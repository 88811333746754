var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inquiry-spec" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c("el-input", {
            attrs: {
              placeholder: _vm.$t("consultRoom.searchWordTip"),
              size: "mini",
              clearable: "",
            },
            model: {
              value: _vm.searchData.speechKey,
              callback: function ($$v) {
                _vm.$set(_vm.searchData, "speechKey", $$v)
              },
              expression: "searchData.speechKey",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleSearch },
            },
            [_vm._v(_vm._s(_vm.$t("common.inquire")))]
          ),
        ],
        1
      ),
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.treeList,
          props: _vm.defaultProps,
          "highlight-current": false,
          "auto-expand-parent": false,
          "expand-on-click-node": false,
          "filter-node-method": _vm.filterNode,
          accordion: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var node = ref.node
              var data = ref.data
              return [
                _c("div", { class: data.id ? "" : "custom-tree-node" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(node.label)),
                  ]),
                  !data.id
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function () {
                                  return _vm.createWord(data)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("consultRoom.addCommon")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }