<template>
  <div class="consult-room">
    <div class="room-left">
      <div class="left-header consultRoom-title">
        <el-popover ref="inquiryPopoverRef"
                    :popper-options="{
                      boundariesElement: 'viewport',
                      removeOnDestroy: true,
                    }"
                    :visible-arrow="false"
                    placement="bottom-end"
                    width="320"
                    popper-class="inquiry-info-popper"
                    trigger="click">
          <el-button slot="reference"
                     :plain="diagnoseRemind||ruleList.length>0||currentUserData.doctorAdvice||currentUserData.imageList>0?false:true"
                     :type="diagnoseRemind||ruleList.length>0||currentUserData.doctorAdvice||currentUserData.imageList>0?'danger':'primary'"
                     class="inquiry-info"
                     size="mini">问诊信息</el-button>
          <div>
            <div style="text-align: right">
              <i class="el-icon-close"
                 style="font-size: 18px;cursor: pointer;"
                 @click="$refs.inquiryPopoverRef.doClose()" />
            </div>
            <div :style="{color: diagnoseRemind||ruleList.length>0?'#E02020':'#999'}"
                 class="inquiry-tip"
                 style="margin-bottom: 10px">
              <div class="weight-bold"
                   style="margin-bottom: 6px;">接诊提醒</div>
              <div :style="{backgroundColor:diagnoseRemind||ruleList.length>0?'#FEF0F0':'#F3F4F6'}"
                   class="inquiry-info-item">
                <div v-if="diagnoseRemind||ruleList.length>0">
                  <div>1、{{ diagnoseRemind }}</div>
                  <div v-for="(item, index) in ruleList"
                       :key="index">
                    {{ (diagnoseRemind ? index + 2 : index + 1) + '、' + item }}
                  </div>
                </div>
                <div v-else>暂无</div>
              </div>
            </div>
            <div :style="{color: currentUserData.doctorAdvice?'#FD9C12':'#999'}"
                 class="doctor-advice"
                 style="margin-bottom: 10px">
              <div class="weight-bold"
                   style="margin-bottom: 6px;">医嘱备注</div>
              <div :style="{backgroundColor: currentUserData.doctorAdvice?'#FFEDD3':'#F3F4F6'}"
                   class="inquiry-info-item">{{ currentUserData.doctorAdvice||'暂无' }}</div>
            </div>
            <div class="inquiry-image">
              <div class="weight-bold"
                   style="margin-bottom: 6px;">问诊图片</div>
              <div class="view-image">
                <div v-for="(item, index) in currentUserData.imageList"
                     :key="index"
                     class="image-item">
                  <i v-if="item.isWatch"
                     class="view-tip el-icon-success" />
                  <el-image :preview-src-list="[item.url]"
                            :src="item.url"
                            style="width: 100px; height: 100px"
                            @click="viewDetail(index)" />
                </div>
                <div v-if="!currentUserData.imageList||currentUserData.imageList.length<=0"
                     class="no-image">暂无图片</div>
              </div>
            </div>
          </div>
        </el-popover>
      </div>
      <patientInfo />
      <div class="inquiry-chat">
        <div v-if="currentMode == 1 || currentMode == 3"
             class="video-chat">
          <div class="local-stream-box">
            <div id="localStream"
                 class="local-stream-content" />
          </div>
          <div class="remote-container video-bg">
            <div v-for="item in remoteUsersViews"
                 :key="item"
                 :id="item"
                 class="remote-stream-container" />
          </div>
          <div v-if="currentUserId"
               class="remote-stop"
               @click="stopInquiry">
            <div class="yy-stop-icon" />
            <div class="stop-txt">{{ $t('consultRoom.endSession') }}</div>
          </div>
        </div>
        <imgText ref="imgText"
                 :chat-list="currentUserData.chatList"
                 :start-time="currentUserData.startTime"
                 :current-user-id="currentUserId"
                 :current-mode="currentMode"
                 :sex="currentUserData.patientInfo.gender === '男' ? 1 : 2" />
      </div>
      <!-- <div class="btn-tip">
        <div class="tip-title">快捷键提示：</div>
        <div class="tip-desc">
          发送（Enter）、确认开方（F1）、用药检测（F4）、常用话术（F6）、图文自动接诊（F9）、结束会话（F10）、删除处方（Delete）安排转诊（~）
        </div>
      </div> -->
    </div>
    <rightRoom v-show="showRight"
               ref="rightRoom"
               @stopInquiry="stopInquiry"
               @deleteRecipe="deleteRecipe"
               @openRecipe="openRecipe" />
    <drugUseAssistant ref="drugUseAssistantRef" />
    <caseAnalysis v-if="showCaseAnalysis"
                  ref="caseAnalysis"
                  :demo-img="demoImg" />
    <injectRecipe v-if="showInjectRecipe"
                  ref="injectRecipe" />
    <el-button v-if="!vaildatePc()"
               class="btn"
               size="mini"
               type="primary"
               @click="() => {showRight = !showRight}">{{ showRight ? '隐藏' : '展开' }}</el-button>
  </div>
</template>

<script>
import { tableOption, patientInfoList } from '@/const/crud/consultRoom'
import { getStore } from '@/util/store'
import { consultRoomApi } from '@/api/consultRoomApi'
import { commonApi } from '@/api/commonApi'
import { vaildatePc } from '@/util/validate'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import imgText from './imgText'
import visitPrescrib from './visitPrescrib'
import drugTable from './drugTable'
import editChineseDrug from './editChineseDrug'
import rightRoom from './rightRoom'
import caseAnalysis from './caseAnalysis'
import drugUseAssistant from './drugUseAssistant'
import patientInfo from './patientInfo'
import injectRecipe from './injectRecipe'

// 视频相关方法
import rtc from '@/util/trtc/mixins/rtcV5.js'

export default {
  name: 'ConsultRoom',
  components: {
    patientInfo,
    drugUseAssistant,
    imgText,
    visitPrescrib,
    drugTable,
    editChineseDrug,
    rightRoom,
    caseAnalysis,
    injectRecipe
  },
  mixins: [rtc],
  data () {
    return {
      vaildatePc,
      patientInfoList,
      tableOption,
      activeName: this.$t('consultRoom.visitPrescrib'),
      patientData: {},
      tableList: [],
      previousMsg: '',
      sdkAppId: '',
      userSig: '',
      userId: '',
      roomId: '',
      onVideo: 0,
      currentRecipeCode: '',
      keyCode1: 0,
      keyCode2: 0,
      age: '',
      nameColor: '#333',
      drugUseData: {},
      gangSelect: false,
      shenSelect: false,
      pregnancySelect: false,
      showRight: true,
      drugUseMsg: '',
      btnOpacity: 1,
      btnRemindOpacity: 1,
      flagOpacity: true,
      flagRemind: true,
      opacityTimer: null,
      opacityRemindTimer: null,
      showPopover: false,
      diagnoseRemind: '',
      ruleList: [],
      showCaseAnalysis: false,
      demoImg: '',
      resizeData: {},
      showInjectRecipe: false,
      cameraId: ''
    }
  },
  computed: {
    provisionalRelease () {
      return socketPublic.state.provisionalRelease
    },
    currentMode () {
      return socketPublic.state.currentMode
    },
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    autoAccept () {
      return socketPublic.state.autoAccept
    },
    inquiryList () {
      return socketPublic.state.inquiryList
    },
    isRemarkMsgBox () {
      return socketPublic.state.isRemarkMsgBox
    },
    isVisit () {
      return socketPublic.state.isVisit
    },
    isCreatRecipe: {
      get () {
        return socketPublic.state.isCreatRecipe
      },
      set (val) {
        socketPublic.commit('changeState', {
          name: 'isCreatRecipe',
          content: val
        })
      }
    },
    isDeleteRecipe: {
      get () {
        return socketPublic.state.isDeleteRecipe
      },
      set (val) {
        socketPublic.commit('changeState', {
          name: 'isDeleteRecipe',
          content: val
        })
      }
    }
  },
  watch: {
    currentUserId (newVal, oldVal) {
      this.drugUseMsg = ''
      this.diagnoseRemind = ''
      this.ruleList = []
      // this.btnRemindOpacity = 1
      // clearInterval(this.opacityRemindTimer)
      // this.opacityRemindTimer = null
      if (newVal) {
        this.$refs.inquiryPopoverRef.doClose()
        setTimeout(() => {
          if (this.currentUserData.doctorAdvice || (this.currentUserData.imageList && this.currentUserData.imageList.length > 0)) this.$refs.inquiryPopoverRef.doShow()
          this.fetchDiagnoseRemind()
          this.fetchRuleReminder()
        }, 1000)
      } else {
        this.$refs.inquiryPopoverRef.doClose()
      }
    },
    $route: {
      handler (to, from) {
        if (to.path == '/consultRoom/index') {
          document.addEventListener('keydown', this.keydownEvent)
          document.addEventListener('keyup', this.keyupEvent)
        } else {
          this.showPopover = false
          document.removeEventListener('keydown', this.keydownEvent)
          document.removeEventListener('keyup', this.keyupEvent)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.stopF5Refresh()
    this.getSecondSpeech()
  },
  mounted () {
    socketPublic.commit('setConsultRoomRef', this)
  },
  destroyed () {
    document.removeEventListener('keydown', this.keydownEvent)
    document.removeEventListener('keyup', this.keyupEvent)
  },
  methods: {
    resize (newRect) {
      this.resizeData = newRect
    },

    // 获取诊断提醒
    async fetchDiagnoseRemind () {
      try {
        const res = await consultRoomApi.fetchDiagnoseRemind({
          company_code: this.currentUserData.companyCode
        })
        this.diagnoseRemind = res.data.data ? res.data.data.inquiry_notice : ''
        if (this.diagnoseRemind) this.$refs.inquiryPopoverRef.doShow()
      } catch (err) {
        console.error(err)
      }
    },
    // 获取规则提醒
    async fetchRuleReminder () {
      try {
        const res = await consultRoomApi.fetchRuleReminder({
          dis_uid: this.currentUserData.pid
        })
        this.ruleList = res.data.data || []
        if (this.ruleList.length > 0) {
          this.$refs.inquiryPopoverRef.doShow()
        }
      } catch (err) {
        console.error(err)
      }
    },
    viewDetail (index) {
      this.currentUserData.imageList[index].isWatch = true
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    },
    controlLight (key, flag) {
      if (this[flag]) {
        this.$nextTick(() => {
          this[key] = this[key] - 0.05
        })
      } else {
        this.$nextTick(() => {
          this[key] = this[key] + 0.05
        })
      }
      if (this[key] < 0.4) {
        this[flag] = false
      } else if (this[key] > 0.99) {
        this[flag] = true
      }
    },
    async getSecondSpeech () {
      try {
        const res = await commonApi.getSecondSpeech({ level: 2, pid: 1 })
        this.$cookies.set('autoReplayObj', JSON.stringify(res.data.data))
      } catch (err) {
        console.error(err)
      }
    },
    // 按下按键
    keydownEvent (e) {
      var evt = window.event || e
      var code = evt.keyCode || evt.which
      // 屏蔽F1---F12
      if ((code > 111 && code < 123) || code == 46) {
        if (evt.preventDefault) {
          evt.preventDefault()
        } else {
          evt.keyCode = 0
          evt.returnValue = false
        }
      }
      if (this.isRemarkMsgBox) return
      if (this.isVisit) return
      if (
        e.keyCode === 112 &&
        !this.isCreatRecipe &&
        !this.currentUserData.isRecipe &&
        this.currentUserId
      ) {
        this.openRecipe() // 确认开方
      } else if (
        e.keyCode === 46 &&
        !this.isDeleteRecipe &&
        this.currentUserData.isRecipe &&
        this.currentUserId
      ) {
        this.deleteRecipe() // 删除处方
      } else if (e.keyCode === 121 && this.currentUserId) {
        this.stopInquiry() // 结束会话
      } else if (e.keyCode === 117) {
        this.$refs.imgText.showCommonWord = !this.$refs.imgText.showCommonWord // 常用话术
      } else if (e.keyCode === 120) {
        const obj = this.inquiryList.find(
          (item) => item.inquiryMode == 1 || item.inquiryMode == 3
        )
        if (obj) {
          this.$message.warning(this.$t('consultRoom.changeStateTip'))
          socketPublic.commit('changeState', {
            name: 'autoAccept',
            content: false
          })
          return
        }
        socketPublic.commit('changeState', {
          name: 'autoAccept',
          content: !this.autoAccept
        }) // 自动问诊
      } else if (
        e.keyCode === 192 &&
        this.currentUserId &&
        this.currentMode == 2
      ) {
        this.$refs.imgText.changeDoctor(!this.$refs.imgText.showChangeDoctor) // 安排转诊
      }
    },
    // 放开按键
    keyupEvent (e) {
      // console.log('keyup', e.keyCode)
    },
    stopF5Refresh () {
      // 禁止鼠标右键菜单
      // document.oncontextmenu = function () {
      //   return false
      // }
      // 阻止后退的所有动作，包括 键盘、鼠标手势等产生的后退动作。
      history.pushState(null, null, window.location.href)
      window.addEventListener('popstate', function () {
        history.pushState(null, null, window.location.href)
      })
    },
    saveStorage (obj) {
      if (obj) {
        this.currentUserData.deleteDrugList.push(obj)
      }
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    },

    /** 初始化进入视频房间**/
    async handleJoinRoom (joinData) {
      try {
        this.sdkAppId = joinData.sdkAppId
        this.roomId = joinData.roomId
        this.userId = joinData.userId
        this.userSig = joinData.secretKey
        if (!this.sdkAppId || !this.userSig) {
          alert('缺少sdkAppId 和 secretKey')
          return
        }
        if (!this.userId || !this.roomId) {
          alert('缺少userId 和 roomId')
          return
        }
        // const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800)
        // this.userSig = userSigGenerator.genTestUserSig(this.userId)
        await this.enterRoom()
        await this.handleStartLocalAudio()
        await this.handleStartLocalVideo()
        const modifyData = { key: 6, value: String(this.roomId) }
        inquiry.doctorModify(modifyData, this.currentUserId)
      } catch (err) {
        console.error('handleJoinRoom', err)
      }
    },

    // 格式化中药
    formatter (data) {
      if (!data.buy_count || !data.frequency || !data.usage_day) {
        return ''
      }
      return (
        (data.takeRequire ? data.takeRequire + ';' : '') +
        (data.takeMethods ? data.takeMethods + ';' : '') +
        data.buy_count +
        '付;' +
        data.frequency +
        ';' +
        data.usage_day
      )
    },

    // 编辑中药用法
    editUse () {
      this.showEdit = true
      this.$nextTick(() => {
        this.$refs.editChineseDrug.isVisible = true
      })
    },
    // 结束问诊
    async stopInquiry () {
      if (this.isCreatRecipe) {
        this.$message.warning('正在开方中，请稍后!')
        return
      }
      if (this.isDeleteRecipe) {
        this.$message.warning('正在删除处方中，请稍后!')
        return
      }
      await this.$confirm(
        this.$t('consultRoom.stopTip'),
        this.$t('common.tip'),
        {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }
      )
      if (!this.currentUserData.isRecipe) {
        this.showInjectRecipe = true
        this.$nextTick(() => {
          this.$refs.injectRecipe.isVisible = true
        })
        return
      }
      if (this.currentUserData.demoImg && this.currentUserData.isTrain) {
        this.demoImg = this.currentUserData.demoImg
        this.showCaseAnalysis = true
        this.$nextTick(() => {
          this.$refs.caseAnalysis.isVisible = true
        })
      }
      inquiry.stopVisit(this.currentUserId)
    },

    // 确认开方
    async openRecipe () {
      try {
        const elInput = document.getElementById('elInput')
        elInput.focus()
        await this.$nextTick()
        if (!!this.currentUserData.doctorAdvice && this.provisionalRelease == false) {
          // this.$message.error(this.$t('consultRoom.childTip7'))
          return
        }
        if (
          !this.currentUserData.customDiagnose ||
          this.currentUserData.customDiagnose.length === 0
        ) {
          this.$message.error(this.$t('consultRoom.checkTip2'))
          return
        }
        const activeType = this.$refs.rightRoom.activeType
        let drugList = null
        if (this.currentUserData.clinicType) {
          if (
            activeType === this.$t('consultRoom.westMedicineRecipeTemp') ||
            activeType === this.$t('consultRoom.chineseMedicineRecipeTemp')
          ) {
            return
          }
          if (activeType === this.$t('consultRoom.westMedicineRecipe')) {
            drugList = this.currentUserData.westernDrugList
            this.currentUserData.recipeType == 1
          } else {
            drugList = this.currentUserData.chineseDrugList
            this.currentUserData.recipeType == 2
          }
        } else {
          drugList = this.currentUserData.drugList
          drugList.forEach((item, index) => {
            if (this.currentUserData.recipeType == 1) {
              if (!item.dosage || !item.dosageUnit || !item.frequencyName || !item.usage) {
                this.currentUserData.drugList[index].warningTxt = 'warnings'
              } else {
                this.currentUserData.drugList[index].warningTxt = ''
              }
            } else {
              if (!item.dosage || !item.dosageUnit || !item.usage) {
                this.currentUserData.drugList[index].warningTxt = 'warnings'
              } else {
                this.currentUserData.drugList[index].warningTxt = ''
              }
            }
          })
        }
        if (!drugList || drugList.length === 0) {
          this.$message.error(this.$t('consultRoom.checkTip1'))
          return
        }
        if (
          drugList.find((item) => {
            if ((!this.currentUserData.clinicType && this.currentUserData.recipeType == 1) ||
              (this.currentUserData.clinicType && activeType == this.$t('consultRoom.westMedicineRecipe'))) {
              return (
                !item.dosage ||
                !item.dosageUnit ||
                !item.frequencyName ||
                !item.usage
              )
            } else {
              return !item.dosage || !item.dosageUnit || !item.usage
            }
          })
        ) {
          this.$message.error(this.$t('consultRoom.checkTip'))
          return
        }
        let recipePrice = 0
        drugList.forEach((item) => {
          recipePrice += item.price * item.buyCount
        })

        let remark = ''
        if ((!this.currentUserData.clinicType && this.currentUserData.recipeType == 2) ||
          (this.currentUserData.clinicType && activeType == this.$t('consultRoom.chineseMedicineRecipe'))) {
          const drugUseData = this.currentUserData.drugUseData
          if (!drugUseData.buy_count || !drugUseData.frequency || !drugUseData.usage_day) {
            this.$message.warning(this.$t('consultRoom.editDrugUseTip'))
            return
          }
          remark = this.formatter(drugUseData)
        }
        remark = remark.length > 0 ? remark + (this.currentUserData.suppleInstruct ? `处方补充说明${this.currentUserData.suppleInstruct}`
          : '') : this.currentUserData.suppleInstruct ? `处方补充说明${this.currentUserData.suppleInstruct}` : ''

        let recipeType = 0
        if (this.currentUserData.clinicType) {
          recipeType =
            activeType == this.$t('consultRoom.chineseMedicineRecipe') ? 2 : 1
        } else {
          recipeType = this.currentUserData.recipeType
        }

        const recipeBean = {
          customDiagnose: this.currentUserData.customDiagnose
            .map((item) => item.value)
            .join(';'),
          diagnoseCode: '',
          diagnoseInfo: '',
          doctorAdvice: '',
          isDisable: 1,
          printTitle: '',
          recipeCode: '',
          recipePrice: recipePrice,
          recipeSource: 1,
          recipeType: recipeType,
          remark: remark,
          drugJson: drugList,
          isSpecial: 0,
          companyCode: this.currentUserData.companyCode || ''
        }
        const obj = drugList.find((item) => item.isSpecial == '1')
        if (obj) recipeBean.isSpecial = 1
        const doctorInfo = getStore({ name: 'doctorInfo' })
        recipeBean.doctorId = doctorInfo.uid
        recipeBean.printTitle = doctorInfo.hospital_name

        this.isCreatRecipe = true
        let res = await consultRoomApi.updateRecipe(recipeBean)
        res = res.data
        if (res.code == 0 && res.data != '') {
          const currentUserId = this.currentUserId
          inquiry.stopVisitTimer[this.currentUserId] = setTimeout(() => {
            inquiry.stopVisit(currentUserId)
          }, 9000)
          this.currentRecipeCode = res.data
          if (this.currentUserData.demoImg && this.currentUserData.isTrain) {
            this.demoImg = this.currentUserData.demoImg
            this.showCaseAnalysis = true
            this.$nextTick(() => {
              this.$refs.caseAnalysis.isVisible = true
            })
          }
          const inquiryList = getStore({ name: 'inquiryList' }) || []
          const inquiryRecipeList =
            getStore({ name: 'inquiryRecipeList' }) || []
          const newList = getStore({ name: 'inquiryList' }) || []
          const index = inquiryList.findIndex(
            (item) => item.userId == this.currentUserId
          )
          if (index > -1) {
            // 将已开方数据，从待开方队列中拿出，放进已开方列表
            inquiryList[index].isRecipe = true

            const item = inquiryList.splice(index, 1)

            inquiryRecipeList.push(item[0])

            if (this.autoAccept) {
              newList.splice(index, 1)
              // inquiryList.push(item[0])
            }
          }

          this.currentUserData.isRecipe = true

          inquiry.commonSetCache({
            name: 'inquiryList',
            content: inquiryList
          })
          inquiry.commonSetCache({
            name: 'inquiryRecipeList',
            content: inquiryRecipeList
          })
          inquiry.commonSetCache({
            name: 'currentUserData_' + this.currentUserId,
            content: this.currentUserData
          })

          var endMsg =
            '请按照实体医院医生给您的指导用法用量使用。如用药后病情无改善或发生不良反应，请及时停药并到就近医院就医。稍后会话将关闭，如有疑虑请在会话关闭前提出。祝您早日康复，再见！'
          inquiry.inquirySendMsg(endMsg, this.currentUserId)
          if (this.autoAccept) {
            const obj = newList.find((item) => item.inquiryMode == 2)
            if (obj) {
              setTimeout(() => {
                inquiry.startVisit(obj)
              }, 500)
            }
          }
          this.isCreatRecipe = false
        } else {
          this.isCreatRecipe = false
          this.$message.error(res.msg)
        }
      } catch (err) {
        this.isCreatRecipe = false
        console.error(err)
      }
    },
    // 作废处方
    async deleteRecipe () {
      try {
        await this.$confirm(
          this.$t('consultRoom.deleteRecipeTip'),
          this.$t('common.tip'),
          {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }
        )
        const doctorInfo = getStore({ name: 'doctorInfo' })
        var recipeBean = {
          recipeCode: this.currentRecipeCode,
          isDisable: 0,
          doctorId: doctorInfo.uid
        }
        this.isDeleteRecipe = true
        const res = await consultRoomApi.deleteRecipe(recipeBean)
        if (res.data.code == 0) {
          clearTimeout(inquiry.stopVisitTimer[this.currentUserId])
          delete inquiry.stopVisitTimer[this.currentUserId]

          const inquiryList = getStore({ name: 'inquiryList' }) || []
          const index = inquiryList.findIndex(
            (item) => item.userId == this.currentUserId
          )
          if (index > -1) {
            inquiryList[index].isRecipe = false
          }

          const inquiryRecipeList =
            getStore({ name: 'inquiryRecipeList' }) || []
          const recipeIndex = inquiryRecipeList.findIndex(
            (item) => item.userId == this.currentUserId
          )
          if (recipeIndex > -1) {
            inquiryRecipeList[recipeIndex].isRecipe = false

            let pushIndex = 0
            inquiryList.forEach((item) => {
              pushIndex =
                item.inquiryMode == 1 || item.inquiryMode == 3
                  ? ++pushIndex
                  : pushIndex
            })
            inquiryList.splice(pushIndex, 0, inquiryRecipeList[recipeIndex])

            inquiryRecipeList.splice(recipeIndex, 1)
          }

          this.currentUserData.isRecipe = false
          inquiry.commonSetCache({
            name: 'inquiryRecipeList',
            content: inquiryRecipeList
          })
          inquiry.commonSetCache({
            name: 'inquiryList',
            content: inquiryList
          })
          inquiry.commonSetCache({
            name: 'currentUserData_' + this.currentUserId,
            content: this.currentUserData
          })
          this.$message.success(this.$t('consultRoom.deleteRecipeTip1'))
        } else {
          this.$message.error(res.data.msg)
        }
        this.isDeleteRecipe = false
      } catch (err) {
        this.isDeleteRecipe = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__header {
  margin: 0;
  text-align: center;
  padding: 10px 0 0 0;
}
label {
  font-weight: 400;
  font-size: 14px;
}
.inquiry-info-item {
  min-height: 60px;
  padding: 10px;
}
.no-image {
  width: 120px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  background-color: #f7f7f7;
  color: #999;
}
.consult-room {
  display: flex;
  background-color: #d9d9d9;
  position: relative;
  .room-left {
    flex: 1;
    margin-right: 10px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .left-header {
      padding-left: 20px;
      font-size: 18px;
      padding-top: 10px;
      position: relative;
      .flex-common {
        width: fit-content;
        span {
          margin-right: 30px;
        }
      }
      .inquiry-info {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
    }
    .zhushu {
      border-left: none;
      height: 80px;
    }
    .btn-tip {
      font-size: 14px;
      padding: 12px;
      .tip-title {
        font-weight: bold;
        margin-bottom: 4px;
      }
      .tip-desc {
        color: #999;
      }
    }
  }
  .btn {
    position: absolute;
    top: 4px;
    right: 10px;
    padding: 5px 15px;
  }

  .inquiry-chat {
    flex: 1;
    overflow: auto;
    display: flex;
  }

  .video-chat {
    flex: 1;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    .local-stream-box {
      position: absolute;
      z-index: 9999999;
      width: 42.67%;
      height: 18%;
      top: 1.7%;
      right: 5.3%;
      overflow: hidden;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
    }

    .remote-container {
      flex: 1;
      .remote-stream-container {
        width: 100%;
        height: 100%;
      }
    }
    .remote-stop {
      height: 25%;
      text-align: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .yy-stop-icon {
        width: 50px;
        height: 50px;
        background-image: url('../../../public/img/icon_stop.png');
        background-size: cover;
      }
      .stop-txt {
        font-size: 12px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin-top: 5px;
      }
    }
  }
  .video-bg {
    background-image: url('../../../public/img/bg_video.png');
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 1550px) {
  label,
  .chinese-tip {
    font-size: 12px !important;
  }
  .consult-room {
    .room-left {
      // flex: 4;
    }
    .room-right {
      // flex: 6;
      .el-tabs .pane-visit .btn-end {
        // padding: 4px 0;
      }
    }
  }
  // .info-item {
  //   font-size: 12px !important;
  //   .el-input {
  //     font-size: 12px !important;
  //   }
  // }
  // .max-weights {
  //   font-size: 12px !important;
  //   .el-input {
  //     font-size: 12px !important;
  //   }
  // }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .btn-tip {
    display: none;
  }
  .inquiry-chat {
    height: calc(100% - 32px - 128px - 20px) !important;
  }
}
.inquiry-image {
  .view-image {
    display: flex;
    flex-wrap: wrap;
    .image-item {
      position: relative;
      margin-right: 20px;
      margin-bottom: 10px;
      .view-tip {
        position: absolute;
        right: -10px;
        top: -8px;
        color: #67c23a;
        font-size: 20px;
        z-index: 1;
      }
      .el-image {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
