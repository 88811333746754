<template>
  <div class="home">
    <div class="total-box">
      <div v-for="(item,index) in totalList"
           :key="index"
           class="total-item"
           @click="jumpMenu(item)">
        <div class="total-title">{{ item.title }}</div>
        <div class="total-main">
          <div v-for="(fItem, fIndex) in item.list"
               :key="fIndex"
               class="total-main-item">
            <div class="total-num">
              <div class="total-count">{{ fItem.value }}</div>
              <div class="total-tip">{{ fItem.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menu">
      <div v-for="(item,index) in menuList"
           :key="index"
           class="menu-item"
           @click.stop="jumpMenu(item)">
        <div class="menu-image">
          <el-image :src="`/img/${item.image}.png`"
                    fit="cover" />
        </div>
        <div class="menu-name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeApi } from '@/api/homeApi'

export default {
  name: 'Home',
  components: {},
  data () {
    return {
      totalList: [
        {
          title: this.$t('home.recipeTotal'),
          list: [
            {
              label: this.$t('home.imageTextNum'),
              value: 1000
            },
            {
              label: this.$t('home.videoNum'),
              value: 635
            },
            {
              label: this.$t('home.recipeNum'),
              value: 1635
            }
          ],
          path: '/preStatistic/index',
          name: this.$t('preStatistic.preStatistic')
        },
        {
          title: this.$t('home.onDutyTotal'),
          list: [
            {
              label: this.$t('home.inquirDuration'),
              value: 1000
            },
            {
              label: this.$t('home.longestDurat'),
              value: 635
            },
            {
              label: this.$t('home.shortestDurat'),
              value: 1635
            }
          ],
          path: '/dutyStatistic/index',
          name: this.$t('dutyStatistic.dutyStatistic')
        }
      ],
      menuList: [
        {
          path: '/consultRoom/index',
          name: this.$t('consultRoom.consultRoom'),
          image: 'consult_room'
        },
        {
          path: '/inquiryRecord/index',
          name: this.$t('inquiryRecord.inquiryRecord'),
          image: 'inquiry_record'
        },
        {
          path: '/recipeRecord/index',
          name: this.$t('recipeRecord.recipeRecord'),
          image: 'recipe_record'
        },
        {
          path: '/recipeTemplate/index',
          name: this.$t('recipeTemplate.recipeTemplate'),
          image: 'recipe_template'
        },
        {
          path: '/serviceEvalua/index',
          name: this.$t('serviceEvalua.serviceEvalua'),
          image: 'service_evalua'
        },
        {
          path: '',
          name: this.$t('home.equipCheck'),
          image: 'equip_check'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if (to.query.isLogin) {
          delete to.query.isLogin
          this.$router.push({
            path: '/consultRoom/index'
          })
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.fetchTotalData()
  },
  methods: {
    // 获取统计数据
    async fetchTotalData () {
      try {
        const res = await homeApi.fetchTotal()
        const data = res.data.data
        this.totalList[0].list[0].value = data.inquiry.textInquiryNum
        this.totalList[0].list[1].value = data.inquiry.videoInquiryNum
        this.totalList[0].list[2].value = data.inquiry.recipeNum
        this.totalList[1].list[0].value =
          data.doctorAttendance.totalTime === null
            ? this.$t('home.notAvail')
            : this.formatter(data.doctorAttendance.totalTime)
        this.totalList[1].list[1].value =
          data.doctorAttendance.recipeTimeMax === null
            ? this.$t('home.notAvail')
            : data.doctorAttendance.recipeTimeMax + 's'
        this.totalList[1].list[2].value =
          data.doctorAttendance.recipeTimeMin === null
            ? this.$t('home.notAvail')
            : data.doctorAttendance.recipeTimeMin + 's'
        this.totalList = [...this.totalList]
      } catch (e) {
        console.error(e)
      }
    },
    // 格式化时分秒
    formatter (duration) {
      const hours = Math.floor(duration / 3600)
      const minutes = Math.floor((duration - hours * 3600) / 60)
      const seconds = duration - hours * 3600 - minutes * 60
      return (
        (hours ? hours + 'h' : '') +
        (minutes ? minutes + 'm' : '') +
        +seconds +
        's'
      )
    },
    jumpMenu (item) {
      if (item.path) {
        this.$router.push({
          path: this.$router.$avueRouter.getPath({
            name: item.name,
            src: item.path
          })
        })
      } else {
        window.open('https://web.sdk.qcloud.com/trtc/webrtc/demo/detect/index.html', '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.total-box {
  display: flex;
  justify-content: space-between;
  .total-item {
    background-color: #3b94ff;
    border-radius: 14px;
    color: #ffffff;
    height: 146px;
    width: 49%;
    cursor: pointer;
    &:nth-child(2n) {
      background-color: #00d3ba;
    }
    .total-title {
      font-size: 14px;
      font-weight: 600;
      padding-top: 16px;
      padding-left: 32px;
      padding-bottom: 30px;
      color: #fff;
    }

    .total-main {
      display: flex;
      justify-content: space-around;

      .total-main-item {
        display: flex;
        justify-content: space-between;
        text-align: center;

        .total-count {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        .total-tip {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
.menu {
  display: flex;
  margin-top: 30px;
  background-color: #fff;
  border-radius: 14px;
  padding: 20px;
  height: calc(100vh - 304px);
  .menu-item {
    width: 200px;
    height: 120px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    &:hover {
      color: #3b94ff;
      // font-weight: 600;
    }
  }
}
</style>
