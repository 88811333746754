var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          defaults: _vm.defaults,
          search: _vm.paramsSearch,
          page: _vm.page,
          data: _vm.tableData,
          "table-loading": _vm.tableLoading,
          option: _vm.tableOption,
        },
        on: {
          "update:defaults": function ($event) {
            _vm.defaults = $event
          },
          "update:search": function ($event) {
            _vm.paramsSearch = $event
          },
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.fetchData,
          "search-change": _vm.handleSubmit,
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.viewDetail(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("recipeRecord.recipeDetail")))]
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("div", { domProps: { innerHTML: _vm._s(row.status) } }),
              ]
            },
          },
          {
            key: "isNowSearch",
            fn: function (ref) {
              var size = ref.size
              return [
                _c(
                  "el-checkbox",
                  {
                    attrs: { size: size },
                    on: { change: _vm.changeCheckbox },
                    model: {
                      value: _vm.paramsSearch.isNow,
                      callback: function ($$v) {
                        _vm.$set(_vm.paramsSearch, "isNow", $$v)
                      },
                      expression: "paramsSearch.isNow",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("recipeRecord.today")))]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showDetail
        ? _c("detail", {
            ref: "detail",
            attrs: { url: _vm.currentData.recipe_url },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }