<template>
  <div class="main-container">
    <avue-crud ref="aVueForm"
               :page.sync="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               v-model="formData"
               @on-load="fetchData"
               @search-change="handleSubmit">
    </avue-crud>
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/serviceEvalua'
import { serviceEvaluaApi } from '@/api/functionApi'
import { dateFormat } from '@/util/date'

export default {
  name: 'serviceEvalua',
  data() {
    return {
      tableOption,
      formData: {},
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], //升序字段
        descs: [] //降序字段
      },
      paramsSearch: {
        startTime: dateFormat(
          +new Date() - 30 * 24 * 60 * 60 * 1000,
          'YYYY-MM-DD'
        ),
        endTime: dateFormat(+new Date(), 'YYYY-MM-DD')
      },
      tableLoading: false
    }
  },
  created() {
    // this.fetchPackageTypeData()
  },
  methods: {
    async fetchData() {
      try {
        this.tableLoading = true
        const res = await serviceEvaluaApi.fetchData({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          ...this.paramsSearch
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (e) {
        console.error(err)
        this.tableLoading = false
      }
    },
    handleSubmit(params, done) {
      ;[params.startTime, params.endTime] = params.date ? params.date : []
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
</style>
