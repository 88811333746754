var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "test-fail" }, [
    _c("div", { staticClass: "fail-title" }, [_vm._v("检测异常，请重新检测")]),
    _c("div", { staticClass: "cxjc", on: { click: _vm.checkAgain } }, [
      _vm._v("重新检测"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }