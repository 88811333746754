<template>
  <div class="line-echarts">
    <div class="line-echarts-search">
      <div class="line-echarts-title">
        {{ $t('dutyStatistic.dutyStatistic') }}
      </div>
      <avue-form
        ref="aVueForm"
        v-model="formData"
        :option="formOption"
        @submit="handleSubmit"
      />
    </div>
    <div ref="lineEcharts" style="height: 320px" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { formOption } from '@/const/crud/preStatistic'
import { dutyStatisticApi } from '@/api/functionApi'
// import { deepClone } from '@/util/util'

export default {
  name: 'LineEcharts',
  data() {
    return {
      formOption: formOption(this.fetchData),
      formData: {},
      myChart: null,
      echartOption: {},
      params: {}
    }
  },

  mounted() {
    this.fetchData(true)
    this.formData.tjType = 2
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeListern)
  },

  methods: {
    // 初始化数据
    async fetchData(isFirst) {
      try {
        this.params = { resultDeno: 3600 }
        this.params.tjType =
          this.formData.tjDate && this.formData.tjDate.length > 0
            ? this.formData.tjDate[0] === this.formData.tjDate[1]
              ? 1
              : 2
            : this.formData.tjType
            ? this.formData.tjType
            : 1
        this.params.tjDate =
          this.formData.tjDate && this.formData.tjDate.length > 0
            ? this.formData.tjDate[0] === this.formData.tjDate[1]
              ? this.formData.tjDate[0]
              : this.formData.tjDate.join(';')
            : ''
        const res = await dutyStatisticApi.fetchChartData(this.params)
        const data = res.data.data
        const echartsData = {}
        echartsData.X =
          this.params.tjType == 1 ? data.X.map((item) => item + ':00') : data.X
        echartsData.yData = []
        for (const key in data) {
          if (
            Object.hasOwnProperty.call(data, key) &&
            key.split('')[0] == 'Y'
          ) {
            echartsData.yData.push(data[key])
          }
        }
        this.echartOption = this.fliterOption(echartsData)
        if (isFirst) {
          this.myChart = echarts.init(this.$refs.lineEcharts)
          window.addEventListener('resize', this.resizeListern)
        }
        this.myChart.setOption(this.echartOption)
      } catch (e) {
        console.error(e)
      }
    },

    resizeListern() {
      this.myChart.resize() // 图表自适应的一个方法
    },

    fliterOption(data) {
      return {
        grid: {
          left: 40,
          top: 60,
          right: 40,
          bottom: 40,
          containLabel: true
        },
        tooltip: {
          // 鼠标触摸显示值
          trigger: 'axis',
          backgroundColor: 'rgba(51, 51, 51, 0.6)',
          borderColor: 'rgba(51, 51, 51, 0.6)',
          borderWidth: 0,
          textStyle: {
            color: '#fff'
          },
          axisPointer: {
            type: 'cross',
            lineStyle: {
              color: '#3B94FF',
              width: 2
            }
          }
        },
        xAxis: {
          type: 'category', // 类型
          boundaryGap: false,
          data: data.X // x即接口方法传递过来的参数也是x轴的数据（x等同于res.data.xData）
        },
        yAxis: {
          type: 'value',
          name: this.$t('dutyStatistic.unit'),
          nameTextStyle: {
            fontWeight: 'bold',
            height: 40,
            lineHeight: 40
          }
        },
        series: data.yData.map((item, index) => {
          return {
            name:
              index === 0
                ? this.$t('dutyStatistic.videoTime')
                : this.$t('dutyStatistic.imgTextTime'),
            symbolSize: [6, 6],
            symbol: 'circle',
            smooth: false,
            lineStyle: {
              color: index === 0 ? '#3B94FF' : '#66CC66',
              width: 2
            },
            itemStyle: {
              color: index === 0 ? '#3B94FF' : '#66CC66'
            },
            type: 'line',
            data: item
          }
        })
      }
    },

    async handleSubmit(form, done) {
      await this.fetchData(false)
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col-3 {
  width: 140px !important;
}
/deep/ .el-col-4 {
  width: 210px !important;
}
/deep/ .el-radio__label {
  &:hover {
    color: #409eff;
  }
}
/deep/ .el-radio__input {
  display: none;
}

/deep/ .el-form-item__content {
  margin-left: 0 !important;
}

/deep/.el-form-item {
  margin-bottom: 0 !important;
}

/deep/ .el-button {
  width: 100px;
}

/deep/ .avue-form__group--flex {
  justify-content: flex-end;
}

.line-echarts {
  background-color: #fff;
  height: 380px;
  border-radius: 14px;

  .line-echarts-search {
    display: flex;
    justify-content: space-between;
    padding-top: 26px;

    .line-echarts-title {
      padding-left: 20px;
      width: 20%;
      display: inline-flex;
      align-items: center;
      font-size: 22px;
      color: #333333;
      font-weight: 600;
    }

    /deep/ .avue-form {
      width: auto !important;
      flex: 1;
    }
  }
}
</style>
