var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "consult-room" },
    [
      _c(
        "div",
        { staticClass: "room-left" },
        [
          _c(
            "div",
            { staticClass: "left-header consultRoom-title" },
            [
              _c(
                "el-popover",
                {
                  ref: "inquiryPopoverRef",
                  attrs: {
                    "popper-options": {
                      boundariesElement: "viewport",
                      removeOnDestroy: true,
                    },
                    "visible-arrow": false,
                    placement: "bottom-end",
                    width: "320",
                    "popper-class": "inquiry-info-popper",
                    trigger: "click",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "inquiry-info",
                      attrs: {
                        slot: "reference",
                        plain:
                          _vm.diagnoseRemind ||
                          _vm.ruleList.length > 0 ||
                          _vm.currentUserData.doctorAdvice ||
                          _vm.currentUserData.imageList > 0
                            ? false
                            : true,
                        type:
                          _vm.diagnoseRemind ||
                          _vm.ruleList.length > 0 ||
                          _vm.currentUserData.doctorAdvice ||
                          _vm.currentUserData.imageList > 0
                            ? "danger"
                            : "primary",
                        size: "mini",
                      },
                      slot: "reference",
                    },
                    [_vm._v("问诊信息")]
                  ),
                  _c("div", [
                    _c("div", { staticStyle: { "text-align": "right" } }, [
                      _c("i", {
                        staticClass: "el-icon-close",
                        staticStyle: { "font-size": "18px", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs.inquiryPopoverRef.doClose()
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "inquiry-tip",
                        staticStyle: { "margin-bottom": "10px" },
                        style: {
                          color:
                            _vm.diagnoseRemind || _vm.ruleList.length > 0
                              ? "#E02020"
                              : "#999",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "weight-bold",
                            staticStyle: { "margin-bottom": "6px" },
                          },
                          [_vm._v("接诊提醒")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inquiry-info-item",
                            style: {
                              backgroundColor:
                                _vm.diagnoseRemind || _vm.ruleList.length > 0
                                  ? "#FEF0F0"
                                  : "#F3F4F6",
                            },
                          },
                          [
                            _vm.diagnoseRemind || _vm.ruleList.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "1、" + _vm._s(_vm.diagnoseRemind)
                                      ),
                                    ]),
                                    _vm._l(
                                      _vm.ruleList,
                                      function (item, index) {
                                        return _c("div", { key: index }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                (_vm.diagnoseRemind
                                                  ? index + 2
                                                  : index + 1) +
                                                  "、" +
                                                  item
                                              ) +
                                              "\n                "
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", [_vm._v("暂无")]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "doctor-advice",
                        staticStyle: { "margin-bottom": "10px" },
                        style: {
                          color: _vm.currentUserData.doctorAdvice
                            ? "#FD9C12"
                            : "#999",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "weight-bold",
                            staticStyle: { "margin-bottom": "6px" },
                          },
                          [_vm._v("医嘱备注")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "inquiry-info-item",
                            style: {
                              backgroundColor: _vm.currentUserData.doctorAdvice
                                ? "#FFEDD3"
                                : "#F3F4F6",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.currentUserData.doctorAdvice || "暂无")
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "inquiry-image" }, [
                      _c(
                        "div",
                        {
                          staticClass: "weight-bold",
                          staticStyle: { "margin-bottom": "6px" },
                        },
                        [_vm._v("问诊图片")]
                      ),
                      _c(
                        "div",
                        { staticClass: "view-image" },
                        [
                          _vm._l(
                            _vm.currentUserData.imageList,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "image-item" },
                                [
                                  item.isWatch
                                    ? _c("i", {
                                        staticClass: "view-tip el-icon-success",
                                      })
                                    : _vm._e(),
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      "preview-src-list": [item.url],
                                      src: item.url,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          !_vm.currentUserData.imageList ||
                          _vm.currentUserData.imageList.length <= 0
                            ? _c("div", { staticClass: "no-image" }, [
                                _vm._v("暂无图片"),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("patientInfo"),
          _c(
            "div",
            { staticClass: "inquiry-chat" },
            [
              _vm.currentMode == 1 || _vm.currentMode == 3
                ? _c("div", { staticClass: "video-chat" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "remote-container video-bg" },
                      _vm._l(_vm.remoteUsersViews, function (item) {
                        return _c("div", {
                          key: item,
                          staticClass: "remote-stream-container",
                          attrs: { id: item },
                        })
                      }),
                      0
                    ),
                    _vm.currentUserId
                      ? _c(
                          "div",
                          {
                            staticClass: "remote-stop",
                            on: { click: _vm.stopInquiry },
                          },
                          [
                            _c("div", { staticClass: "yy-stop-icon" }),
                            _c("div", { staticClass: "stop-txt" }, [
                              _vm._v(_vm._s(_vm.$t("consultRoom.endSession"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("imgText", {
                ref: "imgText",
                attrs: {
                  "chat-list": _vm.currentUserData.chatList,
                  "start-time": _vm.currentUserData.startTime,
                  "current-user-id": _vm.currentUserId,
                  "current-mode": _vm.currentMode,
                  sex: _vm.currentUserData.patientInfo.gender === "男" ? 1 : 2,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("rightRoom", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRight,
            expression: "showRight",
          },
        ],
        ref: "rightRoom",
        on: {
          stopInquiry: _vm.stopInquiry,
          deleteRecipe: _vm.deleteRecipe,
          openRecipe: _vm.openRecipe,
        },
      }),
      _c("drugUseAssistant", { ref: "drugUseAssistantRef" }),
      _vm.showCaseAnalysis
        ? _c("caseAnalysis", {
            ref: "caseAnalysis",
            attrs: { "demo-img": _vm.demoImg },
          })
        : _vm._e(),
      _vm.showInjectRecipe
        ? _c("injectRecipe", { ref: "injectRecipe" })
        : _vm._e(),
      !_vm.vaildatePc()
        ? _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function () {
                  _vm.showRight = !_vm.showRight
                },
              },
            },
            [_vm._v(_vm._s(_vm.showRight ? "隐藏" : "展开"))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "local-stream-box" }, [
      _c("div", {
        staticClass: "local-stream-content",
        attrs: { id: "localStream" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }