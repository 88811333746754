import request from '@/router/axios'
import qs from 'qs'

// 处方记录
export const recipeRecordApi = {
  fetchData: (obj) => {
    return request({
      url: '/doctor/inquiry/getRecipeList',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  fetchRecipeDetail: (obj) => {
    return request({
      url: '/store/inquiry/getRecipe',
      method: 'post',
      data: qs.stringify(obj)
    })
  }
}

// 问诊记录
export const inquiryRecordApi = {
  fetchData: (query) => {
    return request({
      url: '/doctor/inquiry/getInquiryList',
      method: 'get',
      params: query
    })
  },
  // 编辑病历
  updateCase: (obj) => {
    return request({
      url: '/doctor/inquiry/updateCase',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  // 获取聊天记录
  fetchChatHist: (query) => {
    return request({
      url: '/doctor/inquiry/chatHis',
      method: 'get',
      params: query
    })
  }
}

// 处方模板
export const recipeTemplateApi = {
  fetchData: (obj) => {
    return request({
      url: '/doctor/recipe/getPublicTpl',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  fetchDetail: (obj) => {
    return request({
      url: '/doctor/recipe/getTplInfo',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  // 创建/修改模板
  updateTemplate: (obj) => {
    return request({
      url: '/doctor/recipe/createTpl',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  // 删除模板
  deleteTemplate: (obj) => {
    return request({
      url: '/doctor/recipe/delTpl',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  // 改变模板状态
  changeTemplateStatus: (obj) => {
    return request({
      url: '/doctor/recipe/setTpl',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  // 搜索药品(用于填补药品的具体信息，缺少的字段后续补上)
  fetchGrugs: (obj) => {
    return request({
      url: '/doctor/drugs/searchBaseList',
      method: 'post',
      data: qs.stringify(obj)
    })
  },
  fetchThirdPartyGrugs: (obj) => {
    return request({
      url: '/doctor/drugs/searchThirdDrugList',
      method: 'post',
      data: qs.stringify(obj)
    })
  }
}

// 服务评价
export const serviceEvaluaApi = {
  fetchData: (obj) => {
    return request({
      url: '/doctor/evaluation/getList',
      method: 'post',
      data: qs.stringify(obj)
    })
  }
}

// 处方统计
export const preStatisticApi = {
  fetchData: (query) => {
    return request({
      url: '/doctor/pageHome/getTjDoctorRecipe',
      method: 'get',
      params: query
    })
  },
  // 获取折线图数据
  fetchChartData: (query) => {
    return request({
      url: '/doctor/pageHome/getTjPlatChart',
      method: 'get',
      params: query
    })
  }
}

// 值班统计
export const dutyStatisticApi = {
  fetchData: (query) => {
    return request({
      url: '/doctor/pageHome/getDoctorDutys',
      method: 'get',
      params: query
    })
  },
  fetchDetail: (query) => {
    return request({
      url: '/doctor/pageHome/getDoctorAttendanceList',
      method: 'get',
      params: query
    })
  },
  // 获取折线图数据
  fetchChartData: (query) => {
    return request({
      url: '/doctor/pageHome/getDoctorDutyChart',
      method: 'get',
      params: query
    })
  }
}
