<template>
  <el-dialog :title="$t('consultRoom.createRecipe') + ' - ' + recipeType"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="600px"
             append-to-body
             @close="isVisible = false">
    <div class="temp-title">【{{ $t("consultRoom.tempDiagnose") }}】</div>
    <div class="diagnose-content">{{ detailData.diagnose_info? detailData.diagnose_info : detailData.custom_diagnose }}</div>
    <div class="temp-title">【{{ $t("consultRoom.tempDrugs") }}】</div>
    <div v-if="recipeType === '西药处方'"
         class="use-drug-detail">
      <div v-for="(item, index) in detailData.drugList"
           :key="index"
           class="drug-list">
        <div>
          {{ `${item.drug_name} ${item.buy_count}${item.packing_unit} （${item.specification}）` }}</div>
        <div>Sig：{{ `${item.usage} 每${item.dosage}${item.dosage_unit} ${item.frequency}` }}</div>
      </div>
    </div>
    <template v-else>
      <div class="use-drug-detail"
           style="margin-left: 10px">
        <div v-for="(item, index) in detailData.drugList"
             :key="index"
             class="chinese-drug-list">
          <div>{{ `${index + 1}、${item.drug_name} ${item.dosage}${item.dosage_unit}` }}</div>
        </div>
      </div>
      <div class="temp-title">【{{ $t("consultRoom.takeRequire") }}】</div>
      <div class="diagnose-content">{{ detailData.remark }}</div>
    </template>

    <div slot="footer"
         style="text-align: center">
      <el-button :loading="isCreatRecipe"
                 type="primary"
                 size="small"
                 @click="handleSubmit">{{ $t("consultRoom.recipeSubmit") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { recipeTemplateApi } from '@/api/functionApi'
import { consultRoomApi } from '@/api/consultRoomApi'
import { getStore } from '@/util/store'
import inquiry from '@/socketMsg/inquiry.js'
import socketPublic from '@/socketMsg/socketPublic.js'

export default {
  name: 'CreateRecipe',
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    recipeType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      detailData: {},
      isVisible: false,
      currentRecipeCode: ''
    }
  },
  computed: {
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    autoAccept () {
      return socketPublic.state.autoAccept
    },
    isCreatRecipe () {
      return socketPublic.state.isCreatRecipe
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showCreateRecipe) {
          this.$parent.showCreateRecipe = false
        }
        if (this.$parent.$parent.showCreateRecipe) {
          this.$parent.$parent.showCreateRecipe = false
        }
      } else {
        if (this.currentData) {
          this.fetchDetail()
        }
      }
    }
  },
  methods: {
    async fetchDetail () {
      try {
        const res = await recipeTemplateApi.fetchDetail({
          tempId: this.currentData.template_id,
          recipeType:
            this.recipeType === '西药处方'
              ? 1
              : this.recipeType === '中药处方'
                ? 2
                : ''
        })
        this.detailData = res.data.data
      } catch (err) {
        console.error(err)
      }
    },
    async handleSubmit () {
      try {
        let recipePrice = 0
        this.detailData.drugList.forEach((item, index) => {
          recipePrice += item.price * item.buy_count
        })

        const recipeBean = {
          customDiagnose: this.detailData.diagnose_info
            ? this.detailData.diagnose_info
            : this.detailData.custom_diagnose,
          diagnoseCode: '',
          diagnoseInfo: '',
          doctorAdvice: '',
          isDisable: 1,
          printTitle: '',
          recipeCode: '',
          recipePrice: recipePrice,
          recipeSource: 1,
          recipeType:
            this.recipeType === '西药处方'
              ? 1
              : this.recipeType === '中药处方'
                ? 2
                : '',
          remark: this.detailData.remark,
          drugJson: this.detailData.drugList.map((item) => {
            return {
              buyCount: item.buy_count,
              buyUnit: item.buy_unit,
              dayCount: item.day_count,
              doctorAdvice: item.doctor_advice,
              dosage: item.dosage,
              dosageUnit: item.dosage_unit,
              drugApprovalNum: item.drug_approval_num,
              drugId: item.drug_id,
              drugName: item.drug_name,
              drugType: item.drugType,
              drug_name: item.drug_name,
              frequency: item.frequency,
              frequencyName: item.frequency,
              packingUnit: item.packing_unit,
              price: item.price,
              specification: item.specification,
              usage: item.usage
            }
          })
        }
        const doctorInfo = getStore({ name: 'doctorInfo' })
        recipeBean.doctorId = doctorInfo.uid
        recipeBean.printTitle = doctorInfo.hospital_name
        this.isCreatRecipe = true
        let res = await consultRoomApi.updateRecipe(recipeBean)
        res = res.data
        if (res.code == 0 && res.data != '') {
          inquiry.stopVisitTimer[this.currentUserId] = setTimeout(() => {
            inquiry.stopVisit(this.currentUserId)
          }, 9000)
          this.currentRecipeCode = res.data
          const inquiryList = getStore({ name: 'inquiryList' }) || []
          const inquiryRecipeList =
            getStore({ name: 'inquiryRecipeList' }) || []
          const newList = getStore({ name: 'inquiryList' }) || []
          const index = inquiryList.findIndex(
            (item) => item.userId == this.currentUserId
          )
          if (index > -1) {
            // 将已开方数据，从待开方队列中拿出，放进已开方列表
            inquiryList[index].isRecipe = true

            const item = inquiryList.splice(index, 1)

            inquiryRecipeList.push(item[0])

            if (this.autoAccept) {
              newList.splice(index, 1)
              // inquiryList.push(item[0])
            }
          }

          this.currentUserData.isRecipe = true

          inquiry.commonSetCache({
            name: 'inquiryList',
            content: inquiryList
          })
          inquiry.commonSetCache({
            name: 'inquiryRecipeList',
            content: inquiryRecipeList
          })
          inquiry.commonSetCache({
            name: 'currentUserData_' + this.currentUserId,
            content: this.currentUserData
          })

          var endMsg =
            '请按照实体医院医生给您的指导用法用量使用。如用药后病情无改善或发生不良反应，请及时停药并到就近医院就医。稍后会话将关闭，如有疑虑请在会话关闭前提出。祝您早日康复，再见！'
          inquiry.inquirySendMsg(endMsg, this.currentUserId)
          if (this.autoAccept) {
            const obj = newList.find((item) => item.inquiryMode == 2)
            if (obj && newList.length > 1) {
              setTimeout(() => {
                inquiry.startVisit(obj)
              }, 500)
            }
          }
          this.isVisible = false
          this.isCreatRecipe = false
        } else {
          this.isCreatRecipe = false
          this.$message.error(res.msg)
        }
      } catch (err) {
        this.isCreatRecipe = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}
.temp-title {
  font-weight: bolder;
  margin: 10px 0;
}
.diagnose-content {
  margin-left: 10px;
}
.use-drug-detail {
  color: #333;
  overflow-y: auto;
}
.drug-list {
  padding: 10px 15px;
  border: 1px solid #333;
  margin-bottom: 16px;
  & > div:first-child {
    margin-bottom: 12px;
  }
}
</style>
