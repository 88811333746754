import { translate as $t } from '@/lang'
import { dateFormat } from '@/util/date'

export const tableOption = {
  size: 'medium',
  dialogDrag: true,
  searchMenuPosition: 'left',
  align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  emptyBtn: false,
  refreshBtn: false,
  columnBtn: false,
  editBtn: false,
  delBtn: false,
  menuWidth: 120,
  searchShowBtn: false,
  highlightCurrentRow: true,
  column: [
    {
      label: $t('recipeTemplate.templateName'),
      prop: 'template_name',
      overHidden: true,
      minWidth: '140px'
    },
    {
      label: $t('recipeTemplate.recipeType'),
      prop: 'recipeType',
      search: true,
      overHidden: true,
      searchSpan: 5,
      searchOrder: 3,
      placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.recipeType'),
      type: 'select',
      dicData: [
        {
          label: $t('recipeRecord.westMedicineRecipe'),
          value: 1
        },
        {
          label: $t('recipeRecord.chineseMedicineRecipe'),
          value: 2
        }
      ],
      formatter: (val) => {
        return val.recipe_type
      }
    },
    {
      label: $t('recipeTemplate.diagnosisContent'),
      overHidden: true,
      prop: 'diagnose_info'
    },
    {
      label: $t('recipeTemplate.customDiagnosis'),
      overHidden: true,
      prop: 'custom_diagnose'
    },
    {
      label: $t('recipeTemplate.status'),
      prop: 'status',
      search: true,
      overHidden: true,
      searchSpan: 5,
      searchOrder: 3,
      placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.status'),
      type: 'select',
      dicData: [
        {
          label: $t('common.startUse'),
          value: 1
        },
        {
          label: $t('common.noUse'),
          value: 2
        }
      ],
      formatter: (val) => {
        return val.status_desc
      }
    },
    {
      width: 200,
      label: $t('recipeTemplate.createTime'),
      overHidden: true,
      prop: 'date',
      type: 'daterange',
      display: false,
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      searchSpan: 8,
      searchOrder: 1,
      formatter: (val) => {
        return val.create_time
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      },
      searchValue: [
        dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
        dateFormat(+new Date(), 'YYYY-MM-DD')
      ]
    },
    {
      label: $t('recipeTemplate.templateName'),
      overHidden: true,
      prop: 'keyword',
      placeholder: $t('common.pleaseInput') + $t('recipeTemplate.templateName'),
      search: true,
      searchSpan: 6,
      searchOrder: 4,
      hide: true
    }
  ]
}

export const drugTableOption = {
  size: 'small',
  align: 'center',
  addBtn: false,
  emptyBtn: false,
  refreshBtn: false,
  columnBtn: false,
  height: 400,
  menu: false,
  highlightCurrentRow: true,
  column: [
    {
      label: $t('recipeTemplate.drugName'),
      prop: 'drugName',
      overHidden: true,
      minWidth: '140px'
    },
    {
      label: $t('recipeTemplate.approvalNum'),
      overHidden: true,
      prop: 'drugApprovalNum'
    },
    {
      label: $t('recipeTemplate.drugSpecification'),
      overHidden: true,
      prop: 'specification'
    }
  ]
}

export function leftOption (type) {
  return {
    emptyBtn: false,
    submitText: $t('recipeTemplate.addDrugs'),
    submitIcon: ' ',
    column: [
      {
        label: $t('recipeTemplate.drugName'),
        prop: 'drug_name',
        span: 24,
        placeholder: ' ',
        readonly: true,
        rules: [
          {
            required: true,
            message: $t('recipeTemplate.selectDrugsTip'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.dosage'),
        prop: 'dosage',
        type: 'number',
        precision: 1,
        controls: false,
        placeholder: $t('common.pleaseInput') + $t('recipeTemplate.dosage'),
        span: 24,
        rules: [
          {
            required: true,
            message: $t('common.pleaseInput') + $t('recipeTemplate.dosage'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.dosageUnit'),
        prop: 'dosage_unit',
        placeholder: $t('common.pleaseInput') + $t('recipeTemplate.dosageUnit'),
        span: 24,
        rules: [
          {
            required: true,
            message: $t('common.pleaseInput') + $t('recipeTemplate.dosageUnit'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.useDrugType'),
        prop: 'usage',
        type: 'select',
        filterable: true,
        placeholder:
          $t('common.pleaseSelect') + $t('recipeTemplate.useDrugType'),
        props: {
          label: 'name',
          value: 'name'
        },
        dicQuery: {
          type: type === 2 ? 1 : 2
        },
        span: 24,
        dicUrl: '/doctor/drugs/getUsage',
        rules: [
          {
            required: true,
            message:
              $t('common.pleaseSelect') + $t('recipeTemplate.useDrugType'),
            trigger: 'change'
          }
        ]
      },
      {
        label: $t('recipeTemplate.useNum'),
        prop: 'frequency',
        type: 'select',
        display: type === 2,
        filterable: true,
        placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
        props: {
          label: 'name',
          value: 'name'
        },
        span: 24,
        dicUrl: '/doctor/drugs/frequency',
        rules: [
          {
            required: true,
            message: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
            trigger: 'change'
          }
        ]
      },
      {
        label: $t('recipeTemplate.useDays'),
        prop: 'day_count',
        type: 'number',
        display: type === 2,
        precision: 1,
        controls: false,
        placeholder: $t('common.pleaseInput') + $t('recipeTemplate.useDays'),
        span: 24,
        rules: [
          {
            required: true,
            message: $t('common.pleaseInput') + $t('recipeTemplate.useDays'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.buyNum'),
        prop: 'buy_count',
        type: 'number',
        display: type === 2,
        precision: 1,
        controls: false,
        placeholder: $t('common.pleaseInput') + $t('recipeTemplate.buyNum'),
        span: 24,
        rules: [
          {
            required: true,
            message: $t('common.pleaseInput') + $t('recipeTemplate.buyNum'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.buyUnit'),
        prop: 'buy_unit',
        type: 'select',
        display: type === 2,
        filterable: true,
        placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.buyUnit'),
        span: 24,
        dicUrl: '/doctor/drugs/getPackageUnit',
        dicFormatter: (res) => {
          const list = res.data.map((item) => {
            return {
              label: item,
              value: item
            }
          })
          return list
        }
      }
    ]
  }
}

export function rightOption (type) {
  return {
    emptyBtn: false,
    submitText: $t('recipeTemplate.confirmSubmit'),
    submitIcon: ' ',
    labelWidth: '150',
    column: [
      {
        label: $t('recipeTemplate.templateName'),
        prop: 'template_name',
        row: true,
        span: 20,
        placeholder:
          $t('common.pleaseInput') + $t('recipeTemplate.templateName'),
        rules: [
          {
            required: true,
            message:
              $t('common.pleaseInput') + $t('recipeTemplate.templateName'),
            trigger: 'blur'
          }
        ]
      },
      // {
      //   prop: 'tip',
      //   span: 20,
      //   row: true
      // },
      {
        label: $t('recipeTemplate.diagnosis'),
        prop: 'diagnose_info',
        type: 'select',
        span: 24,
        row: true
      },
      {
        label: $t('recipeTemplate.customDiagnosis'),
        prop: 'custom_diagnose',
        placeholder:
          $t('common.pleaseInput') + $t('recipeTemplate.customDiagnosis'),
        span: 20,
        row: true
      },
      {
        label: $t('recipeTemplate.useNum'),
        prop: 'frequency',
        type: 'select',
        span: 12,
        display: type === 1,
        filterable: true,
        placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
        props: {
          label: 'name',
          value: 'name'
        },
        dicUrl: '/doctor/drugs/frequency',
        rules: [
          {
            required: true,
            message: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
            trigger: 'change'
          }
        ]
      },
      {
        label: $t('recipeTemplate.takeType'),
        prop: 'takeMethods',
        type: 'select',
        span: 12,
        display: type === 1,
        filterable: true,
        placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.takeType'),
        dicData: [
          {
            label: $t('recipeTemplate.inEat'),
            value: $t('recipeTemplate.inEat')
          },
          {
            label: $t('recipeTemplate.outEat'),
            value: $t('recipeTemplate.outEat')
          }
        ]
      },
      {
        label: $t('recipeTemplate.takeRequire'),
        prop: 'takeRequire',
        type: 'select',
        span: 12,
        display: type === 1,
        filterable: true,
        placeholder:
          $t('common.pleaseSelect') + $t('recipeTemplate.takeRequire'),
        dicData: [
          {
            label: $t('recipeTemplate.beforeEat'),
            value: $t('recipeTemplate.beforeEat')
          },
          {
            label: $t('recipeTemplate.afterEat'),
            value: $t('recipeTemplate.afterEat')
          }
        ]
      },
      {
        label: $t('recipeTemplate.payNum'),
        prop: 'buy_count',
        type: 'number',
        min: 1,
        placeholder: $t('common.pleaseInput') + $t('recipeTemplate.payNum'),
        span: 12,
        display: type === 1,
        rules: [
          {
            required: true,
            message: $t('common.pleaseInput') + $t('recipeTemplate.payNum'),
            trigger: 'blur'
          }
        ]
      },
      {
        label: $t('recipeTemplate.takeDrugDays'),
        prop: 'usage_day',
        type: 'select',
        span: 12,
        display: type === 1,
        filterable: true,
        placeholder:
          $t('common.pleaseSelect') + $t('recipeTemplate.takeDrugDays'),
        dicData: [
          {
            label: $t('recipeTemplate.takeDrugDays1'),
            value: $t('recipeTemplate.takeDrugDays1')
          },
          {
            label: $t('recipeTemplate.takeDrugDays2'),
            value: $t('recipeTemplate.takeDrugDays2')
          },
          {
            label: $t('recipeTemplate.takeDrugDays3'),
            value: $t('recipeTemplate.takeDrugDays3')
          },
          {
            label: $t('recipeTemplate.takeDrugDays4'),
            value: $t('recipeTemplate.takeDrugDays4')
          },
          {
            label: $t('recipeTemplate.takeDrugDays5'),
            value: $t('recipeTemplate.takeDrugDays5')
          },
          {
            label: $t('recipeTemplate.takeDrugDays6'),
            value: $t('recipeTemplate.takeDrugDays6')
          },
          {
            label: $t('recipeTemplate.takeDrugDays7'),
            value: $t('recipeTemplate.takeDrugDays7')
          }
        ]
      }
    ]
  }
}
