<template>
  <div class="common-word">
    <div class="search-content">
      <el-input v-model="keyword"
                :placeholder="$t('consultRoom.searchWordTip')"
                size="mini"
                clearable />
      <el-button size="mini"
                 type="primary"
                 @click="fetchData">
        {{ $t('common.inquire') }}</el-button>
      <el-button size="mini"
                 type="primary"
                 @click="handleEdit(null)">
        {{ $t('consultRoom.addCommonWord') }}</el-button>
    </div>
    <avue-crud v-show="!showEdit"
               ref="crud"
               v-model="formData"
               :data="wordList"
               :row-class-name="() => {return 'pointer'}"
               :table-loading="tableLoading"
               :option="wordTableOption"
               @cell-click="selectWord"
               @sortable-change="sortableChange"
               @on-load="fetchData">
      <template slot-scope="{ row }"
                slot="menu">
        <el-button type="text"
                   size="medium"
                   class="text-edit"
                   @click="handleEdit(row)">{{ $t('common.edit') }}
        </el-button>
        <el-button type="text"
                   size="medium"
                   class="text-delete"
                   @click="handleDelete(row)">{{ $t('common.delete') }}
        </el-button>
        <el-button type="text"
                   size="medium"
                   style="margin-left: 0"
                   @click="updateWord(row)">{{ $t('consultRoom.changeTop') }}
        </el-button>
      </template>
    </avue-crud>
    <div v-show="showEdit"
         class="form">
      <div class="form-header">
        {{ currentData? $t('consultRoom.editCommonWord'): $t('consultRoom.addCommonWord') }}
      </div>
      <el-input v-model="formData.content"
                :placeholder="$t('consultRoom.searchWordTip1')"
                :rows="6"
                resize="none"
                type="textarea" />
      <div class="form-botton">
        <el-button :loading="isLoading"
                   type="primary"
                   size="medium"
                   @click.stop="updateWord(null)">{{ $t('common.affirm') }}</el-button>
        <el-button size="medium"
                   @click.stop="showEdit = false">
          {{ $t('common.cancel') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { consultRoomApi } from '@/api/consultRoomApi'
import { wordTableOption } from '@/const/crud/consultRoom'

export default {
  name: 'CommonWord',
  components: {},
  data () {
    return {
      wordTableOption,
      formData: {},
      tableLoading: false,
      wordList: [],
      keyword: '',
      showEdit: false,
      currentData: {},
      isLoading: false
    }
  },
  methods: {
    // 拖拽
    sortableChange (oldindex, newindex, row, list) {
      // console.log(oldindex, newindex, row, list)
    },
    // 获取常用话术
    async fetchData () {
      try {
        this.tableLoading = true
        const res = await consultRoomApi.fetchCommonWord({
          keyword: this.keyword
        })
        this.wordList = res.data.data || []
        this.tableLoading = false
      } catch (err) {
        this.tableLoading = false
        console.error(err)
      }
    },
    // 置顶
    async handleTop (row) {
      try {
      } catch (err) {
        console.error(err)
      }
    },
    handleEdit (row) {
      this.currentData = row
      this.formData.content = this.currentData ? this.currentData.content : ''
      this.formData = { ...this.formData }
      this.showEdit = true
    },
    async updateWord (row) {
      try {
        this.isLoading = true
        if (row) {
          await consultRoomApi.updateCommonWord({
            sort: 999,
            id: row.id
          })
        } else {
          this.currentData
            ? await consultRoomApi.createCommonWord({
              content: this.formData.content,
              id: this.currentData.id
            })
            : await consultRoomApi.createCommonWord({
              content: this.formData.content
            })
        }
        this.$message.success(this.$t('common.operateSucc'))
        this.fetchData()
        this.isLoading = false
        this.showEdit = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    async handleDelete (row) {
      // await this.$confirm(
      //   this.$t('consultRoom.deleteWordTip'),
      //   this.$t('common.tip'),
      //   {
      //     confirmButtonText: this.$t('common.confirm'),
      //     cancelButtonText: this.$t('common.cancel'),
      //     type: 'warning'
      //   }
      // )
      try {
        await consultRoomApi.deleteCommonWord({
          id: row.id
        })
        this.fetchData()
        this.$message.success(this.$t('common.operateSucc'))
      } catch (err) {
        console.error(err)
      }
    },
    selectWord (row, column) {
      if (column.property == 'content') {
        this.$emit('selectWord', row.content)
        this.$emit('closeCommonWord')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-table::before {
  height: 0px;
}
/deep/ .el-table__fixed-right::before,
.el-table__fixed::before {
  height: 0px;
}
/deep/ .avue-crud__menu {
  display: none;
}
.common-word {
  .search-content {
    display: flex;
    margin-bottom: 20px;
    .el-input {
      width: 240px;
      margin-right: 10px;
    }
  }
  .form {
    background-color: #f9f9f9;
    height: 262px;
    padding: 0 30px;
    .form-header {
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
    .form-botton {
      text-align: center;
      margin: 20px 0;
      .el-button {
        width: 100px;
      }
    }
  }
  .text-delete {
    color: #999;
    margin-left: 20px;
    margin-right: 20px;
    &:hover {
      color: #e02020;
    }
  }
}
</style>
