<template>
  <div class="visit-prescrib">
    <div class="patient-info weight-bold">
      <el-tooltip :content="patientInfo.user||''"
                  class="item"
                  effect="dark"
                  placement="bottom">
        <span :style="{color: nameColor,flex: 1}"
              class="overflow-1">{{ patientInfo.user||'' }}</span>
      </el-tooltip>
      <span>{{ patientInfo.gender||'' }}</span>
      <span :style="{color: age<18||age>60?'#f00':'#333'}">{{ patientInfo.age||'' }}</span>
      <span style="margin-top: 4px;">{{ patientInfo.weight||'' }}</span>
    </div>
    <el-row>
      <el-col :span="7"
              class="info-item"
              style="justify-content: flex-start;">
        <label>{{ $t('consultRoom.liverFunction') }}：</label>
        <span :style="{color: patientInfo.gang==1?'#333':'#f00'}"
              class="pointer"
              @click="handleDialog('gangSelect')">{{ patientInfo.gang==1?'正常':patientInfo.gang==0?'异常':'' }}</span>
      </el-col>
      <el-col :span="7"
              class="info-item"
              style="justify-content: flex-start;">
        <label>{{ $t('consultRoom.renalFunction') }}：</label>
        <span :style="{color: patientInfo.shen==1?'#333':'#f00'}"
              class="pointer"
              @click="handleDialog('shenSelect')">{{ patientInfo.shen==1?'正常':patientInfo.shen==0?'异常':'' }}</span>
      </el-col>
      <el-col :span="10"
              :style="showRed?'color:red':''"
              class="info-item"
              style="justify-content: flex-start;">
        <label>{{ $t('consultRoom.pregnancy') }}：</label>
        <span :style="{color: patientInfo.pregnancy==0 && !showRed?'#333':'#f00'}"
              class="pointer"
              @click="handleDialog('pregnancySelect')">{{ patientInfo.pregnancy==1?'是':patientInfo.pregnancy==0?'否认':'' }}</span>
      </el-col>
    </el-row>
    <div class="diagnose-tags">
      <div class="diagnose-title weight-bold">诊断信息</div>
      <div class="diagnose">
        <div class="diagnose-content">
          <div v-for="(item,index) in selectdiagList"
               :key="index"
               class="tag select-tag">
            <el-tooltip v-if="!item.show"
                        :content="item.value"
                        class="item"
                        effect="dark"
                        placement="bottom"
                        @click.native="openEdit(item,index)">
              <span>{{ item.value }}</span>
            </el-tooltip>
            <el-input v-else
                      v-model="item.value"
                      :ref="'input'+index"
                      :placeholder="$t('common.pleaseInput')+$t('consultRoom.diagnose')"
                      size="mini"
                      @blur="inputBlur(item)" />
            <el-tooltip v-if="selectdiagList.length>1"
                        :disabled="!item.value"
                        :content="$t('common.delete')"
                        class="item"
                        effect="dark"
                        placement="bottom"
                        @click.native="deleteTag(index)">
              <i class="el-icon-error close" />
            </el-tooltip>
          </div>
        </div>
        <!-- <div v-if="showicd==1"
             class="showicd"
             style="color: #11c529;">{{ $t('consultRoom.checkPassTip') }}</div>
        <div v-if="showicd==2"
             class="showicd"
             style="color: #e02020;">{{ $t('consultRoom.checkUnpassTip') }}</div> -->
      </div>
      <div class="add-diagnose">
        <el-select v-model="diagnoseType"
                   size="mini"
                   style="width: 100px;margin-right: 10px;"
                   @change="changeType">
          <el-option v-for="(item,index) in [{type:1,name:$t('consultRoom.westDiagnose')},{type:2,name:$t('consultRoom.chineseDiagnose')}]"
                     :key="index"
                     :label="item.name"
                     :value="item.type" />
        </el-select>
        <el-select v-loadmore="loadMore"
                   v-model="diagnose"
                   :loading="isLoading"
                   :disabled="!currentUserId"
                   :placeholder="$t('consultRoom.addDiagnose')"
                   :remote-method="fetchDiagnose"
                   filterable
                   allow-create
                   size="mini"
                   reserve-keyword
                   default-first-option
                   remote
                   @change="selectDiagnose">
          <el-option v-for="item in diagnoseList"
                     :key="item.id"
                     :label="item.name"
                     :value="item.name" />
        </el-select>
      </div>
      <div :style="{height:showMore?'auto':'36px',overflow: 'hidden'}"
           class="symptoms">
        <div ref="diagContentRef"
             class="diagnose-content">
          <label>{{ $t('consultRoom.drugSymptoms') }}：</label>
          <div v-if="!symptomsList || !symptomsList.length"
               class="no">{{ $t('common.no') }}</div>
          <div v-for="(item,index) in symptomsList"
               :key="index"
               class="tag">
            <el-tooltip :disabled="!item.icd_name"
                        :content="item.icd_name"
                        class="item"
                        effect="dark"
                        placement="bottom"
                        @click.native="selectDiagnose(item.icd_name)">
              <span>{{ item.icd_name }}</span>
            </el-tooltip>
            <el-tooltip :disabled="!item.icd_name"
                        :content="$t('common.delete')"
                        class="item"
                        effect="dark"
                        placement="bottom"
                        @click.native="symDeleteTag(index)" />
          </div>
        </div>
        <div class="right-icon pointer"
             @click="()=>{showMore=!showMore}">
          <span>{{ showMore?'收起':'展开' }}</span>
          <i :class="showMore?'el-icon-arrow-up':'el-icon-arrow-down'" />
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="gangSelect"
               :append-to-body="true"
               title="修改肝功能"
               width="25%">
      <el-radio-group v-model="patientInfo.gang"
                      @change="changeInfo($event,'gang')">
        <el-radio label="1">正常</el-radio>
        <el-radio label="0">异常</el-radio>
      </el-radio-group>
    </el-dialog>
    <el-dialog :visible.sync="shenSelect"
               :append-to-body="true"
               title="修改肾功能"
               width="25%">
      <el-radio-group v-model="patientInfo.shen"
                      @change="changeInfo($event,'shen')">
        <el-radio label="1">正常</el-radio>
        <el-radio label="0">异常</el-radio>
      </el-radio-group>
    </el-dialog>
    <el-dialog :visible.sync="pregnancySelect"
               :append-to-body="true"
               title="修改备孕/怀孕/哺乳期"
               width="25%">
      <el-radio-group v-model="patientInfo.pregnancy"
                      @change="changeInfo($event,'pregnancy')">
        <el-radio label="1">是</el-radio>
        <el-radio label="0">否认</el-radio>
      </el-radio-group>
    </el-dialog>
  </div>
</template>

<script>
import {
  consultRoomApi
} from '@/api/consultRoomApi'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import checkDrug from '@/socketMsg/checkDrug.js'

export default {
  props: {
    showicd: {
      type: Number,
      default: 0
    },
    activeType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      diagnoseType: 1,
      diagnose: '',
      diagnoseList: [],
      selectdiagList: [],
      symptomsList: [],
      isLoading: false,
      oldValue: '',
      showRed: false,
      drugUseData: {},
      gangSelect: false,
      shenSelect: false,
      pregnancySelect: false,
      showMore: false,
      searchDiagnose: '',
      nameColor: '#333',
      age: ''
    }
  },
  computed: {
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    patientInfo () {
      return socketPublic.state.currentUserData.patientInfo
    },
    drugList () {
      return socketPublic.state.currentUserData.drugList
    },
    westernDrugList () {
      return socketPublic.state.currentUserData.westernDrugList
    },
    chineseDrugList () {
      return socketPublic.state.currentUserData.chineseDrugList
    },
    checkDrugData () {
      return socketPublic.state.checkDrugData
    }
  },
  watch: {
    patientInfo: {
      handler (newVal) {
        this.age = newVal.age ? newVal.age.slice(0, -1) : ''
        if (newVal.user) {
          if (newVal.user.length === 1 || newVal.user.length > 3) {
            this.nameColor = '#f00'
          } else {
            this.nameColor = '#333'
          }
        }
      },
      deep: true,
      immediate: true
    },
    currentUserId () {
      this.symptomsList = []
    },
    checkDrugData: {
      handler (newVal, oldVal) {
        if (!newVal) return
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.fetchDrugSuit()
      },
      deep: true
    },
    currentUserData: {
      handler (val) {
        this.selectdiagList =
          this.currentUserData.customDiagnose &&
            this.currentUserData.customDiagnose.length
            ? this.currentUserData.customDiagnose : []
        this.doDiagnoseCheck()
      },
      deep: true
    },
    'currentUserData.recipeType': {
      handler (val) {
        this.diagnoseType = val || 1
        if (val) this.fetchDiagnose()
      },
      immediate: true
    },
    drugList: {
      handler (newVal, oldVal) {
        if (newVal && newVal.length > 0 && newVal.length !== oldVal.length) this.fetchDrugSuit()
      },
      deep: true
    },

    activeType (val, oldVal) {
      if (val === oldVal) return
      if (!this.currentUserData.clinicType) return
      if (val === this.$t('consultRoom.westMedicineRecipe') || val === this.$t('consultRoom.chineseMedicineRecipe')) {
        this.diagnoseType = val === this.$t('consultRoom.westMedicineRecipe') ? 1 : 2
        this.fetchDiagnose()
        this.symptomsList = []
        this.fetchDrugSuit()
        this.currentUserData.customDiagnose = []
        inquiry.commonSetCache({
          name: 'currentUserData_' + this.currentUserId,
          content: this.currentUserData
        })
      }
    }
  },
  created () {
    this.selectdiagList =
      this.currentUserData.customDiagnose &&
        this.currentUserData.customDiagnose.length
        ? this.currentUserData.customDiagnose : []
  },
  methods: {
    // 获取诊断列表
    async loadMore () {
      try {
        const res = this.diagnoseType === 1
          ? await consultRoomApi.fetchWestDrugDiagnose({
            keyword: this.searchDiagnose || '',
            page: ++this.page
          })
          : await consultRoomApi.fetchChineseDrugDiagnose({
            keyword: this.searchDiagnose || '',
            page: ++this.page
          })
        this.diagnoseList = [...this.diagnoseList, ...res.data.data]
      } catch (err) {
        console.error(err)
      }
    },

    // 获取诊断列表
    async fetchDiagnose (val) {
      try {
        this.searchDiagnose = val
        this.page = 1
        this.isLoading = true
        const res =
          this.diagnoseType === 1
            ? await consultRoomApi.fetchWestDrugDiagnose({
              keyword: val || '',
              page: this.page
            })
            : await consultRoomApi.fetchChineseDrugDiagnose({
              keyword: val || '',
              page: this.page
            })
        this.diagnoseList = res.data.data || []
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    // 获取药品适用症
    async fetchDrugSuit () {
      try {
        let drugList = []
        if (this.currentUserData.clinicType) {
          if (
            this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ||
            this.activeType === this.$t('consultRoom.chineseMedicineRecipeTemp')
          ) {
            this.$message.warning('请先选择西药处方/中药处方,再进行重新检测')
            return
          }
          if (this.activeType === this.$t('consultRoom.westMedicineRecipe')) {
            drugList = this.currentUserData.westernDrugList
          } else {
            drugList = this.currentUserData.chineseDrugList
          }
        } else {
          drugList = this.currentUserData.drugList
        }
        await this.$nextTick()
        if (this.currentUserData.companyType == 2 && this.currentUserData.visitType && drugList.length) {
          const list = []
          drugList.forEach(item => {
            const diagnoseList = item.diagnose ? item.diagnose.split(';') : []
            diagnoseList.forEach(dItem => {
              list.push(dItem)
            })
          })
          this.symptomsList = [...new Set(list)].map(item => {
            return {
              icd_name: item
            }
          })
          return
        } else if (this.currentUserData.companyType == 2 && !this.currentUserData.visitType) {
          this.symptomsList = this.currentUserData.indicationList.map(item => {
            return {
              icd_name: item.icdName
            }
          })
          return
        }
        const res = await consultRoomApi.fetchDrugSuit({
          drugIds: drugList.length > 0
            ? drugList.map((item) => item.drugId).join(',') : ''
        })
        this.symptomsList = res.data.data || []
      } catch (err) {
        console.error(err)
      }
    },
    // 发送socket诊断信息
    sendDiagnose () {
      const customDiagnose = this.selectdiagList
      this.currentUserData.customDiagnose = customDiagnose
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
      inquiry.doctorModify({
        key: 4,
        value: customDiagnose.map(item => item.value).join(';')
      }, this.currentUserId)
      checkDrug.newCheckDrugs()
      this.addChangeAdvises()
    },
    addChangeAdvises () {
      let drugList = null
      if (this.currentUserData.clinicType) {
        if (
          this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ||
          this.activeType === this.$t('consultRoom.chineseMedicineRecipeTemp')
        ) {
          return
        }
        if (this.activeType === this.$t('consultRoom.westMedicineRecipe')) {
          drugList = this.currentUserData.westernDrugList
        } else {
          drugList = this.currentUserData.chineseDrugList
        }
      } else {
        drugList = this.currentUserData.drugList
      }
      const drugNames = drugList.map(item => item.drugName).join(',')
      const diagnoseNames = this.currentUserData.customDiagnose.map(item => item.value).join(',')
      socketPublic.commit('setChangeAdvises', [])
      const changeAdvises = socketPublic.state.changeAdvises || []
      const xgzdTxt = `请问【${drugNames}】是用于治疗【${diagnoseNames}】吗？如果是请回复【是】`
      // const xgzdTxtShow = `请问【<span style="color:#fd9c12">${drugNames}</span>】是用于治疗【<span style="color:#fd9c12">${diagnoseNames}</span>】吗？如果是请回复【是】`
      let exists = false
      if (changeAdvises && changeAdvises.length > 0) {
        exists = changeAdvises.some((advice) => {
          return advice.tip.includes(xgzdTxt)
        })
      }
      if (!exists) {
        changeAdvises.push({
          drugNames,
          diagnoseNames,
          tip: xgzdTxt
        })
        socketPublic.commit('setChangeAdvises', changeAdvises)
      }
    },
    // 改变诊断类型
    changeType () {
      this.diagnose = ''
      this.fetchDiagnose(null)
    },
    selectDiagnose (val) {
      if (!this.selectdiagList.find((item) => item.value == val)) {
        this.selectdiagList.push({
          show: false,
          value: val
        })
        this.diagnose = ''
        this.sendDiagnose()
        // 如果未怀孕
        this.doDiagnoseCheck()
      }
    },
    doDiagnoseCheck () {
      // 未怀孕,跟诊断对不上
      const patientInfo = this.currentUserData.patientInfo
      const that = this
      that.showRed = false
      if (patientInfo.pregnancy == '0') {
        this.selectdiagList.forEach(function (ele) {
          if (ele.value.indexOf('早产') != -1 ||
            ele.value.indexOf('产科') != -1 ||
            ele.value.indexOf('先兆流产') != -1 ||
            ele.value.indexOf('孕期') != -1 ||
            ele.value.indexOf('胎动') != -1 ||
            ele.value.indexOf('安胎') != -1 ||
            ele.value.indexOf('孕妇') != -1 ||
            (ele.value.indexOf('孕') != -1 && ele.value.indexOf('周') != -1)
          ) {
            // 显示红色
            that.showRed = true
          }
        })
      }
    },
    openEdit (item, index) {
      item.show = true
      this.$nextTick(() => {
        this.$refs['input' + index][0].focus()
      })
      this.oldValue = item.value
    },
    inputBlur (item) {
      if (!item.value) item.value = this.oldValue
      item.show = false
      this.selectdiagList = [...this.selectdiagList]

      // 对诊断进行孕期判断

      if (this.oldValue !== item.value) {
        this.sendDiagnose()
      }
    },
    deleteTag (index) {
      this.selectdiagList.splice(index, 1)
      this.sendDiagnose()
      this.doDiagnoseCheck()
    },
    symDeleteTag (index) {
      this.symptomsList.splice(index, 1)
    },
    handleDialog (dialogValue) {
      this[dialogValue] = true
    },
    changeInfo (e, type) {
      const diseaseTxt = type == 'pregnancy' ? e === 1 ? '正常' : '异常' : e === 1 ? '是' : '否认'
      const diseaseValue = type == 'gang' ? {
        gang: e,
        shen: this.patientInfo.shen,
        pregnancy: this.patientInfo.pregnancy
      } : type == 'shen' ? {
        gang: this.patientInfo.gang,
        shen: e,
        pregnancy: this.patientInfo.pregnancy
      } : type == 'pregnancy' ? {
        gang: this.patientInfo.gang,
        shen: this.patientInfo.shen,
        pregnancy: e
      } : {}
      const modifyData = {
        key: type == 'gang' ? 7 : type == 'shen' ? 8 : type == 'pregnancy' ? 9 : '',
        value: diseaseTxt,
        dbValue: JSON.stringify(diseaseValue)
      }
      const currentUserData = this.deepClone(this.currentUserData)
      currentUserData.patientInfo[type] = e
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: currentUserData
      })
      inquiry.doctorModify(modifyData, this.currentUserId)
      this.gangSelect = false
      this.shenSelect = false
      this.pregnancySelect = false
      checkDrug.newCheckDrugs()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1550px) {
  label,
  .tag,
  .el-input {
    font-size: 12px !important;
  }
}

label {
  font-size: 14px;
  font-weight: 400;
}

.visit-prescrib {
  padding: 0 12px;
  font-size: 14px;
  .patient-info {
    display: flex;
    width: fit-content;
    padding: 4px 0;
    margin-bottom: 10px;
    font-size: 18px;
    span {
      margin-right: 30px;
    }
  }
  .diagnose-tags {
    font-size: 14px;
    margin-top: 10px;
    .diagnose-title {
      padding: 6px 0;
    }
    .max-weights {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;

      &:nth-child(1) {
        font-weight: 600;

        label {
          font-size: 18px !important;
        }

        .el-input {
          font-size: 18px;
        }

        span {
          font-size: 18px;
        }
      }

      &:nth-child(2) {
        height: 32px;
        line-height: 32px;

        label {
          font-weight: normal;
          font-size: 14px;
        }

        .el-input {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }
      }

      .el-col {
        height: 100%;

        & > div {
          height: 100%;
        }
      }

      label {
        font-weight: 600;
        font-size: 16px;
      }

      .el-input {
        flex: 1;
        font-size: 14px;

        /deep/ .el-input__inner {
          border: none;
        }
      }

      /deep/ .input-red {
        input {
          color: #f00;
        }
      }
    }

    .diagnose,
    .symptoms {
      // height: 100px;
      position: relative;
      display: flex;

      label {
        height: 32px;
        line-height: 32px;
      }

      .tag {
        height: 32px;
        display: flex;
        align-items: center;
        border: 1px solid #d8d8d8;
        padding: 0 5px;
        margin-right: 10px;
        margin-bottom: 4px;
        &.select-tag {
          background: #e8f6ff;
          border: 1px solid #3b94ff;
          color: #3b94ff;
        }

        &:hover {
          border: 1px solid #3b94ff;
          color: #3b94ff;
        }

        span {
          cursor: pointer;
          display: block;
          max-width: 90px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .el-input {
          /deep/ .el-input__inner {
            border: none;
            padding: 0;
          }
        }

        .close {
          color: #3b94ff;
          font-size: 16px;
          cursor: pointer;
          margin-left: 6px;

          &:hover {
            color: #e40013;
          }
        }
      }

      .no {
        line-height: 32px;
      }

      /deep/ .el-select {
        .el-input__inner {
          height: 32px !important;
          line-height: 32px;
        }
      }

      .diagnose-content {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }

      .showicd {
        width: 76px;
        align-self: center;
      }
      .right-icon {
        color: #3b94ff;
        margin-top: 5px;
      }
    }
  }

  .add-diagnose {
    margin: 10px 0;
  }

  .diagnose-table {
    margin-top: 20px;

    .delete {
      color: #999;

      &:hover {
        color: #e40013;
      }
    }
  }
}
</style>
