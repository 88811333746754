var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "1100px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "edit-template" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                title: _vm.$t("recipeTemplate.selectDrugs"),
                "popper-options": {
                  boundariesElement: "viewport",
                  removeOnDestroy: true,
                },
                placement: "top-start",
                width: "1000",
                trigger: "manual",
              },
              model: {
                value: _vm.showDrugList,
                callback: function ($$v) {
                  _vm.showDrugList = $$v
                },
                expression: "showDrugList",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "search-drug",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", "margin-right": "20px" },
                    attrs: {
                      placeholder: _vm.$t("recipeTemplate.searchTip1"),
                      size: "small",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.fetchDrugsData($event)
                      },
                    },
                    model: {
                      value: _vm.drugName,
                      callback: function ($$v) {
                        _vm.drugName = $$v
                      },
                      expression: "drugName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.fetchDrugsData },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.inquire")))]
                  ),
                ],
                1
              ),
              _c("avue-crud", {
                ref: "crud",
                attrs: {
                  page: _vm.page,
                  data: _vm.drugList,
                  "table-loading": _vm.tableLoading,
                  option: _vm.drugTableOption,
                  "row-class-name": function () {
                    return "pointer"
                  },
                },
                on: {
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "on-load": _vm.fetchDrugsData,
                  "row-click": _vm.selectDrug,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "template-content" }, [
            _c(
              "div",
              { staticClass: "template-left" },
              [
                _c("div", { staticClass: "left-tip" }, [
                  _vm._v(_vm._s(_vm.$t("recipeTemplate.drugsInfoTip"))),
                ]),
                _c("avue-form", {
                  ref: "leftForm",
                  attrs: { option: _vm.leftOption },
                  on: { submit: _vm.leftSubmit },
                  model: {
                    value: _vm.leftFormData,
                    callback: function ($$v) {
                      _vm.leftFormData = $$v
                    },
                    expression: "leftFormData",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "template-right" },
              [
                _c(
                  "div",
                  { staticClass: "content-list" },
                  _vm._l(_vm.contentList, function (item, index) {
                    return _c("div", { key: index, staticClass: "list-item" }, [
                      _vm.recipeType ===
                      _vm.$t("recipeRecord.westMedicineRecipe")
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                item.drug_name +
                                  " " +
                                  item.usage +
                                  " " +
                                  item.dosage +
                                  item.dosage_unit +
                                  " " +
                                  item.frequency +
                                  " 共" +
                                  item.day_count +
                                  "天 " +
                                  item.buy_count +
                                  item.buy_unit
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm.recipeType ===
                      _vm.$t("recipeRecord.chineseMedicineRecipe")
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                item.drug_name +
                                  " " +
                                  item.dosage +
                                  item.dosage_unit +
                                  " " +
                                  item.usage
                              )
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(index)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("common.delete")))]
                      ),
                    ])
                  }),
                  0
                ),
                _c("avue-form", {
                  ref: "rightForm",
                  attrs: { option: _vm.rightOption },
                  on: { submit: _vm.rightSubmit },
                  scopedSlots: _vm._u([
                    {
                      key: "diagnose_info",
                      fn: function (ref) {
                        var size = ref.size
                        return [
                          _c(
                            "el-select",
                            {
                              ref: "diagnoseInfo",
                              attrs: {
                                loading: _vm.isLoading,
                                "multiple-limit": 3,
                                size: size,
                                "remote-method": _vm.fetchDiagnose,
                                filterable: "",
                                multiple: "",
                                "value-key": "id",
                                placeholder: "请搜索后再选择诊断",
                                remote: "",
                              },
                              model: {
                                value: _vm.rightFormData.diagnose_info,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.rightFormData,
                                    "diagnose_info",
                                    $$v
                                  )
                                },
                                expression: "rightFormData.diagnose_info",
                              },
                            },
                            _vm._l(_vm.diagnoseList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.rightFormData,
                    callback: function ($$v) {
                      _vm.rightFormData = $$v
                    },
                    expression: "rightFormData",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }