<template>
  <div class="main-container">
    <avue-crud ref="crud"
               :defaults.sync="defaults"
               :search.sync="paramsSearch"
               :page.sync="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="fetchData"
               @search-change="handleSubmit">
      <template #menu="{ row }">
        <el-button type="text"
                   size="small"
                   @click="viewDetail(row)">{{ $t('recipeRecord.recipeDetail') }}</el-button>
      </template>
      <template slot-scope="{row}"
                slot="status">
        <div v-html="row.status" />
      </template>
      <template slot-scope="{ size }"
                slot="isNowSearch">
        <el-checkbox v-model="paramsSearch.isNow"
                     :size="size"
                     @change="changeCheckbox">{{ $t('recipeRecord.today') }}</el-checkbox>
      </template>
    </avue-crud>
    <detail v-if="showDetail"
            ref="detail"
            :url="currentData.recipe_url" />
    <!-- <iframe v-if="showDetail"
            ref="Iframe"
            src="http://localhost:8087/#/recipeDetail?recipeCode=20221114203236159011"
            frameborder="0"
            style="height: 100vh;width: 100vw;position:fixed;left:0;top:0;z-index: 99999"></iframe> -->
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/recipeRecord'
import { recipeRecordApi } from '@/api/functionApi'
import { defaultRecipeDate, dateFormat } from '@/util/date'
import { mapGetters } from 'vuex'
import moment from 'moment'
import detail from './detail'

export default {
  name: 'RecipeRecord',
  components: {
    detail
  },
  data () {
    return {
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      paramsSearch: {
        startTime: moment().format('YYYY-MM-DD'),
        endTime: moment().format('YYYY-MM-DD'),
        isNow: true
      },
      tableLoading: false,
      tableOption,
      isExport: false,
      selectList: [],
      currentData: {},
      showDetail: false,
      defaults: null
    }
  },
  computed: {
    ...mapGetters(['doctorInfo'])
  },
  watch: {
    'paramsSearch.recipe_date': {
      handler (list) {
        if (list && list.length > 0 && list.find(item => item != moment().format('YYYY-MM-DD'))) this.paramsSearch.isNow = false
      }
    }
  },
  created () {
    this.fetchData()
    if (this.doctorInfo.career_type == '1') {
      this.$nextTick(() => {
        this.defaults.dispensary_name.hide = true
      })
    }
  },
  methods: {
    changeCheckbox (val) {
      this.paramsSearch.recipe_date = val ? [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] : [defaultRecipeDate().startData, defaultRecipeDate().endData]
    },
    async fetchData () {
      try {
        if (dateFormat(+new Date(this.paramsSearch.startTime), 'YYYY-MM') !== dateFormat(+new Date(this.paramsSearch.endTime), 'YYYY-MM')) {
          this.$message.warning('不支持跨月查询，请重新选择时间段')
          return
        }
        this.tableLoading = true
        const res = await recipeRecordApi.fetchData({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          ...this.paramsSearch
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (err) {
        console.error(err)
        this.tableLoading = false
      }
    },
    // 查看详情
    viewDetail (row) {
      this.currentData = row
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.detail.isVisible = true
      })
      // window.open('http://localhost:8087/#/recipeDetail?recipeCode=20221114203236159011')
    },
    // 查询
    handleSubmit (params, done) {
      [params.startTime, params.endTime] = params.recipe_date
        ? params.recipe_date
        : []
      delete params.recipe_date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
</style>
