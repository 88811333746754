var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aide-home-drug" },
    [
      !_vm.drugDetailList || _vm.drugDetailList.length <= 0
        ? _c("el-empty", { attrs: { description: "暂无药品信息" } })
        : _c(
            "div",
            { staticClass: "drug-list" },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "drug-active",
                  on: { "tab-click": _vm.switchDrug },
                  model: {
                    value: _vm.drugActiveObj,
                    callback: function ($$v) {
                      _vm.drugActiveObj = $$v
                    },
                    expression: "drugActiveObj",
                  },
                },
                _vm._l(_vm.drugDetailList, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.labels, name: index + "" },
                  })
                }),
                1
              ),
              _c("div", { staticClass: "drug-detail" }, [
                _c("table", [
                  _c("tr", [
                    _c("td", [
                      _c("div", { staticClass: "drug-img" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.drugDetailObj.drugBase
                              ? _vm.drugDetailObj.drugBase.drugImageUrl
                              : "",
                            width: "80",
                            height: "80",
                          },
                        }),
                      ]),
                    ]),
                    _c("td", [
                      _c("div", { staticClass: "drug-desc" }, [
                        _c("div", { staticClass: "desc-title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.drugDetailObj.drugBase
                                  ? _vm.drugDetailObj.drugBase.drugName
                                  : ""
                              )
                          ),
                        ]),
                        _c("div", { staticClass: "desc-text" }, [
                          _vm._v(
                            "通用名：" +
                              _vm._s(
                                _vm.drugDetailObj.drugBase
                                  ? _vm.drugDetailObj.drugBase.commDrug
                                  : ""
                              )
                          ),
                        ]),
                        _c("div", { staticClass: "desc-text" }, [
                          _vm._v(
                            "批准文号：" +
                              _vm._s(
                                _vm.drugDetailObj.drugBase
                                  ? _vm.drugDetailObj.drugBase.approvalNumber
                                  : ""
                              )
                          ),
                        ]),
                        _c("div", { staticClass: "desc-text" }, [
                          _vm._v(
                            "生产企业：" +
                              _vm._s(
                                _vm.drugDetailObj.drugBase
                                  ? _vm.drugDetailObj.drugBase.company
                                  : ""
                              )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("用法用量"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.drugDetailObj.drugBase
                            ? _vm.drugDetailObj.drugBase.usageAndDosage
                            : ""
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [_vm._v("禁忌")]),
                    _c("td", {
                      staticClass: "line-right c-error",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.drugDetailObj.drugBase
                            ? _vm.drugDetailObj.drugBase.taboo
                            : ""
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("参考诊断"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.drugDetailObj.drugBase
                            ? _vm.drugDetailObj.drugBase.majorFunction
                            : ""
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("适用疾病"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.drugDetailObj.drugBase
                            ? _vm.drugDetailObj.drugBase.majorFunction
                            : ""
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("适用年龄"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.drugDetailObj.age == "0-120"
                            ? "全年龄段"
                            : _vm.drugDetailObj.age
                        )
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("适用性别"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(_vm._s(_vm.drugDetailObj.sex)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("儿童用药"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isDisableChildren]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("老年用药"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isDisableChildren]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("肝功能异常"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isLiverDamage]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("肾功能异常"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isRenalDamage]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("妊娠孕妇用药"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isDisableWoman]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("哺乳期用药"),
                    ]),
                    _c("td", {
                      staticClass: "line-right",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.shtml[_vm.drugDetailObj.isDisableGestational]
                        ),
                      },
                    }),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("药物过敏"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.drugDetailObj.isDisableAllergy == 1
                              ? "本药品任何成分过敏者慎用"
                              : "本药品任何成分过敏者禁用"
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "line-left" }, [
                      _vm._v("不良反应"),
                    ]),
                    _c("td", { staticClass: "line-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.drugDetailObj.adverseReactions) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }