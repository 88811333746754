<template>
  <div class="main-container">
    <div class="line-chart">
      <lineEcharts />
    </div>
    <div class="crud">
      <avue-crud ref="crud"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :option="tableOption"
                 v-model="formData"
                 @on-load="fetchData"
                 @search-change="handleSubmit" />
    </div>
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/preStatistic'
import { preStatisticApi } from '@/api/functionApi'
import { dateFormat } from '@/util/date'
import lineEcharts from './lineEcharts'

export default {
  name: 'PreStatistic',
  components: {
    lineEcharts
  },
  data () {
    return {
      formData: {},
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      paramsSearch: {
        tjDate:
          dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD') +
          ';' +
          dateFormat(+new Date(), 'YYYY-MM-DD')
      },
      tableLoading: false,
      tableOption
    }
  },
  methods: {
    async fetchData () {
      try {
        this.tableLoading = true
        const res = await preStatisticApi.fetchData({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          ...this.paramsSearch
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (err) {
        console.error(err)
        this.tableLoading = false
      }
    },
    handleSubmit (params, done) {
      params.tjDate = params.date ? params.date.join(';') : ''
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
.main-container {
  padding: 4px;
  background-color: #d9d9d9;
  .crud {
    margin-top: 30px;
    background-color: #fff;
    height: 300px;
    border-radius: 14px;
    padding: 20px;
  }
}
</style>
