<template>
  <el-dialog :title="$t('recipeRecord.recipeDetail')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="610px"
             append-to-body
             @close="isVisible = false">
    <div v-loading="isLoading">
      <iframe ref="Iframe"
              :src="url"
              frameborder="0"
              style="height: 660px;width: 100%;" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isVisible: false,
      isLoading: false
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showDetail) this.$parent.showDetail = false
        if (this.$parent.$parent.showDetail) { this.$parent.$parent.showDetail = false }
      }
    }
  },
  mounted () { },
  methods: {}
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 0;
}
</style>
