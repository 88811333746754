<template>
  <el-dialog :title="$t('common.detail')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="8vh"
             width="1200px"
             append-to-body
             @close="isVisible = false">
    <avue-crud ref="crud"
               :page.sync="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="detailTableOption"
               @on-load="fetchData"
               @search-change="handleSubmit">
      <template slot-scope="{size}"
                slot="searchMenu">
        <el-button :size="size"
                   :loading="isExport"
                   type="primary"
                   @click="exportExcel">{{ $t('common.export') }}</el-button>
      </template>
    </avue-crud>
  </el-dialog>
</template>

<script>
import { detailTableOption } from '@/const/crud/dutyStatistic'
import { dutyStatisticApi } from '@/api/functionApi'
import { dateFormat, formatTime } from '@/util/date'

export default {
  name: 'Detail',
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {},
      isVisible: false,
      detailTableOption: detailTableOption,
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      tableLoading: false,
      tableData: [],
      isExport: false,
      paramsSearch: {}
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showDetail) this.$parent.showDetail = false
        if (this.$parent.$parent.showDetail) { this.$parent.$parent.showDetail = false }
      } else {
        this.paramsSearch = {
          startTime: this.params.startTime,
          endTime: this.params.endTime
        }
        this.detailTableOption.column[
          this.detailTableOption.column.findIndex(
            (item) => item.prop === 'date'
          )
        ].searchValue = [this.params.startTime, this.params.endTime]
      }
    }
  },
  methods: {
    async fetchData () {
      try {
        this.tableLoading = true
        const res = await dutyStatisticApi.fetchDetail({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          ...this.paramsSearch
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (err) {
        console.error(err)
        this.tableLoading = false
      }
    },
    async exportExcel () {
      try {
        this.isExport = true
        let params = {
          getAll: 1
        }
        const defaultTime = {
          startTime: dateFormat(
            +new Date() - 30 * 24 * 60 * 60 * 1000,
            'YYYY-MM-DD'
          ),
          endTime: dateFormat(+new Date(), 'YYYY-MM-DD')
        }
        if (this.paramsSearch.startTime && this.paramsSearch.endTime) {
          params = { ...params, ...this.paramsSearch }
        } else {
          params = { ...params, ...defaultTime }
        }
        const res = await dutyStatisticApi.fetchDetail(params)
        this.$Export.excel({
          title: this.$t('dutyStatistic.dutyStatistic'),
          columns: detailTableOption.column.filter((item) => !item.hide),
          data: res.data.data.map((item) => {
            return {
              date: item.date,
              receptDate: item.startTime + ' ~ ' + item.endTime,
              totalTime: formatTime(item.totalTime),
              withoutRecipeNum: item.withoutRecipeNum
                ? item.withoutRecipeNum
                : 0,
              queueNum: item.queueNum ? item.queueNum : 0
            }
          })
        })
        this.isExport = false
      } catch (err) {
        console.error(err)
        this.isExport = false
      }
    },
    handleSubmit (params, done) {
      [params.startTime, params.endTime] = params.date ? params.date : []
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
// /deep/ .avue-crud__menu {
//   display: none;
// }
/deep/ .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 0;
}
</style>
