var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-chat" },
    [
      _c("div", { staticClass: "chat-content" }, [
        _c("div", { staticClass: "top-time" }, [_vm._v(_vm._s(_vm.startTime))]),
        _c("div", { staticClass: "top-content" }, [
          _c("span", { staticClass: "red-notice" }, [_vm._v("【系统消息】")]),
          _vm._v(
            "欢迎" + _vm._s(_vm.doctorInfo.name) + "医生进入网络接诊\n    "
          ),
        ]),
        _c(
          "div",
          { staticClass: "info-list" },
          [
            _vm._l(_vm.chatList, function (item, index) {
              return [
                item.clientType != 3 && item.msgType == 2
                  ? _c(
                      "div",
                      {
                        key: index,
                        staticClass: "list-item",
                        class: item.clientType === 1 ? "" : "doctor",
                        on: {
                          contextmenu: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.handleMenu($event, item, index)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "info-content" }, [
                          _c("div", { staticClass: "time-line" }, [
                            _vm._v(
                              "\n              " + _vm._s(item.clientName)
                            ),
                            _c("span", { staticClass: "chat-time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.dateFormat(
                                    +new Date(item.msgTime),
                                    "HH:mm:ss"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          item.medieType == 1
                            ? _c("div", { staticClass: "msg" }, [
                                _vm._v(_vm._s(item.msg)),
                              ])
                            : _vm._e(),
                          item.medieType == 2
                            ? _c(
                                "div",
                                { staticClass: "msg" },
                                [
                                  _c("el-image", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      src: item.msg,
                                      "preview-src-list": [item.msg],
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  : _vm._e(),
                item.clientType != 3 && item.msgType == 1
                  ? _c("div", { key: index, staticClass: "stystem-tip" }, [
                      _c("div", { staticClass: "top-time" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.dateFormat(
                                +new Date(item.msgTime),
                                "HH:mm:ss"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "top-content" }, [
                        _c("span", { staticClass: "red-notice" }, [
                          _vm._v("【系统消息】"),
                        ]),
                        _vm._v(_vm._s(item.msg) + "\n          "),
                      ]),
                    ])
                  : _vm._e(),
                item.clientType == 3
                  ? _c("div", { key: index, staticClass: "withdraw-tip" }, [
                      _c("div", { staticClass: "top-time" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.dateFormat(
                                +new Date(item.msgTime),
                                "HH:mm:ss"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("div", { staticClass: "top-content" }, [
                        _c("span", { staticClass: "withdraw-notice" }, [
                          _vm._v(_vm._s(_vm.$t("consultRoom.withdrawMsgTip"))),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm.showMenu
          ? _c(
              "div",
              {
                staticClass: "right-menu",
                style: { left: _vm.menuLeft + "px", top: _vm.menuTop + "px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "menu-item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.withdrawMsg($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("consultRoom.withdrawMsg")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "text-submit" }, [
        _c(
          "div",
          { staticClass: "content-zoom" },
          [
            _c("el-input", {
              ref: "elInput",
              attrs: {
                id: "elInput",
                type: "textarea",
                resize: "none",
                placeholder: "请输入消息",
              },
              nativeOn: {
                paste: function ($event) {
                  return _vm.handlePaste($event)
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.sendMsg($event)
                },
              },
              model: {
                value: _vm.sendData.content,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.sendData,
                    "content",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "sendData.content",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "btn-zoom" },
          [
            _vm.currentUserId && _vm.currentMode == 2
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { plain: "", round: "", size: "small" },
                    on: { click: _vm.changeSessionEnd },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("consultRoom.sessionBase")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.currentUserId
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { plain: "", round: "", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.changeDoctor(true)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("consultRoom.arrangeRefer")) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.currentUserId
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { plain: "", round: "", size: "small" },
                    on: { click: _vm.sendMsg },
                  },
                  [_vm._v(_vm._s(_vm.$t("consultRoom.send")))]
                )
              : _vm._e(),
            _c(
              "el-popover",
              {
                attrs: {
                  "popper-options": {
                    boundariesElement: "viewport",
                    removeOnDestroy: true,
                  },
                  placement: "top-end",
                  width: "590",
                  "popper-class": "commonWordPopper",
                  trigger: "click",
                },
                model: {
                  value: _vm.showCommonWord,
                  callback: function ($$v) {
                    _vm.showCommonWord = $$v
                  },
                  expression: "showCommonWord",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      slot: "reference",
                      plain: "",
                      round: "",
                      size: "small",
                    },
                    slot: "reference",
                  },
                  [_vm._v(_vm._s(_vm.$t("consultRoom.commonWords")))]
                ),
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        staticClass: "pane-visit",
                        attrs: {
                          name: _vm.$t("consultRoom.inquirySpec"),
                          label: _vm.$t("consultRoom.inquirySpec"),
                        },
                      },
                      [_c("inquirySpec", { ref: "inquirySpec" })],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          name: _vm.$t("consultRoom.commonWords"),
                          label: _vm.$t("consultRoom.commonWords"),
                        },
                      },
                      [
                        _c("commonWord", {
                          ref: "commonWord",
                          on: {
                            selectWord: _vm.selectWord,
                            closeCommonWord: _vm.closeCommonWord,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      _vm.showCommonWord = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.showChangeDoctor
        ? _c("changeDoctor", { ref: "changeDoctor" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }