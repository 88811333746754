<template>
  <el-dialog :title="$t('consultRoom.caseAnalysis')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="800px"
             append-to-body
             @close="isVisible = false">
    <div class="case-analysis">
      <el-image :src="demoImg"
                :preview-src-list="[demoImg]"
                style="width: 100%" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CaseAnalysis',
  props: {
    demoImg: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showCaseAnalysis) this.$parent.showCaseAnalysis = false
        if (this.$parent.$parent.showCaseAnalysis) { this.$parent.$parent.showCaseAnalysis = false }
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
}
</style>
