import { translate as $t } from '@/lang'
import { dateFormat, formatTime } from '@/util/date'

export const tableOption = {
  dialogDrag: true,
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  // stripe: true,
  align: 'center',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  columnBtn: false,
  // menu: false,
  menuWidth: 80,
  searchMenuSpan: 4,
  highlightCurrentRow: true,
  // viewBtn: false,
  // filterBtn: false,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  column: [
    {
      label: $t('common.date'),
      prop: 'date',
      type: 'daterange',
      searchSpan: 6,
      searchLabelWidth: 46,
      hide: true,
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              // time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选当前的前一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      },
      searchValue: [
        dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
        dateFormat(+new Date(), 'YYYY-MM-DD')
      ]
    },
    {
      minWidth: 100,
      label: $t('dutyStatistic.doctorName'),
      prop: 'doctorName'
    },
    {
      minWidth: 100,
      label: $t('dutyStatistic.receptTotalTime'),
      prop: 'totalTime',
      formatter: (val) => {
        return formatTime(val.totalTime)
      }
    },
    {
      minWidth: 100,
      label: $t('dutyStatistic.imgTextTime'),
      prop: 'textTotalTime',
      formatter: (val) => {
        return formatTime(val.textTotalTime)
      }
    },
    {
      minWidth: 100,
      label: $t('dutyStatistic.videoTime'),
      prop: 'videoTotalTime',
      formatter: (val) => {
        return formatTime(val.videoTotalTime)
      }
    },
    {
      minWidth: 150,
      label: $t('dutyStatistic.maxTime'),
      prop: 'recipeTimeMax',
      formatter: (val) => {
        return val.recipeTimeMax ? val.recipeTimeMax : 0 + 's'
      }
    },
    {
      minWidth: 150,
      label: $t('dutyStatistic.mixTime'),
      prop: 'recipeTimeMin',
      formatter: (val) => {
        return val.recipeTimeMin ? val.recipeTimeMin : 0 + 's'
      }
    },
    {
      minWidth: 120,
      label: $t('dutyStatistic.notPrescrib'),
      prop: 'withoutRecipeNum',
      formatter: (val) => {
        return val.withoutRecipeNum ? val.withoutRecipeNum : 0
      }
    },
    {
      minWidth: 100,
      label: $t('dutyStatistic.referralTimes'),
      prop: 'referralTimes',
      formatter: (val) => {
        return val.referralTimes ? val.referralTimes : 0
      }
    },
    {
      minWidth: 120,
      label: $t('dutyStatistic.notRecept'),
      prop: 'queueNum',
      formatter: (val) => {
        return val.queueNum ? val.queueNum : 0
      }
    }
  ]
}

export const detailTableOption = {
  dialogDrag: true,
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  // stripe: true,
  align: 'center',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  columnBtn: false,
  menu: false,
  searchMenuSpan: 4,
  highlightCurrentRow: true,
  viewBtn: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  column: [
    {
      label: $t('common.date'),
      prop: 'date',
      type: 'daterange',
      searchSpan: 6,
      searchLabelWidth: 46,
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      formatter: (val) => {
        return val.date
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      }
    },
    {
      width: 300,
      label: $t('dutyStatistic.receptDate'),
      prop: 'receptDate',
      formatter: (val) => {
        return val.startTime.split(' ')[1] + ' ~ ' + val.endTime.split(' ')[1]
      }
    },
    {
      label: $t('dutyStatistic.receptTime'),
      prop: 'totalTime',
      formatter: (val) => {
        return formatTime(val.totalTime)
      }
    },
    {
      label: $t('dutyStatistic.notPrescrib'),
      prop: 'withoutRecipeNum',
      formatter: (val) => {
        return val.withoutRecipeNum ? val.withoutRecipeNum : 0
      }
    },
    {
      label: $t('dutyStatistic.notRecept'),
      prop: 'queueNum',
      formatter: (val) => {
        return val.queueNum ? val.queueNum : 0
      }
    }
  ]
}
