var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drug-table" },
    [
      _vm.activeName === _vm.$t("consultRoom.visitPrescrib")
        ? _c("div", { staticClass: "right-title", attrs: { id: "helpBlur" } }, [
            _vm._v("药品信息"),
            _c("span", { staticStyle: { color: "#f00" } }, [
              _vm._v(
                "（" + _vm._s(_vm.drugList ? _vm.drugList.length : 0) + "）"
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-row",
        { staticClass: "table-header" },
        [
          _vm.recipeType == 2
            ? _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(_vm._s(_vm.$t("consultRoom.drugName"))),
              ])
            : _vm._e(),
          _c("el-col", { attrs: { span: 6 } }, [
            _vm._v(_vm._s(_vm.$t("consultRoom.usage"))),
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(_vm._s(_vm.$t("consultRoom.singleDose"))),
          ]),
          _vm.recipeType == 1
            ? _c("el-col", { attrs: { span: 6 } }, [
                _vm._v(_vm._s(_vm.$t("consultRoom.frequency"))),
              ])
            : _vm._e(),
          _vm.recipeType == 1
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.$t("consultRoom.num"))),
              ])
            : _vm._e(),
          _vm.recipeType == 2
            ? _c("el-col", { attrs: { span: 2 } }, [
                _vm._v(_vm._s(_vm.$t("consultRoom.operation"))),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-box" },
        _vm._l(_vm.drugList, function (item, index) {
          return _c(
            "div",
            { key: item.drugId || index, staticClass: "table-contain" },
            [
              _vm.recipeType == 1
                ? _c(
                    "div",
                    {
                      staticClass: "name-west",
                      class:
                        _vm.activeName === _vm.$t("consultRoom.visitPrescrib")
                          ? ""
                          : "name-Record",
                    },
                    [
                      item.drugName &&
                      _vm.activeName === _vm.$t("consultRoom.visitPrescrib")
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: "复制药品名称",
                                effect: "dark",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("el-image", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { src: "/img/copy.png", fit: "cover" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyName(
                                      item.drugName + " " + item.specification
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("span", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v("(" + _vm._s(index + 1) + ")"),
                      ]),
                      item.drugName &&
                      _vm.activeName === _vm.$t("consultRoom.visitPrescrib")
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: item.drugName,
                                effect: "dark",
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "drug-name overflow-1",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyName(
                                        item.drugName + " " + item.specification
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.drugName))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.activeName === _vm.$t("consultRoom.medicalRecord")
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                title: _vm.$t("recipeTemplate.selectDrugs"),
                                placement: "left",
                                width: "600",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "search-drug",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder:
                                        _vm.recipeType == 1
                                          ? _vm.$t("consultRoom.drugSearchTip1")
                                          : _vm.$t(
                                              "consultRoom.drugSearchTip2"
                                            ),
                                      size: "mini",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.fetchDrugsData(item)
                                      },
                                      input: function ($event) {
                                        _vm.debounce(
                                          function () {
                                            _vm.fetchDrugsData(item)
                                          },
                                          1000,
                                          false
                                        )()
                                      },
                                    },
                                    model: {
                                      value: item.drugName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "drugName", $$v)
                                      },
                                      expression: "item.drugName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("avue-crud", {
                                ref: "crud",
                                refInFor: true,
                                attrs: {
                                  page: _vm.page,
                                  data: _vm.drugDataList,
                                  "table-loading": _vm.tableLoading,
                                  option: _vm.drugTableOption,
                                  "row-class-name": function () {
                                    return "pointer"
                                  },
                                },
                                on: {
                                  "update:page": function ($event) {
                                    _vm.page = $event
                                  },
                                  "row-click": function ($event) {
                                    return _vm.selectDrug(index, $event)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.specification &&
                      _vm.activeName === _vm.$t("consultRoom.visitPrescrib")
                        ? _c(
                            "span",
                            {
                              staticClass: "drug-spec",
                              on: {
                                click: function ($event) {
                                  return _vm.copyName(
                                    item.drugName + " " + item.specification
                                  )
                                },
                              },
                            },
                            [_vm._v("【" + _vm._s(item.specification) + "】")]
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                content: _vm.$t("common.delete"),
                                effect: "dark",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(index)
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "table-content", attrs: { gutter: 2 } },
                [
                  _vm.recipeType == 2
                    ? [
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.activeName ===
                                  _vm.$t("consultRoom.visitPrescrib"),
                                expression:
                                  "activeName === $t('consultRoom.visitPrescrib')",
                              },
                            ],
                            staticStyle: { "text-align": "left" },
                            attrs: { span: 8 },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                height: "18px",
                                width: "18px",
                                "vertical-align": "middle",
                                cursor: "pointer",
                                margin: "0 5px",
                              },
                              attrs: {
                                src: "/img/copy.png",
                                title: "复制",
                                fit: "cover",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyName(
                                    item.drugName + " " + item.specification
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v("(" + _vm._s(index + 1) + ")")]
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: item.drugName,
                                  effect: "dark",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyName(
                                          item.drugName +
                                            " " +
                                            item.specification
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.drugName))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.activeName ===
                                  _vm.$t("consultRoom.medicalRecord"),
                                expression:
                                  "activeName === $t('consultRoom.medicalRecord')",
                              },
                            ],
                            attrs: { span: 8 },
                          },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  title: _vm.$t("recipeTemplate.selectDrugs"),
                                  "popper-options": {
                                    boundariesElement: "viewport",
                                    removeOnDestroy: true,
                                  },
                                  placement: "left",
                                  width: "600",
                                  trigger: "click",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "search-drug",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder:
                                          _vm.recipeType == 1
                                            ? _vm.$t(
                                                "consultRoom.drugSearchTip1"
                                              )
                                            : _vm.$t(
                                                "consultRoom.drugSearchTip2"
                                              ),
                                        size: "mini",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.fetchDrugsData(item)
                                        },
                                        input: function ($event) {
                                          _vm.debounce(
                                            function () {
                                              _vm.fetchDrugsData(item)
                                            },
                                            1000,
                                            false
                                          )()
                                        },
                                      },
                                      model: {
                                        value: item.drugName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "drugName", $$v)
                                        },
                                        expression: "item.drugName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("avue-crud", {
                                  ref: "crud",
                                  refInFor: true,
                                  attrs: {
                                    page: _vm.page,
                                    data: _vm.drugDataList,
                                    "table-loading": _vm.tableLoading,
                                    option: _vm.drugTableOption,
                                    "row-class-name": function () {
                                      return "pointer"
                                    },
                                  },
                                  on: {
                                    "update:page": function ($event) {
                                      _vm.page = $event
                                    },
                                    "row-click": function ($event) {
                                      return _vm.selectDrug(index, $event)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: item.usage,
                            effect: "dark",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "usage-select",
                              staticStyle: { width: "80%" },
                              attrs: { filterable: "", size: "mini" },
                              on: {
                                change: _vm.saveStorage,
                                "visible-change": _vm.fetchUsage,
                              },
                              model: {
                                value: item.usage,
                                callback: function ($$v) {
                                  _vm.$set(item, "usage", $$v)
                                },
                                expression: "item.usage",
                              },
                            },
                            _vm._l(_vm.usageList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.name },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-input", {
                        ref: "dosageEl" + index,
                        refInFor: true,
                        staticClass: "dosage-input",
                        staticStyle: { width: "45%" },
                        attrs: { title: item.dosage, size: "mini" },
                        on: {
                          input: function ($event) {
                            return _vm.formatNum(
                              item.dosage,
                              index,
                              _vm.recipeType
                            )
                          },
                          blur: function ($event) {
                            return _vm.formatNumBlur(index)
                          },
                        },
                        model: {
                          value: item.dosage,
                          callback: function ($$v) {
                            _vm.$set(item, "dosage", $$v)
                          },
                          expression: "item.dosage",
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: item.dosageUnit,
                            effect: "dark",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "45%" },
                              attrs: {
                                title: item.dosageUnit,
                                filterable: "",
                                size: "mini",
                              },
                              on: { change: _vm.saveStorage },
                              model: {
                                value: item.dosageUnit,
                                callback: function ($$v) {
                                  _vm.$set(item, "dosageUnit", $$v)
                                },
                                expression: "item.dosageUnit",
                              },
                            },
                            _vm._l(_vm.singleList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.recipeType == 1
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: item.frequencyName,
                                  effect: "dark",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "frequencyEl" + index,
                                    refInFor: true,
                                    staticStyle: { width: "90%" },
                                    attrs: { size: "mini", filterable: "" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.saveStorage(
                                          "frequency",
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.frequencyName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "frequencyName", $$v)
                                      },
                                      expression: "item.frequencyName",
                                    },
                                  },
                                  _vm._l(
                                    _vm.frequencyList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.name,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "70%" },
                              attrs: {
                                title: item.buyCount,
                                controls: false,
                                min: 1,
                                precision: 0,
                                size: "mini",
                              },
                              on: { blur: _vm.saveStorage },
                              model: {
                                value: item.buyCount,
                                callback: function ($$v) {
                                  _vm.$set(item, "buyCount", $$v)
                                },
                                expression: "item.buyCount",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-col",
                          { attrs: { span: 2 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.$t("common.delete"),
                                  effect: "dark",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(index)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.activeName === _vm.$t("consultRoom.medicalRecord")
        ? _c(
            "div",
            { staticClass: "table-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    type: "primary",
                    icon: "el-icon-circle-plus-outline",
                  },
                  on: { click: _vm.addDrug },
                },
                [_vm._v(_vm._s(_vm.$t("consultRoom.addDrugs")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentUserId
        ? _c(
            "div",
            { staticClass: "supple-instruct" },
            [
              _c("div", { staticClass: "right-title" }, [
                _vm._v("处方补充说明"),
              ]),
              _c("el-input", {
                attrs: {
                  rows: 2,
                  type: "textarea",
                  resize: "none",
                  maxlength: "50",
                  "show-word-limit": "",
                  placeholder: "请输入处方补充说明",
                },
                on: { blur: _vm.changeData },
                model: {
                  value: _vm.currentUserData.suppleInstruct,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentUserData, "suppleInstruct", $$v)
                  },
                  expression: "currentUserData.suppleInstruct",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }