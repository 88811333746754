var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "aide-set" }, [
      _c("div", { staticClass: "short-key" }, [
        _c("span", { staticClass: "key-title" }, [_vm._v("快捷键提示：")]),
        _c("div", [_vm._v("收起用药助手：Esc")]),
        _c("div", [_vm._v("展开用药助手：F4")]),
        _c("div", [_vm._v("发送：Enter")]),
        _c("div", [_vm._v("确认开方：F1")]),
        _c("div", [_vm._v("常用话术：F6")]),
        _c("div", [_vm._v("图文自动接诊：F9")]),
        _c("div", [_vm._v("结束会话：F10")]),
        _c("div", [_vm._v("删除处方：Delete")]),
        _c("div", [_vm._v("安排转诊：~")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }