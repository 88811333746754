var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("common.detail"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "8vh",
        width: "1200px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          page: _vm.page,
          data: _vm.tableData,
          "table-loading": _vm.tableLoading,
          option: _vm.detailTableOption,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.fetchData,
          "search-change": _vm.handleSubmit,
        },
        scopedSlots: _vm._u([
          {
            key: "searchMenu",
            fn: function (ref) {
              var size = ref.size
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: size,
                      loading: _vm.isExport,
                      type: "primary",
                    },
                    on: { click: _vm.exportExcel },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.export")))]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }