import { render, staticRenderFns } from "./visitPrescrib.vue?vue&type=template&id=58dbbf11&scoped=true&"
import script from "./visitPrescrib.vue?vue&type=script&lang=js&"
export * from "./visitPrescrib.vue?vue&type=script&lang=js&"
import style0 from "./visitPrescrib.vue?vue&type=style&index=0&id=58dbbf11&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58dbbf11",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/data/.jenkins/workspace/yyDoctorOld/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58dbbf11')) {
      api.createRecord('58dbbf11', component.options)
    } else {
      api.reload('58dbbf11', component.options)
    }
    module.hot.accept("./visitPrescrib.vue?vue&type=template&id=58dbbf11&scoped=true&", function () {
      api.rerender('58dbbf11', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/consultRoom/visitPrescrib.vue"
export default component.exports