<template>
  <el-dialog :title="$t('consultRoom.injectReason')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="600px"
             append-to-body
             @close="isVisible = false">
    <div style="color: #f00;margin-bottom: 10px;">*选择的拒方原因仅用于内部记录，不会发送给用户</div>
    <el-radio-group v-model="injectReason">
      <div v-for="(item,index) in reasonList"
           :key="index"
           class="radio-item"><el-radio :label="item.label">{{ item.label }}</el-radio></div>
      <div class="radio-item"><el-radio label="其他">其他</el-radio></div>
    </el-radio-group>
    <el-input v-if="injectReason=='其他'"
              :rows="2"
              v-model="injectReason2"
              type="textarea"
              maxlength="50"
              show-limit
              placeholder="请输入拒方原因（限50字以内）" />
    <div slot="footer"
         style="text-align: center">
      <el-button type="primary"
                 size="small"
                 @click="isVisible = false">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary"
                 size="small"
                 @click="handleSubmit">{{ $t('common.affirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { consultRoomApi } from '@/api/consultRoomApi'
import inquiry from '@/socketMsg/inquiry.js'
import socketPublic from '@/socketMsg/socketPublic.js'

export default {
  name: 'InjectRecipe',
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    recipeType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reasonList: [],
      isVisible: false,
      injectReason: '',
      injectReason2: ''
    }
  },
  computed: {
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    currentUserData () {
      return socketPublic.state.currentUserData
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showInjectRecipe) this.$parent.showInjectRecipe = false
        if (this.$parent.$parent.showInjectRecipe) this.$parent.$parent.showInjectRecipe = false
      } else {
        this.fetchReasonList()
      }
    }
  },
  methods: {
    async fetchReasonList () {
      try {
        const res = await consultRoomApi.fetchReasonList()
        this.reasonList = res.data.data.list || []
      } catch (err) {
        console.error(err)
      }
    },
    async handleSubmit () {
      try {
        if ((this.injectReason == '其他' && !this.injectReason2) || (this.injectReason != '其他' && !this.injectReason)) {
          this.$message.warning(this.$t('consultRoom.injectReason'))
          return
        }
        const msg_data = {
          bean: { medieType: 4, msg: this.injectReason == '其他' ? this.injectReason2 : this.injectReason, targetUserId: this.currentUserId }
        }
        socketPublic.commit('senddata', {
          cmd: '0',
          data: msg_data
        })
        inquiry.stopVisit(this.currentUserId)
        this.isVisible = false
      } catch (err) {
        this.isCreatRecipe = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 10px 20px;
}
.radio-item {
  margin-bottom: 8px;
}
</style>
