var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "visit-prescrib" },
    [
      _c(
        "div",
        { staticClass: "patient-info weight-bold" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                content: _vm.patientInfo.user || "",
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "overflow-1",
                  style: { color: _vm.nameColor, flex: 1 },
                },
                [_vm._v(_vm._s(_vm.patientInfo.user || ""))]
              ),
            ]
          ),
          _c("span", [_vm._v(_vm._s(_vm.patientInfo.gender || ""))]),
          _c(
            "span",
            {
              style: { color: _vm.age < 18 || _vm.age > 60 ? "#f00" : "#333" },
            },
            [_vm._v(_vm._s(_vm.patientInfo.age || ""))]
          ),
          _c("span", { staticStyle: { "margin-top": "4px" } }, [
            _vm._v(_vm._s(_vm.patientInfo.weight || "")),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticClass: "info-item",
              staticStyle: { "justify-content": "flex-start" },
              attrs: { span: 7 },
            },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("consultRoom.liverFunction")) + "："),
              ]),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  style: { color: _vm.patientInfo.gang == 1 ? "#333" : "#f00" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog("gangSelect")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.patientInfo.gang == 1
                        ? "正常"
                        : _vm.patientInfo.gang == 0
                        ? "异常"
                        : ""
                    )
                  ),
                ]
              ),
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "info-item",
              staticStyle: { "justify-content": "flex-start" },
              attrs: { span: 7 },
            },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("consultRoom.renalFunction")) + "："),
              ]),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  style: { color: _vm.patientInfo.shen == 1 ? "#333" : "#f00" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog("shenSelect")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.patientInfo.shen == 1
                        ? "正常"
                        : _vm.patientInfo.shen == 0
                        ? "异常"
                        : ""
                    )
                  ),
                ]
              ),
            ]
          ),
          _c(
            "el-col",
            {
              staticClass: "info-item",
              staticStyle: { "justify-content": "flex-start" },
              style: _vm.showRed ? "color:red" : "",
              attrs: { span: 10 },
            },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("consultRoom.pregnancy")) + "："),
              ]),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  style: {
                    color:
                      _vm.patientInfo.pregnancy == 0 && !_vm.showRed
                        ? "#333"
                        : "#f00",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDialog("pregnancySelect")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.patientInfo.pregnancy == 1
                        ? "是"
                        : _vm.patientInfo.pregnancy == 0
                        ? "否认"
                        : ""
                    )
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "diagnose-tags" }, [
        _c("div", { staticClass: "diagnose-title weight-bold" }, [
          _vm._v("诊断信息"),
        ]),
        _c("div", { staticClass: "diagnose" }, [
          _c(
            "div",
            { staticClass: "diagnose-content" },
            _vm._l(_vm.selectdiagList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "tag select-tag" },
                [
                  !item.show
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: item.value,
                            effect: "dark",
                            placement: "bottom",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openEdit(item, index)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(item.value))])]
                      )
                    : _c("el-input", {
                        ref: "input" + index,
                        refInFor: true,
                        attrs: {
                          placeholder:
                            _vm.$t("common.pleaseInput") +
                            _vm.$t("consultRoom.diagnose"),
                          size: "mini",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.inputBlur(item)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                  _vm.selectdiagList.length > 1
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            disabled: !item.value,
                            content: _vm.$t("common.delete"),
                            effect: "dark",
                            placement: "bottom",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.deleteTag(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "el-icon-error close" })]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c(
          "div",
          { staticClass: "add-diagnose" },
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100px", "margin-right": "10px" },
                attrs: { size: "mini" },
                on: { change: _vm.changeType },
                model: {
                  value: _vm.diagnoseType,
                  callback: function ($$v) {
                    _vm.diagnoseType = $$v
                  },
                  expression: "diagnoseType",
                },
              },
              _vm._l(
                [
                  { type: 1, name: _vm.$t("consultRoom.westDiagnose") },
                  { type: 2, name: _vm.$t("consultRoom.chineseDiagnose") },
                ],
                function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.type },
                  })
                }
              ),
              1
            ),
            _c(
              "el-select",
              {
                directives: [
                  {
                    name: "loadmore",
                    rawName: "v-loadmore",
                    value: _vm.loadMore,
                    expression: "loadMore",
                  },
                ],
                attrs: {
                  loading: _vm.isLoading,
                  disabled: !_vm.currentUserId,
                  placeholder: _vm.$t("consultRoom.addDiagnose"),
                  "remote-method": _vm.fetchDiagnose,
                  filterable: "",
                  "allow-create": "",
                  size: "mini",
                  "reserve-keyword": "",
                  "default-first-option": "",
                  remote: "",
                },
                on: { change: _vm.selectDiagnose },
                model: {
                  value: _vm.diagnose,
                  callback: function ($$v) {
                    _vm.diagnose = $$v
                  },
                  expression: "diagnose",
                },
              },
              _vm._l(_vm.diagnoseList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.name },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "symptoms",
            style: {
              height: _vm.showMore ? "auto" : "36px",
              overflow: "hidden",
            },
          },
          [
            _c(
              "div",
              { ref: "diagContentRef", staticClass: "diagnose-content" },
              [
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("consultRoom.drugSymptoms")) + "："),
                ]),
                !_vm.symptomsList || !_vm.symptomsList.length
                  ? _c("div", { staticClass: "no" }, [
                      _vm._v(_vm._s(_vm.$t("common.no"))),
                    ])
                  : _vm._e(),
                _vm._l(_vm.symptomsList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "tag" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            disabled: !item.icd_name,
                            content: item.icd_name,
                            effect: "dark",
                            placement: "bottom",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.selectDiagnose(item.icd_name)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(item.icd_name))])]
                      ),
                      _c("el-tooltip", {
                        staticClass: "item",
                        attrs: {
                          disabled: !item.icd_name,
                          content: _vm.$t("common.delete"),
                          effect: "dark",
                          placement: "bottom",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.symDeleteTag(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "right-icon pointer",
                on: {
                  click: function () {
                    _vm.showMore = !_vm.showMore
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.showMore ? "收起" : "展开"))]),
                _c("i", {
                  class: _vm.showMore
                    ? "el-icon-arrow-up"
                    : "el-icon-arrow-down",
                }),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.gangSelect,
            "append-to-body": true,
            title: "修改肝功能",
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.gangSelect = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              on: {
                change: function ($event) {
                  return _vm.changeInfo($event, "gang")
                },
              },
              model: {
                value: _vm.patientInfo.gang,
                callback: function ($$v) {
                  _vm.$set(_vm.patientInfo, "gang", $$v)
                },
                expression: "patientInfo.gang",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("正常")]),
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("异常")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.shenSelect,
            "append-to-body": true,
            title: "修改肾功能",
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.shenSelect = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              on: {
                change: function ($event) {
                  return _vm.changeInfo($event, "shen")
                },
              },
              model: {
                value: _vm.patientInfo.shen,
                callback: function ($$v) {
                  _vm.$set(_vm.patientInfo, "shen", $$v)
                },
                expression: "patientInfo.shen",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("正常")]),
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("异常")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.pregnancySelect,
            "append-to-body": true,
            title: "修改备孕/怀孕/哺乳期",
            width: "25%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.pregnancySelect = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              on: {
                change: function ($event) {
                  return _vm.changeInfo($event, "pregnancy")
                },
              },
              model: {
                value: _vm.patientInfo.pregnancy,
                callback: function ($$v) {
                  _vm.$set(_vm.patientInfo, "pregnancy", $$v)
                },
                expression: "patientInfo.pregnancy",
              },
            },
            [
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("否认")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }