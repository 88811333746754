import { translate as $t } from '@/lang'
import { dateFormat } from '@/util/date'
import store from '@/store'

const token = store.getters.access_token

export const tableOption = {
  size: 'medium',
  dialogDrag: true,
  align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  selection: true,
  searchMenuSpan: 2,
  highlightCurrentRow: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  menuWidth: 80,
  column: [
    {
      label: $t('recipeRecord.username'),
      prop: 'patient_name'
    },
    {
      label: $t('common.sex'),
      prop: 'sex'
    },
    {
      label: $t('recipeRecord.linkPhone'),
      prop: 'patient_phone'
    },
    {
      label: $t('recipeRecord.diagnosis'),
      prop: 'diagnose_info'
    },
    {
      label: $t('recipeRecord.userOrigin'),
      prop: 'dispensary_name'
    },
    {
      width: 200,
      label: $t('recipeRecord.recipeDate'),
      prop: 'recipe_date',
      type: 'daterange',
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      searchSpan: 6,
      searchOrder: 2,
      formatter: (val) => {
        return val.recipe_date
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      },
      searchValue: [
        dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
        dateFormat(+new Date(), 'YYYY-MM-DD')
      ]
    },
    {
      label: $t('recipeRecord.recipeType'),
      prop: 'recipeType',
      search: true,
      searchSpan: 4,
      searchOrder: 3,
      placeholder: $t('common.pleaseSelect') + $t('recipeRecord.recipeType'),
      type: 'select',
      dicData: [
        {
          label: $t('recipeRecord.westMedicineRecipe'),
          value: 1
        },
        {
          label: $t('recipeRecord.chineseMedicineRecipe'),
          value: 2
        }
      ],
      formatter: (val) => {
        return val.recipe_type
      }
    },
    {
      label: $t('recipeRecord.auditStatus'),
      prop: 'e_state_txt'
    },
    {
      label: $t('recipeRecord.username'),
      prop: 'keyword',
      search: true,
      hide: true,
      placeholder: $t('common.pleaseInput') + $t('recipeRecord.username'),
      searchSpan: 4,
      searchOrder: 1
    }
  ]
}

export const formOption = {
  emptyBtn: false,
  submitText: $t('common.affirm'),
  submitIcon: ' ',
  column: [
    {
      label: $t('recipeTemplate.payNum'),
      prop: 'buy_count',
      type: 'number',
      min: 1,
      max: 30,
      precision: 0,
      placeholder: $t('common.pleaseInput') + $t('recipeTemplate.payNum'),
      span: 24,
      rules: [
        {
          required: true,
          message: $t('common.pleaseInput') + $t('recipeTemplate.payNum'),
          trigger: 'blur'
        }
      ]
    },
    {
      label: $t('recipeTemplate.useNum'),
      prop: 'frequency',
      type: 'select',
      span: 24,
      filterable: true,
      placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
      props: {
        label: 'name',
        value: 'name'
      },
      dicHeaders: {
        Authorization: 'Organ ' + token
      },
      dicUrl: '/doctor/drugs/frequency',
      rules: [
        {
          required: true,
          message: $t('common.pleaseSelect') + $t('recipeTemplate.useNum'),
          trigger: 'change'
        }
      ]
    },
    {
      label: $t('recipeTemplate.takeType'),
      prop: 'takeMethods',
      type: 'select',
      span: 24,
      filterable: true,
      placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.takeType'),
      props: {
        label: 'name',
        value: 'name'
      },
      dicHeaders: {
        Authorization: 'Organ ' + token
      },
      dicUrl: '/doctor/drugs/getClassifyDict',
      dicQuery: {
        classifyName: 'take_way'
      }
      // dicData: [
      //   {
      //     label: $t('recipeTemplate.inEat'),
      //     value: $t('recipeTemplate.inEat')
      //   },
      //   {
      //     label: $t('recipeTemplate.outEat'),
      //     value: $t('recipeTemplate.outEat')
      //   }
      // ]
    },
    {
      label: $t('recipeTemplate.takeRequire'),
      prop: 'takeRequire',
      type: 'select',
      span: 24,
      filterable: true,
      placeholder: $t('common.pleaseSelect') + $t('recipeTemplate.takeRequire'),
      props: {
        label: 'name',
        value: 'name'
      },
      dicHeaders: {
        Authorization: 'Organ ' + token
      },
      dicUrl: '/doctor/drugs/getClassifyDict',
      dicQuery: {
        classifyName: 'take_require'
      }
      // dicData: [
      //   {
      //     label: $t('recipeTemplate.beforeEat'),
      //     value: $t('recipeTemplate.beforeEat')
      //   },
      //   {
      //     label: $t('recipeTemplate.afterEat'),
      //     value: $t('recipeTemplate.afterEat')
      //   }
      // ]
    },
    {
      label: $t('recipeTemplate.takeDrugDays'),
      prop: 'usage_day',
      type: 'select',
      span: 24,
      filterable: true,
      placeholder:
        $t('common.pleaseSelect') + $t('recipeTemplate.takeDrugDays'),
      props: {
        label: 'name',
        value: 'name'
      },
      dicHeaders: {
        Authorization: 'Organ ' + token
      },
      dicUrl: '/doctor/drugs/getClassifyDict',
      dicQuery: {
        classifyName: 'use_day'
      },
      rules: [
        {
          required: true,
          message:
            $t('common.pleaseSelect') + $t('recipeTemplate.takeDrugDays'),
          trigger: 'change'
        }
      ]
      // dicData: [
      //   {
      //     label: $t('recipeTemplate.takeDrugDays1'),
      //     value: $t('recipeTemplate.takeDrugDays1')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays2'),
      //     value: $t('recipeTemplate.takeDrugDays2')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays3'),
      //     value: $t('recipeTemplate.takeDrugDays3')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays4'),
      //     value: $t('recipeTemplate.takeDrugDays4')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays5'),
      //     value: $t('recipeTemplate.takeDrugDays5')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays6'),
      //     value: $t('recipeTemplate.takeDrugDays6')
      //   },
      //   {
      //     label: $t('recipeTemplate.takeDrugDays7'),
      //     value: $t('recipeTemplate.takeDrugDays7')
      //   }
      // ]
    }
  ]
}

export const wordTableOption = {
  addBtn: false,
  searchShowBtn: false,
  index: true,
  indexLabel: '序号',
  // sortable: true, //开启拖拽
  // dragHandler: false, //开启列拖拽
  refreshBtn: false,
  highlightCurrentRow: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  menuWidth: 160,
  // showHeader: false,
  rowKey: 'id',
  submitText: $t('consultRoom.confirmPrescrib'),
  submitIcon: ' ',
  height: '560',
  column: [
    {
      label: $t('consultRoom.words'),
      prop: 'content',
      type: 'textarea',
      span: 24,
      overHidden: false,
      placeholder: $t('consultRoom.searchWordTip1'),
      rules: [
        {
          required: true,
          message: $t('consultRoom.searchWordTip1'),
          trigger: 'blur'
        }
      ]
    }
  ]
}

export const tempTableOption = {
  size: 'mini',
  // align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  highlightCurrentRow: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  menuWidth: 160,
  searchMenuSpan: 2,
  border: true,
  // height: '400',
  column: [
    {
      label: $t('consultRoom.tempName'),
      prop: 'template_name',
      overHidden: true
    },
    {
      label: $t('consultRoom.tempDiagnose'),
      prop: 'diagnose_info',
      overHidden: true,
      formatter: (obj) => {
        return obj.diagnose_info ? obj.diagnose_info : obj.custom_diagnose
      }
    },
    {
      prop: 'keyword',
      search: true,
      hide: true,
      placeholder: $t('common.pleaseInput'),
      searchSpan: 16,
      searchLabelWidth: 1
    }
  ]
}

// 历史病历
export const historyOption = {
  size: 'small',
  emptyBtn: false,
  submitText: $t('common.inquire'),
  menuSpan: 2,
  labelWidth: 0,
  column: [
    {
      prop: 'date',
      type: 'daterange',
      span: 12,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime')
    }
  ]
}

export const patientInfoList = [
  {
    label: $t('consultRoom.drugAllergy'),
    key: 'allergyContent',
    sendKey: 5
  },
  {
    label: $t('consultRoom.previousHistory'),
    key: 'pastContent',
    sendKey: 3
  },
  {
    label: $t('consultRoom.presentCaseHist'),
    key: 'illContent',
    sendKey: 2
  },
  {
    label: $t('consultRoom.mainSuit'),
    key: 'mainDignose',
    sendKey: 1
  }
]

export const drugInfoList = [
  {
    label: $t('consultRoom.presentCaseHist'),
    key: 'illContent'
  },
  {
    label: $t('consultRoom.mainSuit'),
    key: 'mainSuit'
  },
  {
    label: $t('consultRoom.drugAllergy'),
    key: 'allergyContent'
  },
  {
    label: $t('consultRoom.previousHistory'),
    key: 'pastContent'
  }
]

// 历史病历
export const caseHistoryList = [
  {
    label: $t('consultRoom.drugAllergy'),
    key: 'yp_allergic'
  },
  {
    label: $t('consultRoom.mainSuit'),
    key: 'main_diagnose'
  },
  {
    label: $t('consultRoom.previousHistory'),
    key: 'past_history'
  },
  {
    label: $t('consultRoom.presentCaseHist'),
    key: 'ill_history'
  },
  {
    label: $t('common.time'),
    key: 'inquiry_time'
  }
]
