<template>
  <el-dialog :title="$t('consultRoom.editDrugUse')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="500px"
             append-to-body
             @close="isVisible = false">
    <div class="edit-template">
      <avue-form ref="rightForm"
                 v-model="formData"
                 :option="formOption"
                 @submit="handleSubmit" />
    </div>
  </el-dialog>
</template>

<script>
import { formOption } from '@/const/crud/consultRoom'

export default {
  name: 'EditChineseDrug',
  props: {
    currentUserData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      formOption,
      isVisible: false,
      formData: {},
      tableData: [],
      paramsSearch: {},
      tableLoading: false,
      isLoading: false,
      showDrugList: false
    }
  },
  watch: {
    isVisible(val) {
      if (!val) {
        if (this.$parent.showEdit) this.$parent.showEdit = false
        if (this.$parent.$parent.showEdit) {
          this.$parent.$parent.showEdit = false
        }
      } else {
        this.formData = { ...this.currentUserData.drugUseData }
      }
    }
  },
  methods: {
    handleSubmit(form, done) {
      this.$emit('editChineseInfo', form)
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding: 20px 30px;
}
</style>
