<template>
  <div :style="{ height: screenHeight + 'px' }"
       class="aide-set">
    <div class="short-key">
      <span class="key-title">快捷键提示：</span>
      <div>Esc：打开/收起用药助手</div>
      <div>ALT：开方,确认层未出现时，打开确认层；确认层出现时，确认开方</div>
    </div>
    <div class="first-set">
      <div>自动接诊：</div>
      <div>
        <el-switch v-model="autoReceive"
                   active-value="1"
                   inactive-value="0"
                   @change="setAutoReceive" />
      </div>
    </div>
    <div class="first-set">
      <div>一键开方：</div>
      <div>
        <el-switch v-model="squareRoot"
                   active-value="1"
                   inactive-value="0"
                   @change="setSquareRoot" />
      </div>
    </div>
    <div class="setNotice">注意：请确保您有足够的熟练度后再开启一键开方</div>
    <!-- <div class="huashu-set">
      <div class="huashu-body">
        <div class="huashu-list">
          <div class="huashu-title">接诊话术</div>
          <div class="huashu-common">
            {{ aideSpeechs.common_reply_start }}
          </div>
          <div class="huashu-custom">
            <el-input
              type="textarea"
              :rows="2"
              v-model="doctorSetting.common_reply_start"
              placeholder="自定义接诊话术"
            ></el-input>
          </div>
        </div>
        <div class="huashu-list">
          <div class="huashu-title">拒方话术</div>
          <div class="huashu-common">
            {{ aideSpeechs.common_reply_refuse }}
          </div>
          <div class="huashu-custom">
            <el-input
              type="textarea"
              :rows="2"
              v-model="doctorSetting.common_reply_refuse"
              placeholder="自定义拒方话术"
            ></el-input>
          </div>
        </div>
        <div class="huashu-list">
          <div class="huashu-title">开方话术</div>
          <div class="huashu-common">{{ aideSpeechs.common_reply_recipe }}</div>
          <div class="huashu-custom">
            <el-input
              type="textarea"
              :rows="2"
              v-model="doctorSetting.common_reply_recipe"
              placeholder="自定义开方话术"
            ></el-input>
          </div>
        </div>
        <div class="set-btn">
          <el-button
            type="primary"
            :loading="btnloading"
            @click="setDoctorSpeech"
            >保存</el-button
          >
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { sendMessagetoBackground, autoInquiry } from './utils/index'
export default {
  components: {},
  data () {
    return {
      btnloading: false,
      autoReceive: '1',
      squareRoot: '0',
      doctorSetting: {
        common_reply_start: '',
        common_reply_refuse: '',
        common_reply_recipe: ''
      },
      aideSpeechs: {
        common_reply_start: '',
        common_reply_refuse: '',
        common_reply_recipe: ''
      },
      topHeight: 200, // 导航栏或者顶部的高度
      screenHeight: document.body.clientHeight - this.topHeight
    }
  },
  computed: {},
  watch: {
    screenHeight: {
      handler (val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenHeight = val
          this.timer = true
          const that = this
          setTimeout(function () {
            // 打印screenWidth变化的值
            console.log(that.screenHeight)
            that.timer = false
          }, 400)
        }
      },
      deep: true, // 深度监听
      immediate: true
    }
  },
  created () {
    this.screenHeight = document.body.clientHeight - this.topHeight
    this.$nextTick(() => {
      this.initSpeech()
    })
    // 接受后台消息
    window.chrome.runtime.onMessage.addListener((request) => {
      var res = JSON.parse(request)
      var cmd = res.cmd
      switch (cmd) {
        case 'setDoctorSpeech':
          console.log('收到后台过来的消息setDoctorSpeech：', request, this)
          this.setDoctorSpeechBack(request)
          break
      }
    })
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        // 可以限制最小高度
        // if (document.body.clientHeight - 240 < 250) {
        //   return
        // }
        window.screenHeight = document.body.clientHeight - this.topHeight
        that.screenHeight = window.screenHeight
      })()
    }
  },
  methods: {
    setSpeechs (aideSpeechList) {
      this.aideSpeechs = aideSpeechList
    },
    initSpeech () {
      const doctorinfo = JSON.parse(sessionStorage.getItem('aideDoctorinfo'))
      if (doctorinfo) {
        this.doctorSetting.common_reply_start = doctorinfo.common_reply_start
          ? doctorinfo.common_reply_start
          : ''
        this.doctorSetting.common_reply_refuse = doctorinfo.common_reply_refuse
          ? doctorinfo.common_reply_refuse
          : ''
        this.doctorSetting.common_reply_recipe = doctorinfo.common_reply_recipe
          ? doctorinfo.common_reply_recipe
          : ''
        this.autoReceive = sessionStorage.getItem('aideAutoReceive')
      }
    },
    setDoctorSpeech () {
      this.btnloading = true
      const doctorinfo = JSON.parse(sessionStorage.getItem('aideDoctorinfo'))
      const sendData = {
        data: {
          Authorization: doctorinfo.access_token,
          common_reply_start: this.doctorSetting.common_reply_start,
          common_reply_refuse: this.doctorSetting.common_reply_refuse,
          common_reply_recipe: this.doctorSetting.common_reply_recipe
        }
      }
      sendMessagetoBackground(sendData, 'setDoctorSpeech')
    },
    setDoctorSpeechBack (res) {
      res = JSON.parse(res)
      this.btnloading = false
      if (res.code == 0) {
        const doctorinfo = JSON.parse(sessionStorage.getItem('aideDoctorinfo'))
        doctorinfo.common_reply_start = this.doctorSetting.common_reply_start
        doctorinfo.common_reply_refuse = this.doctorSetting.common_reply_refuse
        doctorinfo.common_reply_recipe = this.doctorSetting.common_reply_recipe
        sessionStorage.setItem('aideDoctorinfo', JSON.stringify(doctorinfo))
        this.$message.success('保存成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    setAutoReceive (e) {
      sessionStorage.setItem('aideAutoReceive', e)
      if (e == 1) {
        autoInquiry()
      }
    },
    setSquareRoot (e) {
      sessionStorage.setItem('aideSquareRoot', e)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-set {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  overflow-y: scroll;
  .first-set {
    display: flex;
    margin-bottom: 20px;
  }
  .setNotice {
    display: flex;
    font-weight: 600;
  }
  .short-key {
    margin-bottom: 20px;
    text-align: left;

    color: #333;
    .key-title {
      font-weight: 600;
      color: #333333;
    }
  }
  .short-key div {
    margin-top: 10px;
  }
  .huashu-set {
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 8px;
    .huashu-body {
      padding: 16px 12px;
      .huashu-list {
        margin-bottom: 30px;
        .huashu-title {
          text-align: left;
          font-weight: 600;
        }
        .huashu-common {
          text-align: left;
          margin-left: 12px;
          margin-top: 8px;
          line-height: 20px;
        }
        .huashu-custom {
          width: 100%;
          margin-top: 10px;
        }
      }
      .set-btn {
        margin: 38px 0;
      }
    }
  }
}
</style>
