var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("inquiryRecord.graphicChatRecord"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "630px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "chat-history",
        },
        [
          _c("div", { staticClass: "chat-item" }, [
            _c("div", [
              _c("label", [
                _vm._v("[" + _vm._s(_vm.$t("inquiryRecord.patient")) + "]："),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.infoData.user +
                      "/" +
                      _vm.infoData.gende +
                      "/" +
                      _vm.infoData.age
                  )
                ),
              ]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v("[" + _vm._s(_vm.$t("inquiryRecord.illness")) + "]："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.icd))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v(
                  "[" + _vm._s(_vm.$t("inquiryRecord.liverFunction")) + "]："
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.gang))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v(
                  "[" + _vm._s(_vm.$t("inquiryRecord.renalFunction")) + "]："
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.shen))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v("[" + _vm._s(_vm.$t("inquiryRecord.pregnancy")) + "]："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.pregnancy))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v("[" + _vm._s(_vm.$t("inquiryRecord.allergy")) + "]："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.allergyContent))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v(
                  "[" + _vm._s(_vm.$t("inquiryRecord.presentCaseHist")) + "]："
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.illContent))]),
            ]),
            _c("div", [
              _c("label", [
                _vm._v(
                  "[" + _vm._s(_vm.$t("inquiryRecord.previousHistory")) + "]："
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.infoData.pastContent))]),
            ]),
          ]),
          _vm._l(_vm.historyList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "history-item",
                class:
                  item.clientType === 1
                    ? "patient"
                    : item.clientType === 2
                    ? "doctor"
                    : item.clientType === 3
                    ? "without"
                    : "",
              },
              [
                item.clientType == 3
                  ? _c("div", { staticClass: "top-content" }, [
                      _c("span", { staticClass: "withdraw-notice" }, [
                        _vm._v(_vm._s(_vm.$t("consultRoom.withdrawMsgTip1"))),
                      ]),
                    ])
                  : [
                      _c(
                        "div",
                        { staticClass: "img" },
                        [
                          _c("el-image", {
                            attrs: {
                              src:
                                item.clientType === 1
                                  ? "/img/doctor_default.png"
                                  : item.clientType === 2
                                  ? "/img/doctor_white.png"
                                  : "",
                              fit: "cover",
                            },
                          }),
                        ],
                        1
                      ),
                      item.medieType == 1
                        ? _c("div", { staticClass: "msg" }, [
                            _vm._v(_vm._s(item.msg)),
                          ])
                        : _vm._e(),
                      item.medieType == 2
                        ? _c(
                            "div",
                            { staticClass: "msg" },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  src: item.msg,
                                  "preview-src-list": [item.msg],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
              ],
              2
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }