<template>
  <div class="main-container">
    <avue-crud ref="crud"
               v-model="formData"
               :page.sync="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               @on-load="fetchData"
               @search-change="handleSubmit">
      <template slot-scope="{size}"
                slot="searchMenu">
        <el-button :size="size"
                   type="primary"
                   @click="handleEdit(null,$t('recipeRecord.westMedicineRecipe'))">{{ $t('recipeTemplate.addWestTem') }}</el-button>
        <el-button :size="size"
                   type="primary"
                   @click="handleEdit(null,$t('recipeRecord.chineseMedicineRecipe'))">{{ $t('recipeTemplate.addChineseTem') }}</el-button>
      </template>
      <template slot-scope="{size,row}"
                slot="menu">
        <el-button :size="size"
                   type="text"
                   @click="handleEdit(row)">{{ $t('common.edit') }}</el-button>
        <!-- <el-button type="text"
                   :size="size"
                   @click="deleteRow(row)">{{$t('common.delete')}}</el-button> -->
      </template>
      <template slot-scope="{row}"
                slot="status">
        <div :style="{color: row.status==='1'?'#3b94ff':'#ff0017'}"
             style="cursor: pointer;"
             @click="changeStatus(row)">{{ row.status_desc }}</div>
      </template>
    </avue-crud>
    <editTemplate v-if="showEditTemplate"
                  ref="editTemplate"
                  :recipe-type="recipeType"
                  :current-data="currentData"
                  @onRefresh="onRefresh" />
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/recipeTemplate'
import { recipeTemplateApi } from '@/api/functionApi'
import { dateFormat } from '@/util/date'
import editTemplate from './editTemplate'

export default {
  name: 'RecipeTemplate',
  components: {
    editTemplate
  },
  data() {
    return {
      formData: {},
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      paramsSearch: {
        startTime: dateFormat(
          +new Date() - 30 * 24 * 60 * 60 * 1000,
          'YYYY-MM-DD'
        ),
        endTime: dateFormat(+new Date(), 'YYYY-MM-DD')
      },
      tableLoading: false,
      tableOption,
      currentData: {},
      showEditTemplate: false,
      recipeType: ''
    }
  },
  methods: {
    async fetchData() {
      try {
        this.tableLoading = true
        const res = await recipeTemplateApi.fetchData({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          scope: 2,
          ...this.paramsSearch
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (e) {
        this.tableLoading = false
      }
    },

    // 改变套餐类型
    async changeStatus(row) {
      try {
        await this.$confirm(
          this.$t('recipeTemplate.changeTip1'),
          this.$t('common.warmPrompt')
        )
        await recipeTemplateApi.changeTemplateStatus({
          status: row.status === '1' ? '0' : '1',
          templateId: row.template_id
        })
        this.fetchData()
        this.$message.success(this.$t('recipeTemplate.changeTip2'))
      } catch (err) {
        console.error(err)
      }
    },

    // 删除模板
    async deleteRow(row) {
      try {
        await this.$confirm(
          this.$t('recipeTemplate.deleteTip'),
          this.$t('common.warmPrompt')
        )
        await recipeTemplateApi.deleteTemplate({
          templateId: row.template_id
        })
        this.fetchData()
        this.$message.success(this.$t('recipeTemplate.changeTip3'))
      } catch (err) {
        console.error(err)
      }
    },

    // 编辑模板
    handleEdit(row, type) {
      this.recipeType = row ? row.recipe_type : type
      this.currentData = row
      this.showEditTemplate = true
      this.$nextTick(() => {
        this.$refs.editTemplate.isVisible = true
      })
    },

    handleSubmit(params, done) {
      [params.startTime, params.endTime] = params.date ? params.date : []
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    },

    onRefresh() {
      this.page.currentPage = 1
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
</style>
