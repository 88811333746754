<template>
  <div :style="{width: showUseAssist?'32%':'42%'}"
       class="room-right">
    <el-tabs v-model="activeName">
      <el-tab-pane v-if="doctorInfo.work_power==1||doctorInfo.work_power==3"
                   :name="$t('consultRoom.visitPrescrib')"
                   :label="$t('consultRoom.visitPrescrib')"
                   :disabled="!!currentUserData.clinicType"
                   class="pane-visit">
        <visitPrescrib />
        <div v-if="currentUserData.recipeType==2"
             class="chinese-tip">
          <div>{{ $t('consultRoom.drugUse') }}：{{ formatter(currentUserData.drugUseData) }}</div>
          <el-button size="mini"
                     type="primary"
                     style="margin-left: 20px;"
                     @click="editUse">{{ $t('consultRoom.editDrugUse') }}</el-button>
        </div>
        <drugTable v-if="drugShow"
                   :showtip="showtip"
                   :table-list="currentUserData.drugList"
                   :recipe-type="currentUserData.recipeType || 1"
                   :active-name="activeName"
                   @saveStorage="saveStorage" />
        <div v-if="currentUserId"
             class="btn-end">
          <el-button v-if="currentUserData.deleteDrugList.length"
                     size="small"
                     type="success"
                     class="recover"
                     @click="recoverDrug">{{ $t('consultRoom.recoverDrug') }}</el-button>
          <el-button :disabled="isCreatRecipe || isDeleteRecipe"
                     size="small"
                     plain
                     type="danger"
                     @click="$emit('stopInquiry')">{{ $t('consultRoom.endSession') }}</el-button>
          <el-button v-if="currentUserData.isRecipe"
                     :loading="isDeleteRecipe"
                     size="small"
                     type="danger"
                     class="danger"
                     @click="$emit('deleteRecipe')">{{ $t('consultRoom.deleteRecipe') }}</el-button>
          <template v-else>
            <!-- <el-button size="small"
                         plain
                         type="danger"
                         @click="stopInquiry">{{$t('consultRoom.endSession')}}</el-button> -->
            <el-button :loading="isCreatRecipe"
                       :disabled="!!currentUserData.doctorAdvice&&!provisionalRelease"
                       :type="!!currentUserData.doctorAdvice&&!provisionalRelease?'info':'primary'"
                       size="small"
                       @click="$emit('openRecipe')">{{ $t('consultRoom.confirmPrescrib') }}</el-button>
          </template>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="doctorInfo.work_power==2||doctorInfo.work_power==3"
                   :name="$t('consultRoom.medicalRecord')"
                   :label="$t('consultRoom.medicalRecord')"
                   :disabled="!currentUserData.clinicType"
                   class="medical-record">
        <visitPrescrib :active-type="activeType" />
        <el-tabs v-model="activeType"
                 style="margin-top: 10px;">
          <el-tab-pane v-if="doctorInfo.recipe_power=='1'||doctorInfo.recipe_power=='0'"
                       :name="$t('consultRoom.westMedicineRecipe')"
                       :label="$t('consultRoom.westMedicineRecipe')" />
          <el-tab-pane v-if="!currentUserData.visitType&&(doctorInfo.recipe_power=='2'||doctorInfo.recipe_power=='0')"
                       :name="$t('consultRoom.chineseMedicineRecipe')"
                       :label="$t('consultRoom.chineseMedicineRecipe')" />
          <el-tab-pane v-if="!currentUserData.visitType&&(doctorInfo.recipe_power=='1'||doctorInfo.recipe_power=='0')"
                       :name="$t('consultRoom.westMedicineRecipeTemp')"
                       :label="$t('consultRoom.westMedicineRecipeTemp')"
                       class="temp-item" />
          <el-tab-pane v-if="!currentUserData.visitType&&(doctorInfo.recipe_power=='2'||doctorInfo.recipe_power=='0')"
                       :name="$t('consultRoom.chineseMedicineRecipeTemp')"
                       :label="$t('consultRoom.chineseMedicineRecipeTemp')"
                       class="temp-item" />
        </el-tabs>
        <cwDrugTable v-if="activeType==$t('consultRoom.westMedicineRecipe')"
                     :table-list="currentUserData.westernDrugList"
                     :recipe-type="1"
                     :active-name="activeName"
                     style="margin-top:0px;"
                     @saveStorage="saveStorage" />
        <template v-if="activeType==$t('consultRoom.chineseMedicineRecipe')">
          <div class="chinese-tip">
            <label>{{ $t('consultRoom.drugUse') }}：</label>
            <el-input v-model="currentUserData.drugUseMsg"
                      :placeholder="$t('consultRoom.chineseDrugTip')"
                      style="width: 60%;"
                      size="small"
                      readonly
                      @click.native="editUse" />
          </div>
          <cwDrugTable :table-list="currentUserData.chineseDrugList"
                       :recipe-type="2"
                       :active-name="activeName"
                       style="margin-top:0px;"
                       @saveStorage="saveStorage" />
        </template>
        <avue-crud v-if="activeType==$t('consultRoom.westMedicineRecipeTemp')"
                   ref="westernCrud"
                   :page.sync="westernPage"
                   :search.sync="westernSearch"
                   :data="westernTableData"
                   :table-loading="westernTableLoading"
                   :option="tempTableOption"
                   :header-cell-class-name="()=>{return 'crud-header'}"
                   :row-class-name="()=>{return 'crud-row'}"
                   class="crud"
                   style="margin-top: 10px;flex:1;"
                   @on-load="fetchTempData(1)"
                   @search-change="handleSubmit">
          <template slot-scope="{size,row}"
                    slot="menu">
            <el-button :size="size"
                       type="text"
                       @click="handleEdit(row)">{{ $t('common.edit') }}</el-button>
            <el-button v-if="!currentUserData.isRecipe"
                       :size="size"
                       type="text"
                       @click="handleUse(row)">{{ $t('common.use') }}</el-button>
            <!-- <el-button v-if="row.scope!==$t('consultRoom.commonTemp')"
                       :size="size"
                       type="text"
                       @click="deleteRow(row)">{{ $t('common.delete') }}</el-button> -->
          </template>
          <template slot-scope="{size,row}"
                    slot="keywordSearch">
            <el-input v-model="row.keyword"
                      :size="size"
                      :placeholder="$t('consultRoom.keywordSearch')"
                      class="input-with-select">
              <el-select slot="append"
                         v-model="row.scope"
                         style="width: 130px;">
                <el-option :label="$t('consultRoom.ownTemp')"
                           :value="2" />
                <el-option :label="$t('consultRoom.commonTemp')"
                           :value="1" />
              </el-select>
            </el-input>
          </template>
        </avue-crud>
        <avue-crud v-if="activeType==$t('consultRoom.chineseMedicineRecipeTemp')"
                   ref="chineseCrud"
                   :page.sync="chinesePage"
                   :search.sync="chineseSearch"
                   :data="chineseTableData"
                   :table-loading="chineseTableLoading"
                   :option="tempTableOption"
                   :header-cell-class-name="()=>{return 'crud-header'}"
                   :row-class-name="()=>{return 'crud-row'}"
                   class="crud"
                   style="margin-top: 10px;flex:1;"
                   @on-load="fetchTempData(2)"
                   @search-change="handleSubmit">
          <template slot-scope="{size,row}"
                    slot="menu">
            <el-button :size="size"
                       type="text"
                       @click="handleEdit(row)">{{ $t('common.edit') }}</el-button>
            <el-button v-if="!currentUserData.isRecipe"
                       :size="size"
                       type="text"
                       @click="handleUse(row)">{{ $t('common.use') }}</el-button>
            <!-- <el-button v-if="row.scope!==$t('consultRoom.commonTemp')"
                       :size="size"
                       type="text"
                       @click="deleteRow(row)">{{ $t('common.delete') }}</el-button> -->
          </template>
          <template slot-scope="{size,row}"
                    slot="keywordSearch">
            <el-input v-model="row.keyword"
                      :size="size"
                      :placeholder="$t('consultRoom.keywordSearch')"
                      class="input-with-select">
              <el-select slot="append"
                         v-model="row.scope"
                         style="width: 130px;">
                <el-option :label="$t('consultRoom.ownTemp')"
                           :value="2" />
                <el-option :label="$t('consultRoom.commonTemp')"
                           :value="1" />
              </el-select>
            </el-input>
          </template>
        </avue-crud>

        <div v-if="currentUserId"
             class="btn-end">
          <el-button :disabled="isDeleteRecipe || isCreatRecipe"
                     size="small"
                     plain
                     type="danger"
                     @click="$emit('stopInquiry')">{{ $t('consultRoom.endSession') }}</el-button>
          <el-button v-if="currentUserData.isRecipe"
                     :loading="isDeleteRecipe"
                     size="small"
                     type="danger"
                     class="danger"
                     @click="$emit('deleteRecipe')">{{ $t('consultRoom.deleteRecipe') }}</el-button>
          <el-button v-if="!currentUserData.isRecipe"
                     :loading="isCreatRecipe"
                     :disabled="!!currentUserData.doctorAdvice&&!provisionalRelease"
                     :type="!!currentUserData.doctorAdvice&&!provisionalRelease?'info':'primary'"
                     size="small"
                     @click="$emit('openRecipe')">{{ $t('consultRoom.confirmPrescrib') }}</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="doctorInfo.work_power==2||doctorInfo.work_power==3"
                   :name="$t('consultRoom.historyRecord')"
                   :disabled="!currentUserData.clinicType"
                   :label="$t('consultRoom.historyRecord')">
        <avue-form ref="historyForm"
                   v-model="historyData"
                   :option="historyOption"
                   style=" margin-top: 10px;"
                   @submit="fetchHistoryData" />
        <div class="case-information">
          <div v-for="(item,index) in caseList"
               :key="index"
               class="case-item">
            <div class="case-left">
              <el-row class="max-weights">
                <el-col :span="8">
                  <div class="overflow-1">
                    <label>{{ $t('consultRoom.name') }}：</label>
                    <el-tooltip :content="item.patient_name"
                                class="item"
                                effect="dark"
                                placement="bottom">
                      <span :style="{color: item.patient_name.length>3||item.patient_name.length==1?'#f00':'#333'}">{{ item.patient_name }}</span>
                    </el-tooltip>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div><label>{{ $t('common.sex') }}：</label><span>{{ item.sex }}</span></div>
                </el-col>
                <el-col :span="8">
                  <div><label>{{ $t('common.age') }}：</label><span :style="{color: Number(item.patient_age)<18||Number(item.patient_age)>60?'#f00':'#333'}">{{ item.patient_age?item.patient_age:'' }}</span>
                  </div>
                </el-col>
              </el-row>
              <div v-for="(cItem,cIndex) in caseHistoryList"
                   :key="cIndex"
                   class="info-item">
                <span>{{ cItem.label }}：</span>
                <span>{{ item[cItem.key]?item[cItem.key]:'无' }}</span>
              </div>
            </div>
            <div class="case-right">
              <el-button type="primary"
                         size="small"
                         @click="useCase(item)">按此病例开方</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 编辑中药用法 -->
    <editChineseDrug v-if="showEdit"
                     ref="editChineseDrug"
                     :current-user-data="currentUserData"
                     @editChineseInfo="editChineseInfo" />
    <!-- 编辑模板 -->
    <editTemplate v-if="showEditTemplate"
                  ref="editTemplate"
                  :recipe-type="recipeType"
                  :current-data="currentData"
                  @onRefresh="onRefresh" />
    <!-- 使用模板 -->
    <createRecipe v-if="showCreateRecipe"
                  ref="createRecipe"
                  :recipe-type="recipeType"
                  :current-data="currentData" />
  </div>
</template>

<script>
import {
  tempTableOption,
  historyOption,
  caseHistoryList
} from '@/const/crud/consultRoom'
import {
  recipeTemplateApi,
  inquiryRecordApi
} from '@/api/functionApi'
import {
  deepClone
} from '@/util/util'
import {
  mapGetters
} from 'vuex'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import visitPrescrib from './visitPrescrib'
import drugTable from './drugTable'
import cwDrugTable from './cwDrugTable'
import editChineseDrug from './editChineseDrug'
import editTemplate from '../recipeTemplate/editTemplate'
import createRecipe from './createRecipe'
export default {
  name: 'RightRoom',
  components: {
    visitPrescrib,
    drugTable,
    cwDrugTable,
    editChineseDrug,
    editTemplate,
    createRecipe
  },
  data () {
    return {
      tempTableOption,
      historyOption: deepClone(historyOption),
      caseHistoryList,
      drugUseMsg: '',
      showEdit: false,
      drugShow: true,
      showtip: 1,
      showEditTemplate: false,
      showCreateRecipe: false,
      currentData: false,
      westernTableData: [],
      chineseTableData: [],
      westernTableLoading: false,
      chineseTableLoading: false,
      westernPage: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      chinesePage: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      recipeType: '',
      westernSearch: {
        scope: 2
      },
      chineseSearch: {
        scope: 2
      },
      historyData: {},
      caseList: []
    }
  },
  computed: {
    provisionalRelease () {
      return socketPublic.state.provisionalRelease
    },
    ...mapGetters(['doctorInfo']),
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    currentMode () {
      return socketPublic.state.currentMode
    },
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    isCreatRecipe () {
      return socketPublic.state.isCreatRecipe
    },
    isDeleteRecipe () {
      return socketPublic.state.isDeleteRecipe
    },
    showUseAssist () {
      return socketPublic.state.showUseAssist
    },
    activeName: {
      get () {
        return socketPublic.state.activeTabName1
      },
      set (val) {
        socketPublic.commit('setActiveTabName1', val)
      }
    },
    activeType: {
      get () {
        return socketPublic.state.activeTabName2
      },
      set (val) {
        socketPublic.commit('setActiveTabName2', val)
      }
    }
  },
  watch: {
    currentUserData (obj) {
      if (obj.clinicType) {
        socketPublic.commit('setActiveTabName1', this.$t('consultRoom.medicalRecord'))
        this.$refs.historyForm.submit()
      } else {
        socketPublic.commit('setActiveTabName1', this.$t('consultRoom.visitPrescrib'))
      }
      // this.checkDrugsInfo(0)
    },
    currentUserId: {
      handler (obj) {
        this.drugUseMsg = ''
        setTimeout(() => {
          if (this.doctorInfo.work_power != 1 && this.currentUserData.clinicType) {
            this.$nextTick(() => {
              this.$refs.historyForm.submit()
            })
            if (this.doctorInfo.recipe_power == '1' || this.doctorInfo.recipe_power == '0') {
              socketPublic.commit('setActiveTabName2', this.$t('consultRoom.westMedicineRecipe'))
            }
            if (this.doctorInfo.recipe_power == '2') {
              socketPublic.commit('setActiveTabName2', this.$t('consultRoom.chineseMedicineRecipe'))
            }
          }
        }, 500)
      }
    }
  },
  // mounted() {
  //   this.doctorInfo.work_power = 2
  // },
  methods: {
    // 获取历史病历
    async fetchHistoryData (form, done) {
      try {
        const res = await inquiryRecordApi.fetchData({
          phone: this.currentUserId,
          dis_uid: this.currentUserData.pid,
          startTime: form.date[0],
          endTime: form.date[1]
        })
        this.caseList = res.data.data
        done()
      } catch (err) {
        done()
        console.error(err)
      }
    },

    // 使用历史病历
    useCase (row) {
      this.currentUserData.patientInfo.age = row.patient_age
      this.currentUserData.patientInfo.allergyContent = row.yp_allergic
      this.currentUserData.patientInfo.gender = row.sex
      this.currentUserData.patientInfo.illContent = row.ill_history
      this.currentUserData.patientInfo.mainDignose = row.main_diagnose
      this.currentUserData.patientInfo.pastContent = row.past_history
      this.currentUserData.patientInfo.user = row.patient_name
      socketPublic.commit('setActiveTabName1', this.$t('consultRoom.medicalRecord'))
    },
    // 获取模板数据
    async fetchTempData (type) {
      try {
        type === 1 ? this.westernTableLoading = true : this.chineseTableLoading = true
        let params = {}
        params.page = type === 1 ? this.westernPage.currentPage : this.chinesePage.currentPage
        params.limit = type === 1 ? this.westernPage.pageSize : this.chinesePage.pageSize
        params.recipeType = type
        params.status = 1
        if (type === 1) {
          params = {
            ...params,
            ...this.westernSearch
          }
        } else {
          params = {
            ...params,
            ...this.chineseSearch
          }
        }
        const res = await recipeTemplateApi.fetchData(params)
        if (type === 1) {
          this.westernTableData = res.data.data
          this.westernPage.total = res.data.count
          this.westernTableLoading = false
        } else {
          this.chineseTableData = res.data.data
          this.chinesePage.total = res.data.count
          this.chineseTableLoading = false
        }
      } catch (err) {
        type === 1 ? this.westernTableLoading = false : this.chineseTableLoading = false
        console.error(err)
      }
    },
    // 删除模板
    async deleteRow (row) {
      try {
        await this.$confirm(
          this.$t('recipeTemplate.deleteTip'),
          this.$t('common.warmPrompt')
        )
        await recipeTemplateApi.deleteTemplate({
          templateId: row.template_id
        })
        this.fetchTempData(this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? 1 : 2)
        this.$message.success(this.$t('recipeTemplate.changeTip3'))
      } catch (err) {
        console.error(err)
      }
    },

    // 使用
    handleUse (row) {
      this.recipeType = this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? this.$t(
        'consultRoom.westMedicineRecipe') : this.$t('consultRoom.chineseMedicineRecipe')
      this.currentData = row
      this.showCreateRecipe = true
      this.$nextTick(() => {
        this.$refs.createRecipe.isVisible = true
      })
    },

    // 编辑模板
    handleEdit (row) {
      this.recipeType = this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? this.$t(
        'consultRoom.westMedicineRecipe') : this.$t('consultRoom.chineseMedicineRecipe')
      this.currentData = row
      this.showEditTemplate = true
      this.$nextTick(() => {
        this.$refs.editTemplate.isVisible = true
      })
    },

    handleSubmit (params, done) {
      // params = this.filterForm(params)
      // this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? this.westernSearch = params : this.westernSearch = params
      this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? this.westernPage.currentPage = 1 : this
        .chinesePage.currentPage = 1
      this.fetchTempData(this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? 1 : 2)
      done()
    },

    onRefresh () {
      this.fetchTempData(this.activeType === this.$t('consultRoom.westMedicineRecipeTemp') ? 1 : 2)
    },

    saveStorage (obj) {
      if (obj) {
        this.currentUserData.deleteDrugList.push(obj)
      }
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    },

    // 格式化中药
    formatter (data) {
      if (!data.buy_count || !data.frequency || !data.usage_day) {
        return ''
      }
      return (
        (data.takeRequire ? data.takeRequire + ';' : '') +
        (data.takeMethods ? data.takeMethods + ';' : '') +
        data.buy_count +
        '付;' +
        data.frequency +
        ';' +
        data.usage_day
      )
    },

    // 编辑中药用法
    editUse () {
      this.showEdit = true
      this.$nextTick(() => {
        this.$refs.editChineseDrug.isVisible = true
      })
    },

    // 填写中药用法信息
    editChineseInfo (obj) {
      this.currentUserData.drugUseData = obj
      this.currentUserData.drugUseMsg = this.formatter(obj)
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
      this.showEdit = false
    },

    // 恢复药品
    recoverDrug () {
      this.currentUserData.drugList = [
        ...this.currentUserData.drugList,
        ...this.currentUserData.deleteDrugList
      ]
      this.currentUserData.deleteDrugList = []
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}

/deep/ .crud-header {
  background: #e0f0fd !important;
  color: #0074ff !important;
}

/deep/ .crud {
  .el-select {
    width: 110px !important;
  }

  .input-with-select {
    .el-input-group__append {
      background-color: #fff;
      color: #333;
    }
  }
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 12px;
}

.room-right {
  height: 100%;
  background-color: #fff;
  width: 32%;
  min-width: 450px;
  margin-right: 10px;
  padding-top: 18px;
  /deep/ .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 32px);
      overflow-y: auto;
    }
    .el-tabs__header {
      margin-bottom: 0;
      .el-tabs__item {
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        &:hover {
          color: #3b94ff;
        }
        &.is-active {
          color: #3b94ff;
        }
        &:first-of-type {
          margin-left: 10px;
        }
      }
      .el-tabs__active-bar {
        background-color: #3b94ff;
        height: 1px;
      }
      .el-tabs__nav-wrap {
        &::after {
          background-color: #d8d8d8;
          height: 1px;
        }
      }
    }
    .pane-visit,
    .medical-record {
      display: flex;
      flex-direction: column;
      height: 100%;
      .chinese-tip {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        padding-left: 12px;
      }
      .drug-table {
        flex: 1;
        overflow: auto;
      }
      .btn-end {
        text-align: center;
        margin-right: 32px;
        padding: 10px 0;
        button {
          font-weight: bold;
        }
      }
    }
  }
}
.medical-record {
  /deep/ .el-tabs {
    height: auto;
    .el-tabs__content {
      height: auto;
    }
  }
}

.case-information {
  .case-item {
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #d8d8d8;
    &:first-of-type {
      border-top: 1px solid #d8d8d8;
    }

    .case-left {
      .max-weights {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        height: 28px;
        line-height: 28px;
        padding: 0 20px;

        .el-col {
          height: 100%;

          & > div {
            height: 100%;
          }
        }

        label {
          font-weight: 600;
        }
      }

      .info-item {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        padding: 0 20px;
      }
    }

    .case-right {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
</style>
