var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("avue-crud", {
        ref: "aVueForm",
        attrs: {
          page: _vm.page,
          data: _vm.tableData,
          "table-loading": _vm.tableLoading,
          option: _vm.tableOption,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.fetchData,
          "search-change": _vm.handleSubmit,
        },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }