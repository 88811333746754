<template>
  <div :style="{width: showUseAssist?'29%':'40px',minWidth: showUseAssist?'350px':'auto'}"
       class="drug-use-assistant animate__animated animate__fadeOutRight">
    <div class="assistant-title">
      <el-tooltip v-model="showTip"
                  :content="showUseAssist?'收起助手(Esc)':'展开助手(F4)'"
                  :manual="showTip"
                  class="item"
                  effect="dark"
                  placement="left">
        <el-image :src="showUseAssist?'/svg/packUp.svg':'/svg/unfold.svg'"
                  fit="cover"
                  @click="changeUseAssist(!showUseAssist)"
                  @mouseout="()=>{showTip=false}" />
      </el-tooltip>
      <template v-if="showUseAssist">
        <el-image src="/img/yy_logo.png"
                  fit="cover"
                  style="margin: 0 10px;" />
        <span>银叶合理用药助手</span>
      </template>
    </div>
    <div v-show="showUseAssist"
         class="assistant-content ">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用药检测"
                     name="first">
          <drugTest v-show="checkSuccess"
                    ref="drugTestRef" />
          <testFail v-show="!checkSuccess"
                    ref="testFailRef" />
        </el-tab-pane>
        <!-- <el-tab-pane name="third">
          <template slot="label">
            <el-badge :value="0">
              <span>重要公告</span>
            </el-badge>
          </template>
          <importantAnnounce ref="importantAnnounceRef" />
        </el-tab-pane> -->
        <el-tab-pane label="处方用药"
                     style="height:100%"
                     name="second">
          <drugInfo ref="drugInfo" />
        </el-tab-pane>
        <el-tab-pane label="辅助功能"
                     name="fourth">
          <auxiliaryFunc ref="auxiliaryFuncRef" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import drugTest from './drugTest.vue'
import testFail from './testFail.vue'
import importantAnnounce from './importantAnnounce.vue'
import auxiliaryFunc from './auxiliaryFunc.vue'
import drugInfo from './drugInfo.vue'
import socketPublic from '@/socketMsg/socketPublic.js'
export default {
  name: 'DrugUseAssistant',
  components: {
    drugTest,
    importantAnnounce,
    auxiliaryFunc,
    drugInfo,
    testFail
  },
  data () {
    return {
      showTip: false,
      activeName: 'first',
      isFirst: false
    }
  },
  computed: {
    showUseAssist () {
      return socketPublic.state.showUseAssist
    },
    checkSuccess () {
      return socketPublic.state.checkSuccess
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if (to.path == '/consultRoom/index') {
          this.showTip = true
          setTimeout(() => {
            this.showTip = false
          }, 5000)
        } else {
          this.showTip = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    document.addEventListener('keyup', this.keyupEvent)
  },
  destroyed () {
    document.removeEventListener('keyup', this.keyupEvent)
  },
  methods: {
    changeUseAssist (bol) {
      socketPublic.commit('setShowUseAssist', bol)
      this.showTip = false
    },
    // 放开按键
    keyupEvent (e) {
      const evt = window.event || e
      const code = evt.keyCode || evt.which
      if (code == 27) this.changeUseAssist(false)
      if (code == 115) this.changeUseAssist(true)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-badge__content {
  top: 10px;
  right: 6px;
}
.drug-use-assistant {
  // width: 30%;
  // min-width: 350px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  .assistant-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 10px;
    padding-bottom: 5px;
    height: 30px;
    /deep/ .el-image {
      width: 24px;
    }
  }
  .assistant-content {
    height: calc(100% - 60px);
    padding: 0 10px;
    /deep/ .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 40px);
        overflow-y: auto;
      }
    }
    /deep/ .el-tabs__header {
      margin-bottom: 6px;
    }
    /deep/ .el-tabs__item {
      color: #333;
      font-weight: 600;
    }
    /deep/ .el-tabs__item.is-active {
      color: #2d85ee;
    }
    /deep/ .el-tabs__active-bar {
      background-color: #2d85ee;
    }
    /deep/ .el-tabs__nav-wrap::after {
      background: #fff;
    }
  }
}
</style>
