var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          page: _vm.page,
          data: _vm.tableData,
          "table-loading": _vm.tableLoading,
          option: _vm.tableOption,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.fetchData,
          "search-change": _vm.handleSubmit,
        },
        scopedSlots: _vm._u([
          {
            key: "searchMenu",
            fn: function (ref) {
              var size = ref.size
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: size, type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.handleEdit(
                          null,
                          _vm.$t("recipeRecord.westMedicineRecipe")
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("recipeTemplate.addWestTem")))]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: size, type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.handleEdit(
                          null,
                          _vm.$t("recipeRecord.chineseMedicineRecipe")
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("recipeTemplate.addChineseTem")))]
                ),
              ]
            },
          },
          {
            key: "menu",
            fn: function (ref) {
              var size = ref.size
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: size, type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEdit(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("common.edit")))]
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    style: {
                      color: row.status === "1" ? "#3b94ff" : "#ff0017",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeStatus(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(row.status_desc))]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _vm.showEditTemplate
        ? _c("editTemplate", {
            ref: "editTemplate",
            attrs: {
              "recipe-type": _vm.recipeType,
              "current-data": _vm.currentData,
            },
            on: { onRefresh: _vm.onRefresh },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }