<template>
  <el-dialog :title="title"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="1100px"
             append-to-body
             @close="isVisible = false">
    <div class="edit-template">
      <el-popover v-model="showDrugList"
                  :title="$t('recipeTemplate.selectDrugs')"
                  :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
                  placement="top-start"
                  width="1000"
                  trigger="manual">
        <div slot="reference"
             class="search-drug">
          <el-input v-model="drugName"
                    :placeholder="$t('recipeTemplate.searchTip1')"
                    style="width: 300px;margin-right: 20px;"
                    size="small"
                    @keydown.native.enter="fetchDrugsData" />
          <el-button type="primary"
                     size="small"
                     @click="fetchDrugsData">{{ $t('common.inquire') }}</el-button>
        </div>
        <avue-crud ref="crud"
                   :page.sync="page"
                   :data="drugList"
                   :table-loading="tableLoading"
                   :option="drugTableOption"
                   :row-class-name="()=>{return 'pointer'}"
                   @on-load="fetchDrugsData"
                   @row-click="selectDrug" />
      </el-popover>
      <div class="template-content">
        <div class="template-left">
          <div class="left-tip">{{ $t('recipeTemplate.drugsInfoTip') }}</div>
          <avue-form ref="leftForm"
                     v-model="leftFormData"
                     :option="leftOption"
                     @submit="leftSubmit" />
        </div>
        <div class="template-right">
          <div class="content-list">
            <div v-for="(item,index) in contentList"
                 :key="index"
                 class="list-item">
              <span v-if="recipeType===$t('recipeRecord.westMedicineRecipe')"
                    class="text">{{ item.drug_name +' ' +item.usage +' ' +item.dosage +item.dosage_unit +' ' +item.frequency +' 共' +item.day_count +'天 ' +item.buy_count +item.buy_unit }}</span>
              <span v-if="recipeType===$t('recipeRecord.chineseMedicineRecipe')"
                    class="text">{{ item.drug_name +' ' +item.dosage +item.dosage_unit + ' ' +item.usage }}</span>
              <span class="delete"
                    @click="deleteItem(index)">{{ $t('common.delete') }}</span>
            </div>
          </div>
          <avue-form ref="rightForm"
                     v-model="rightFormData"
                     :option="rightOption"
                     @submit="rightSubmit">
            <!-- <template slot="tip">
              <span style="color: #f00;font-size: 14px;">*请搜索后再选择诊断</span>
            </template> -->
            <template slot="diagnose_info"
                      slot-scope="{size}">
              <el-select ref="diagnoseInfo"
                         v-model="rightFormData.diagnose_info"
                         :loading="isLoading"
                         :multiple-limit="3"
                         :size="size"
                         :remote-method="fetchDiagnose"
                         filterable
                         multiple
                         value-key="id"
                         placeholder="请搜索后再选择诊断"
                         remote>
                <el-option v-for="item in diagnoseList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.code" />
              </el-select>
            </template>
          </avue-form>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  leftOption,
  rightOption,
  drugTableOption
} from '@/const/crud/recipeTemplate'
import { recipeTemplateApi } from '@/api/functionApi'
import { consultRoomApi } from '@/api/consultRoomApi'

export default {
  name: 'EditTemplate',
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    recipeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      drugTableOption,
      leftOption: leftOption(this.recipeType === '西药处方' ? 2 : 1),
      rightOption: rightOption(this.recipeType === '西药处方' ? 2 : 1),
      isVisible: false,
      leftFormData: {},
      rightFormData: {},
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      paramsSearch: {},
      tableLoading: false,
      isLoading: false,
      showDrugList: false,
      drugName: '',
      title: '',
      drugList: [],
      contentList: [],
      diagnoseList: []
    }
  },
  watch: {
    isVisible(val) {
      if (!val) {
        if (this.$parent.showEditTemplate) this.$parent.showEditTemplate = false
        if (this.$parent.$parent.showEditTemplate) {
          this.$parent.$parent.showEditTemplate = false
        }
      } else {
        if (this.currentData) {
          this.fetchDetail()
        } else {
          this.fetchDiagnose('')
        }
        this.title = this.currentData
          ? this.$t('common.edit') +
          this.recipeType +
          this.$t('recipeTemplate.template') +
          '（' +
          this.currentData['template_name'] +
          '）'
          : this.recipeType === '西药处方'
            ? this.$t('recipeTemplate.addWestTem')
            : this.$t('recipeTemplate.addChineseTem')
      }
    }
  },
  methods: {
    async fetchDetail() {
      try {
        const res = await recipeTemplateApi.fetchDetail({
          tempId: this.currentData.template_id,
          recipeType:
            this.recipeType === '西药处方'
              ? 1
              : this.recipeType === '中药处方'
                ? 2
                : ''
        })
        const data = res.data.data
        const infoList = data.diagnose_info ? data.diagnose_info.split(';') : []
        const codeList = data.diagnose_code ? data.diagnose_code.split(';') : []
        this.contentList = data.drugList
        this.rightFormData = {
          template_name: data.template_name,
          diagnose_info: data.diagnose_code ? codeList : '',
          custom_diagnose: data.custom_diagnose,
          frequency: data.frequency,
          takeMethods: data.takeMethods,
          takeRequire: data.takeRequire,
          buy_count: data.buy_count,
          usage_day: data.usage_day
        }
        if (data.diagnose_code) {
          this.diagnoseList = codeList.map((item) => {
            return {
              code: item
            }
          })
          this.diagnoseList.forEach((item, index) => {
            item.name = infoList[index]
          })
        } else {
          this.fetchDiagnose('')
        }
      } catch (err) {
        console.error(err)
      }
    },
    // 药品列表
    async fetchDrugsData() {
      try {
        if (!this.drugName) return
        this.tableLoading = true
        const res = await recipeTemplateApi.fetchGrugs({
          keyword: this.drugName,
          page: this.page.currentPage,
          limit: this.page.pageSize,
          drugType:
            this.recipeType === '西药处方'
              ? 1
              : this.recipeType === '中药处方'
                ? 3
                : ''
        })
        this.drugList = res.data.data || []
        this.page.total = res.data.count
        this.tableLoading = false
        if (this.drugList.length > 0) this.showDrugList = true
        else this.$message.warning(this.$t('recipeTemplate.searchTip2'))
      } catch (err) {
        this.tableLoading = false
        console.error(err)
      }
    },
    // 获取诊断列表
    async fetchDiagnose(val) {
      try {
        this.isLoading = true
        const res =
          this.recipeType === '西药处方'
            ? await consultRoomApi.fetchWestDrugDiagnose({ keyword: val || '' })
            : await consultRoomApi.fetchChineseDrugDiagnose({ keyword: val || '' })
        this.diagnoseList = res.data.data
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    },
    // 点击药品
    selectDrug(row) {
      this.leftFormData.drug_id = row.drugId
      this.leftFormData.drug_name = row.drugName
      this.leftFormData.usage = row.usage
      this.leftFormData.day_count = 3
      this.leftFormData.buyUnit = row.specification
        ? row.specification.split('/')[1]
        : ''
      this.showDrugList = false
    },
    deleteItem(index) {
      this.contentList.splice(index, 1)
    },
    leftSubmit(form, done) {
      this.contentList.push(form)
      for (const key in this.leftFormData) {
        delete this.leftFormData[key]
      }
      done()
    },
    async rightSubmit(form, done) {
      try {
        if (form.diagnose_info && form.diagnose_info.length > 3) {
          this.$message.warning(this.$t('recipeTemplate.diagnosisTip'))
          done()
          return
        }
        await recipeTemplateApi.updateTemplate({
          templateId: this.currentData ? this.currentData.template_id : 0,
          tempName: form.template_name,
          diagnose:
            form.diagnose_info && form.diagnose_info.length > 0
              ? this.$refs.diagnoseInfo.$el.innerText.split('\n').join(';')
              : '',
          diagnoseCode:
            form.diagnose_info && form.diagnose_info.length > 0
              ? form.diagnose_info.join(';')
              : '',
          customDiagnose: form.custom_diagnose,
          frequency: form.frequency,
          takeMethods: form.takeMethods,
          takeRequire: form.takeRequire,
          buy_count: form.buy_count,
          usage_day: form.usage_day,
          cDrugs: this.contentList.map((item) => {
            return this.recipeType === '西药处方'
              ? `${item.drug_id}::${item.usage}::${item.dosage}::${item.dosage_unit}::${item.frequency}::${item.buy_count}::${item.buy_unit}::${item.day_count}`
              : `${item.drug_id}::${item.usage}::${item.dosage}::${item.dosage_unit}`
          }),
          recipeType:
            this.recipeType === '西药处方'
              ? 1
              : this.recipeType === '中药处方'
                ? 2
                : ''
        })
        this.$message.success(this.$t('common.operateSucc'))
        this.isVisible = false
        this.$emit('onRefresh')
        done()
      } catch (err) {
        done()
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
/deep/ .el-dialog__body {
  padding: 20px 50px;
}

.edit-template {
  height: 600px;
  .template-content {
    display: flex;
    .template-left {
      width: 30%;
      .left-tip {
        font-size: 12px;
        color: #e42113;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 30px;
      }
    }
    .template-right {
      flex: 1;
      margin-left: 120px;
      .content-list {
        border: 1px solid #3b94ff;
        height: 200px;
        margin-bottom: 20px;
        overflow: auto;
        padding: 2px 0 6px;
        .list-item {
          margin-top: 10px;
          margin-left: 20px;
          margin-right: 20px;
          border-bottom: 1px dashed #333333;
          display: flex;
          justify-content: space-between;
          .delete {
            color: #ff4a5a;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
