import request from '@/router/axios'
import qs from 'qs'
// 公共api
export const commonApi = {
  // 获取通用药品用法
  fetchUsage: (query) => {
    return request({
      url: '/doctor/drugs/getUsage',
      method: 'get',
      params: query
    })
  },
  // 获取通用频次
  fetchFrequency: (query) => {
    return request({
      url: '/doctor/drugs/frequency',
      method: 'get',
      params: query
    })
  },
  // 新获取通用频次
  fetchNewFrequency: (query) => {
    return request({
      url: '/doctor/checkDrugs/frequency',
      method: 'get',
      params: query
    })
  },
  // 获取通用包装单位
  fetchPackageUnit: (query) => {
    return request({
      url: '/doctor/drugs/getPackageUnit',
      method: 'get',
      params: query
    })
  },
  // 获取通用用量单位
  fetchDosageUnit: (query) => {
    return request({
      url: '/doctor/drugs/getDosageUnit',
      method: 'get',
      params: query
    })
  },
  // 获取指定的常用自动回复话术
  getSecondSpeech: (query) => {
    return request({
      url: '/doctor/speechcraft/getSecondSpeech',
      method: 'get',
      params: query
    })
  },
  // 视频日志
  videoLog: (query) => {
    return request({
      url: '/doctor/account/videoLog',
      method: 'post',
      data: qs.stringify(query)
    })
  }
}
