var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      { staticClass: "total-box" },
      _vm._l(_vm.totalList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "total-item",
            on: {
              click: function ($event) {
                return _vm.jumpMenu(item)
              },
            },
          },
          [
            _c("div", { staticClass: "total-title" }, [
              _vm._v(_vm._s(item.title)),
            ]),
            _c(
              "div",
              { staticClass: "total-main" },
              _vm._l(item.list, function (fItem, fIndex) {
                return _c(
                  "div",
                  { key: fIndex, staticClass: "total-main-item" },
                  [
                    _c("div", { staticClass: "total-num" }, [
                      _c("div", { staticClass: "total-count" }, [
                        _vm._v(_vm._s(fItem.value)),
                      ]),
                      _c("div", { staticClass: "total-tip" }, [
                        _vm._v(_vm._s(fItem.label)),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "menu" },
      _vm._l(_vm.menuList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "menu-item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.jumpMenu(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "menu-image" },
              [
                _c("el-image", {
                  attrs: { src: "/img/" + item.image + ".png", fit: "cover" },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "menu-name" }, [
              _vm._v(_vm._s(item.name)),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }