<template>
  <div class="test-fail">
    <div class="fail-title">检测异常，请重新检测</div>
    <div class="cxjc"
         @click="checkAgain">重新检测</div>
  </div>
</template>
<script>
import checkDrug from '@/socketMsg/checkDrug.js'

export default {
  name: 'TestFail',
  data () {
    return {

    }
  },
  methods: {
    checkAgain () {
      checkDrug.newCheckDrugs(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.test-fail {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fail-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .cxjc {
    cursor: pointer;
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: #f4faff;
    border: 1px solid rgba(59, 148, 255, 1);
    border-radius: 5px;
    margin-left: 10px;
    font-size: 16px;
    color: #2d85ee;
    text-align: center;
  }
}
</style>
