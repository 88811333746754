<template>
  <div class="text-chat">
    <div class="chat-content">
      <div class="top-time">{{ startTime }}</div>
      <div class="top-content">
        <span class="red-notice">【系统消息】</span>欢迎{{
          doctorInfo.name
        }}医生进入网络接诊
      </div>
      <div class="info-list">
        <template v-for="(item, index) in chatList">
          <div v-if="item.clientType != 3 && item.msgType == 2"
               :class="item.clientType === 1 ? '' : 'doctor'"
               :key="index"
               class="list-item"
               @contextmenu.prevent.stop="handleMenu($event, item, index)">
            <!-- <div class="info-image">
              <el-image :src="
                          item.clientType === 1
                            ? sex === 1
                              ? '/img/man_default.png'
                              : '/img/woman_default.png'
                            : doctorInfo.doctor_img
                              ? doctorInfo.doctor_img
                              : '/img/doctor_default.png'
                        "
                        fit="cover" />
            </div> -->
            <div class="info-content">
              <div class="time-line">
                {{ item.clientName
                }}<span class="chat-time">{{
                  dateFormat(+new Date(item.msgTime), 'HH:mm:ss')
                }}</span>
              </div>
              <div v-if="item.medieType == 1"
                   class="msg">{{ item.msg }}</div>
              <div v-if="item.medieType == 2"
                   class="msg">
                <el-image :src="item.msg"
                          :preview-src-list="[item.msg]"
                          style="width: 100px" />
              </div>
            </div>
          </div>
          <div v-if="item.clientType != 3 && item.msgType == 1"
               :key="index"
               class="stystem-tip">
            <div class="top-time">
              {{ dateFormat(+new Date(item.msgTime), 'HH:mm:ss') }}
            </div>
            <div class="top-content">
              <span class="red-notice">【系统消息】</span>{{ item.msg }}
            </div>
          </div>
          <div v-if="item.clientType == 3"
               :key="index"
               class="withdraw-tip">
            <div class="top-time">
              {{ dateFormat(+new Date(item.msgTime), 'HH:mm:ss') }}
            </div>
            <div class="top-content">
              <span class="withdraw-notice">{{
                $t('consultRoom.withdrawMsgTip')
              }}</span>
            </div>
          </div>
        </template>
        <!-- <div class="list-item doctor">
            <div class="info-image">
              <el-image src="https://test-image.ynye.com/image/2022-06-27/16649533829117582.png"
                        fit="cover" />
            </div>
            <div class="info-content">
              <div class="time-line">朱璐璐<span class="chat-time">16:44:18</span></div>
              <div class="msg">您好！已收到您的复诊购药需求。请问您用该药后是否出现不良反应或过敏？</div>
            </div>

          </div> -->
      </div>
      <div v-if="showMenu"
           :style="{ left: menuLeft + 'px', top: menuTop + 'px' }"
           class="right-menu">
        <div class="menu-item"
             @click.stop="withdrawMsg">
          {{ $t('consultRoom.withdrawMsg') }}
        </div>
      </div>
    </div>
    <div class="text-submit">
      <div class="content-zoom">
        <el-input id="elInput"
                  ref="elInput"
                  v-model.trim="sendData.content"
                  type="textarea"
                  resize="none"
                  placeholder="请输入消息"
                  @paste.native="handlePaste"
                  @keyup.enter.native="sendMsg" />
      </div>
      <!---->
      <div class="btn-zoom">
        <el-button v-if="currentUserId && currentMode == 2"
                   plain
                   round
                   size="small"
                   class="btn"
                   @click="changeSessionEnd">
          {{ $t('consultRoom.sessionBase') }}
        </el-button>
        <el-button v-if="currentUserId"
                   plain
                   round
                   size="small"
                   class="btn"
                   @click="changeDoctor(true)">
          {{ $t('consultRoom.arrangeRefer') }}
        </el-button>
        <el-button v-if="currentUserId"
                   plain
                   round
                   size="small"
                   class="btn"
                   @click="sendMsg">{{ $t('consultRoom.send') }}</el-button>
        <el-popover v-model="showCommonWord"
                    :popper-options="{
                      boundariesElement: 'viewport',
                      removeOnDestroy: true
                    }"
                    placement="top-end"
                    width="590"
                    popper-class="commonWordPopper"
                    trigger="click">
          <el-button slot="reference"
                     plain
                     round
                     size="small"
                     style="margin-left: 10px"
                     class="btn">{{ $t('consultRoom.commonWords') }}</el-button>
          <el-tabs v-model="activeName"
                   @tab-click="tabClick">
            <el-tab-pane :name="$t('consultRoom.inquirySpec')"
                         :label="$t('consultRoom.inquirySpec')"
                         class="pane-visit">
              <inquirySpec ref="inquirySpec" />
            </el-tab-pane>
            <el-tab-pane :name="$t('consultRoom.commonWords')"
                         :label="$t('consultRoom.commonWords')">
              <commonWord ref="commonWord"
                          @selectWord="selectWord"
                          @closeCommonWord="closeCommonWord" />
            </el-tab-pane>
          </el-tabs>
          <i class="el-icon-close"
             @click="showCommonWord = false" />
        </el-popover>
      </div>
    </div>
    <changeDoctor v-if="showChangeDoctor"
                  ref="changeDoctor" />
  </div>
</template>

<script>
import changeDoctor from './changeDoctor'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/util/date'
import inquiry from '@/socketMsg/inquiry.js'
import socketPublic from '@/socketMsg/socketPublic.js'
import ScreenShort from 'js-web-screen-shot'
import { consultRoomApi } from '@/api/consultRoomApi'
import commonWord from './commonWord'
import inquirySpec from './inquirySpec'

export default {
  components: {
    changeDoctor,
    inquirySpec,
    commonWord
  },
  props: {
    chatList: {
      type: Array,
      default: () => {
        return []
      }
    },
    startTime: {
      type: String,
      default: ''
    },
    sex: {
      type: Number,
      default: 1
    },
    currentMode: {
      type: Number,
      default: 1
    },
    currentUserId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dateFormat,
      showImgs: '',
      sendData: {
        content: ''
      },
      showChangeDoctor: false,
      showCommonWord: false,
      activeName: this.$t('consultRoom.commonWords'),
      showMenu: false,
      menuLeft: 0,
      menuTop: 0,
      currentData: {},
      currentIndex: 0
    }
  },

  computed: {
    ...mapGetters(['doctorInfo']),
    inquiryList () {
      return socketPublic.state.inquiryList
    },
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    autoAccept () {
      return socketPublic.state.autoAccept
    },
    sendMsgContent () {
      return socketPublic.state.sendMsgContent
    }
  },

  watch: {
    'sendData.content': {
      handler () {
        socketPublic.commit('setSendMsg', '')
      }
    },
    chatList () {
      this.scrollBottom()
    },
    currentUserId () {
      this.showCommonWord = false
      this.sendData.content = ''
    },
    $route () {
      this.showCommonWord = false
    },
    sendMsgContent (newVal) {
      if (newVal) {
        this.sendData.content = newVal
        this.sendData = { ...this.sendData }
      }
    }
  },
  created () {
    document.addEventListener('click', this.hideMenu)
    document.addEventListener('mousedown', this.clickHideMenu)
  },

  // mounted() {
  //   let div = document.querySelector('.chat-content')
  //   div.scrollTop = div.scrollHeight
  // },
  destroyed () {
    document.removeEventListener('click', this.hideMenu)
    document.removeEventListener('mousedown', this.clickHideMenu)
  },

  methods: {
    // 隐藏撤回消息
    hideMenu () {
      this.showMenu = false
    },
    // 点击隐藏
    clickHideMenu (e) {
      const { button } = e
      if (button === 2) {
        this.showMenu = false
      }
    },
    // 展示撤回消息菜单
    handleMenu (e, item, index) {
      if (item.msgType != 2 || item.clientType != 2) return
      this.currentData = item
      this.currentIndex = index
      this.showMenu = true
      this.menuLeft = e.pageX
      this.menuTop = e.pageY
    },
    // 撤回消息
    withdrawMsg () {
      const modifyData = {
        key: 11,
        value: this.currentData.msgId
          ? String(this.currentData.msgId)
          : String(this.currentIndex + 1 + 1)
      }
      inquiry.doctorModify(modifyData, this.currentUserId)
      this.showMenu = false
      let index = 0
      if (this.currentData.msgId) {
        index = this.currentUserData.chatList.findIndex(
          (item) => item.msgId === this.currentData.msgId
        )
      } else index = this.currentIndex
      this.currentUserData.chatList[index].clientType = 3
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: this.currentUserData
      })
    },
    // 会话置底
    changeSessionEnd () {
      const index = this.inquiryList.findIndex(
        (item) => item.userId == this.currentUserId
      )
      if (index > -1) {
        const item = this.inquiryList.splice(index, 1)[0]
        item.isPush = true
        this.inquiryList.push(item)
        this.currentUserData.isPush = true
        inquiry.commonSetCache({
          name: 'currentUserData_' + this.currentUserId,
          content: this.currentUserData
        })

        inquiry.commonSetCache({
          name: 'inquiryList',
          content: this.inquiryList
        })

        if (this.autoAccept) {
          const obj = this.inquiryList.find((item) => item.inquiryMode == 2)
          if (obj && this.inquiryList.length > 1) {
            setTimeout(() => {
              inquiry.startVisit(obj)
            }, 500)
          }
        }
      }
    },
    closeCommonWord () {
      this.showCommonWord = false
    },
    // 选择常用话术
    selectWord (str) {
      this.sendData.content = str
      this.$refs.elInput.focus()
    },
    handlePaste (event) {
      const items = (event.clipboardData || window.clipboardData).items
      let file = null

      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地')
        return
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile()
          break
        }
      }
      if (file && this.currentUserId) {
        const formData = new FormData()
        formData.append('file', file)
        this.uploadFiles(formData)
        // this.$message.error("粘贴内容非图片");
        // return;
      }
    },
    // 话术切换
    tabClick (e) {
      if (e.name === this.$t('consultRoom.inquirySpec')) {
        this.$refs.inquirySpec.fetchData()
      } else {
        this.$refs.commonWord.fetchData()
      }
    },
    // 安排转诊
    changeDoctor (bool) {
      this.showChangeDoctor = bool
      if (bool) {
        this.$nextTick(() => {
          this.$refs.changeDoctor.isVisible = true
        })
      }
    },
    // 发送信息
    sendMsg () {
      if (this.sendData.content == '') return
      if (!this.currentUserId) return
      inquiry.inquirySendMsg(this.sendData.content, this.currentUserId)
      this.sendData.content = ''
    },
    // 设置滚动到最下面
    scrollBottom () {
      this.$nextTick(() => {
        const div = document.querySelector('.chat-content')
        setTimeout(() => {
          div.scrollTop = div.scrollHeight
        }, 100)
      })
    },
    cutPageImg () {
      // 截图确认按钮回调函数
      const callback = (base64) => {
        const regex = /data:.*base64,/
        var base64Str = base64.replace(regex, '')
        this.uploadBaseFiles(base64Str)
      }
      // 截图取消时的回调函数
      const closeFn = () => {
        // console.log("截图窗口关闭");
      }
      setTimeout(() => {
        new ScreenShort({
          enableWebRtc: false,
          level: 99, // 层级级别
          completeCallback: callback,
          closeCallback: closeFn,
          showScreenData: true
        })
      }, 0)
    },
    // 上传图片
    async uploadBaseFiles (base64File) {
      var uploadObj = { files: base64File }
      let res = await consultRoomApi.uploadCutImg(uploadObj)
      res = res.data
      if (res.code == 0 && res.data != '') {
        inquiry.inquirySendImg(res.data, this.currentUserId)
      }
    },
    // 上传图片
    async uploadFiles (files) {
      // var uploadObj = { files: base64File }
      let res = await consultRoomApi.uploadImg(files)
      res = res.data
      if (res.code == 0 && res.data != '') {
        inquiry.inquirySendImg(res.data, this.currentUserId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    color: #f00;
  }
}
.text-chat {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #f3f4f6;
  .chat-content {
    border-bottom: #dddddd 1px solid;
    overflow: auto;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #333333;
    font-size: 14px;
    flex: 1;
    .top-time {
      text-align: center;
      padding-top: 12px;
      font-size: 12px;
      color: #999999;
      letter-spacing: 0;
      font-weight: 400;
    }
    .top-content {
      text-align: center;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      .red-notice {
        color: #f11111;
      }
    }
    .info-list {
      font-size: 14px;
      padding: 10px 0 0;
      .list-item {
        display: flex;
        margin-bottom: 20px;
        .info-image {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          overflow: hidden;
        }
        .info-content {
          flex: 1;
          width: fit-content;
          min-width: 120px;
          margin-left: 10px;
          max-width: 100%;
          &:hover {
            .chat-time {
              display: inline-block;
            }
          }
          .chat-time {
            font-size: 12px;
            display: none;
          }
          .time-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #999;
            margin-bottom: 4px;
          }
          .msg {
            width: fit-content;
            max-width: 90%;
            padding: 10px;
            border-radius: 5px;
            word-wrap: break-word;
            word-break: normal;
            background-color: #fff;
          }
        }
        &.doctor {
          flex-direction: row-reverse;
          .info-content {
            margin-right: 10px;
          }
          .time-line {
            flex-direction: row-reverse;
          }
          .msg {
            background-color: #cbe7ff;
            float: right;
          }
        }
      }
    }
    .stystem-tip {
      margin-bottom: 10px;
    }
    .right-menu {
      position: fixed;
      z-index: 999;
      padding: 5px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .menu-item {
        display: block;
        cursor: pointer;
        padding: 2px 4px;
        border-radius: 3px;
        color: #333;
        transition: all 0.5s;
        &:hover {
          background-color: #f00;
          color: #fff;
        }
      }
    }
  }
  .text-submit {
    height: 25%;
    background: #f3f4f6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .content-zoom {
      flex: 1;
      box-sizing: border-box;
      /deep/ .el-textarea {
        height: 100%;
      }
      /deep/ .el-textarea__inner {
        height: 100%;
        border: none;
        color: #333333;
        background: #f3f4f6;
        padding: 10px 5px 0 15px;
        line-height: 1.2;
      }
    }
    .btn-zoom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      position: relative;
      padding-bottom: 12px;
      padding-right: 30px;
      .cut-btn {
        position: absolute;
        cursor: pointer;
        width: 18px;
        height: 18px;
        left: 20px;
        background-image: url('../../../public/img/icon_cut.png');
        background-size: cover;
      }
      /deep/ .el-button {
        // padding: 7px;
        // margin: 6px;
        margin-top: 10px;
        width: 96px;
        .el-image {
          vertical-align: middle;
          margin-right: 6px;
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .text-chat {
    .chat-content {
      font-size: 12px;
    }
  }
}
</style>
