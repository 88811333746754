import { translate as $t } from '@/lang'
import { defaultDate } from '@/util/date'

export const tableOption = {
  size: 'medium',
  dialogDrag: true,
  dialogMenuPosition: 'center',
  dialogCustomClass: 'inquiry-record-dialog',
  align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  searchMenuSpan: 2,
  highlightCurrentRow: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  columnBtn: false,
  editText: $t('inquiryRecord.editCaseHist'),
  menuWidth: 200,
  saveBtn: false,
  updateBtn: false,
  cancelBtn: false,
  column: [
    {
      label: $t('inquiryRecord.username'),
      display: false,
      overHidden: true,
      prop: 'patient_name'
    },
    {
      label: $t('common.sex'),
      display: false,
      overHidden: true,
      prop: 'patient_gender',
      formatter: (val) => {
        return val.patient_gender == '1' ? '男' : '女'
      }
    },
    {
      label: $t('common.age'),
      overHidden: true,
      display: false,
      prop: 'patient_age'
    },
    {
      label: $t('inquiryRecord.linkPhone'),
      overHidden: true,
      display: false,
      prop: 'patient_phone'
    },
    {
      width: 200,
      label: $t('inquiryRecord.inquiryDate'),
      overHidden: true,
      prop: 'date',
      type: 'daterange',
      display: false,
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      searchSpan: 8,
      searchOrder: 1,
      clearable: false,
      formatter: (val) => {
        return val.inquiry_time
      },
      // pickerOptions: {
      //   disabledDate: (time) => {
      //     // 是否限制的判断条件
      //     if (tableOption.timeData) {
      //       var date = new Date(tableOption.timeData) // 只可以选择当月
      //       // 以下最多可选一个月
      //       // const one = 30 * 24 * 3600 * 1000
      //       // const minTime = tableOption.timeData - one
      //       // const maxTime = tableOption.timeData + one
      //       // 这里就是限制的关键，大于或者小于本月的日期被禁用
      //       return (
      //         date.getMonth() > new Date(time).getMonth() ||
      //         date.getMonth() < new Date(time).getMonth() // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
      //         // time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
      //       )
      //     } else {
      //       return false
      //     }
      //   },
      //   onPick: ({ maxDate, minDate }) => {
      //     tableOption.timeData = minDate.getTime()
      //     if (maxDate) {
      //       // 解除限制
      //       tableOption.timeData = ''
      //     }
      //   }
      // },
      searchValue: defaultDate().dataArr
    },
    {
      label: $t('inquiryRecord.inquiryType'),
      overHidden: true,
      display: false,
      prop: 'type'
    },
    {
      label: $t('inquiryRecord.userOrigin'),
      overHidden: true,
      display: false,
      prop: 'dispensary_name'
    },
    {
      label: $t('recipeRecord.channelType'),
      overHidden: true,
      prop: 'doctor_data_source',
      formatter: (obj) => {
        return obj.doctor_data_source == 1
          ? $t('recipeRecord.smallProgramDrugstoreEnd')
          : obj.doctor_data_source == 2
            ? $t('recipeRecord.PCDrugstoreEnd')
            : obj.doctor_data_source == 3
              ? $t('recipeRecord.H5DrugstoreEnd')
              : obj.doctor_data_source == 4
                ? $t('recipeRecord.userEnd')
                : obj.doctor_data_source == 5
                  ? $t('recipeRecord.shopSmallProgram')
                  : ''
      }
    },
    {
      label: $t('inquiryRecord.username'),
      overHidden: true,
      prop: 'name',
      search: true,
      display: false,
      hide: true,
      placeholder: $t('common.pleaseInput') + $t('inquiryRecord.username'),
      searchSpan: 6,
      searchOrder: 2
    },
    // 编辑病历
    {
      label: $t('inquiryRecord.diagnosis'),
      prop: 'diagnoseInfo',
      hide: true,
      readonly: true,
      span: 24,
      placeholder: $t('common.pleaseInput') + $t('inquiryRecord.diagnosis'),
      type: 'textarea'
    },
    {
      label: $t('inquiryRecord.allergy'),
      prop: 'yp_allergic',
      span: 24,
      hide: true,
      placeholder: $t('common.pleaseInput') + $t('inquiryRecord.allergy')
    },
    {
      label: $t('inquiryRecord.mainSuit'),
      prop: 'main_diagnose',
      hide: true,
      span: 24,
      placeholder: $t('common.pleaseInput') + $t('inquiryRecord.mainSuit')
    },
    {
      label: $t('inquiryRecord.presentCaseHist'),
      prop: 'ill_history',
      hide: true,
      span: 24,
      placeholder:
        $t('common.pleaseInput') + $t('inquiryRecord.presentCaseHist')
    },
    {
      label: $t('inquiryRecord.previousHistory'),
      prop: 'past_history',
      hide: true,
      span: 24,
      placeholder:
        $t('common.pleaseInput') + $t('inquiryRecord.previousHistory')
    }
  ]
}
