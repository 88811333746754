/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export function diffArary(arr1, arr2) {
  arr1 = uniqueArr(arr1)
  arr2 = uniqueArr(arr2)
  return arr1
    .concat(arr2)
    .filter((arg) => !(arr1.includes(arg) && arr2.includes(arg)))
}

/**
 * 防抖
 * @param {string} fn 函数
 * @param {number} delay 等待多少时间（s）后就行函数
 * @param {boolean} immediate 是否立即执行
 * @return {function}
 * @public
 */
export function debounce(fn, delay, immediate) {
  let timer,
    _this = this

  return function () {
    let args = arguments
    if (timer) clearTimeout(timer)

    if (immediate) {
      let callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, delay)
      if (callNow) fn.apply(_this, args)
    } else {
      timer = setTimeout(() => {
        fn.apply(_this, args)
      }, delay)
    }
  }
}
