<template>
  <div class="aide-home-drug">
    <el-empty v-if="!drugDetailList||drugDetailList.length<=0"
              description="暂无药品信息" />
    <div v-else
         class="drug-list">
      <el-tabs v-model="drugActiveObj"
               class="drug-active"
               @tab-click="switchDrug">
        <el-tab-pane v-for="(item, index) in drugDetailList"
                     :key="index"
                     :label="item.labels"
                     :name="index+''" />
      </el-tabs>
      <div class="drug-detail">
        <table>
          <tr>
            <td>
              <div class="drug-img">
                <img :src="drugDetailObj.drugBase? drugDetailObj.drugBase.drugImageUrl: ''"
                     width="80"
                     height="80">
              </div>
            </td>
            <td>
              <div class="drug-desc">
                <div class="desc-title"> {{ drugDetailObj.drugBase? drugDetailObj.drugBase.drugName: '' }}</div>
                <div class="desc-text">通用名：{{ drugDetailObj.drugBase? drugDetailObj.drugBase.commDrug: '' }}</div>
                <div class="desc-text">批准文号：{{ drugDetailObj.drugBase? drugDetailObj.drugBase.approvalNumber: '' }}</div>
                <div class="desc-text">生产企业：{{ drugDetailObj.drugBase? drugDetailObj.drugBase.company: '' }}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="line-left">用法用量</td>
            <td class="line-right">{{ drugDetailObj.drugBase? drugDetailObj.drugBase.usageAndDosage: '' }}</td>
          </tr>
          <tr>
            <td class="line-left">禁忌</td>
            <td class="line-right c-error"
                v-html="drugDetailObj.drugBase ? drugDetailObj.drugBase.taboo : ''" />
          </tr>
          <tr>
            <td class="line-left">参考诊断</td>
            <td class="line-right"
                v-html="drugDetailObj.drugBase? drugDetailObj.drugBase.majorFunction: ''" />
          </tr>
          <tr>
            <td class="line-left">适用疾病</td>
            <td class="line-right">{{ drugDetailObj.drugBase? drugDetailObj.drugBase.majorFunction: '' }}</td>
          </tr>
          <tr>
            <td class="line-left">适用年龄</td>
            <td class="line-right">{{ drugDetailObj.age == '0-120'? '全年龄段': drugDetailObj.age }}</td>
          </tr>
          <tr>
            <td class="line-left">适用性别</td>
            <td class="line-right">{{ drugDetailObj.sex }}</td>
          </tr>
          <tr>
            <td class="line-left">儿童用药</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isDisableChildren]" />
          </tr>
          <tr>
            <td class="line-left">老年用药</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isDisableChildren]" />
          </tr>
          <tr>
            <td class="line-left">肝功能异常</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isLiverDamage]" />
          </tr>
          <tr>
            <td class="line-left">肾功能异常</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isRenalDamage]" />
          </tr>
          <tr>
            <td class="line-left">妊娠孕妇用药</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isDisableWoman]" />
          </tr>
          <tr>
            <td class="line-left">哺乳期用药</td>
            <td class="line-right"
                v-html="shtml[drugDetailObj.isDisableGestational]" />
          </tr>
          <tr>
            <td class="line-left">药物过敏</td>
            <td class="line-right">
              {{
                drugDetailObj.isDisableAllergy == 1
                  ? '本药品任何成分过敏者慎用'
                  : '本药品任何成分过敏者禁用'
              }}
            </td>
          </tr>
          <tr>
            <td class="line-left">不良反应</td>
            <td class="line-right">
              {{ drugDetailObj.adverseReactions }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import socketPublic from '@/socketMsg/socketPublic.js'

export default {
  name: 'DrugInfo',
  data () {
    return {
      drugDetailList: [],
      drugDetailObj: {},
      drugActiveObj: '0',
      shtml: [
        '可用',
        '<b style="color:#fd9c12">慎用</b>',
        '<b style="color:#e20000">禁用</b>',
        '<b style="color:#fd9c12">慎用</b>'
      ]
    }
  },
  computed: {
    checkDrugData () {
      return socketPublic.state.checkDrugData
    }
  },
  watch: {
    checkDrugData: {
      handler (newVal, oldVal) {
        console.log(newVal)
        if (!newVal || !newVal.data || newVal.data.length <= 0) {
          this.clearDetect()
          return
        }
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.clearDetect()
        this.getDrugResult()
      },
      deep: true
    }
  },
  methods: {
    switchDrug (val) {
      const swtichKey = val.index
      this.drugDetailObj = this.drugDetailList[swtichKey]
    },
    clearDetect () {
      this.drugDetailObj = {
        drugBase: {}
      }
      this.drugDetailList = []
    },
    getDrugResult () {
      const checkList = this.checkDrugData.data
      checkList.forEach((item, index) => {
        const { age, sex, drugBase, isDisableChildren, isDisableOld, isDisableWoman, isDisableGestational,
          isLiverDamage, isRenalDamage, isDisableAllergy
        } = item
        const drugKeys = index + 1
        if (drugBase) {
          this.drugDetailList.push({
            labels: '药品' + drugKeys,
            drugBase: drugBase || {},
            sex: sex,
            age: age,
            isDisableChildren: isDisableChildren,
            isDisableOld: isDisableOld,
            isDisableWoman: isDisableWoman,
            isDisableGestational: isDisableGestational,
            isLiverDamage: isLiverDamage,
            isRenalDamage: isRenalDamage,
            isDisableAllergy: isDisableAllergy
          })
        }
      })
      this.drugActiveObj = '0'
      this.drugDetailObj = this.drugDetailList[this.drugActiveObj]
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-home-drug {
  height: 100%;
  .drug-active {
    height: 32px !important;
  }
  /deep/ .el-tabs__header {
    margin-top: 2px;
  }
  /deep/ .el-tabs__item {
    color: #333;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
  }
  /deep/ .el-tabs__item.is-active {
    color: #3b94ff;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #3b94ff;
  }
  /deep/ .el-tabs__nav-wrap::after {
    background: #fff;
  }
  .drug-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    .drug-detail {
      flex: 1;
      font-size: 12px;
      overflow: auto;
      .line-left {
        text-align: right;
        font-weight: 600;
        height: 32px;
        line-height: 32px;
        padding-right: 16px;
        border-top: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
      }
      .line-right {
        height: 32px;
        line-height: 18px;
        text-align: left;
        padding: 12px 12px 12px 18px;
        border-top: 1px solid #d8d8d8;
      }
      .drug-img {
        text-align: right;
        padding: 16px;
      }
      .drug-desc {
        text-align: left;
        font-size: 12px;
        padding-left: 18px;
        .desc-title {
          font-weight: 600;
          margin-bottom: 10px;
        }
        .desc-text {
          margin-bottom: 5px;
        }
      }
      .text-left {
        text-align: right;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        padding-right: 16px;
      }
      .text-right {
        text-align: left;
        height: 32px;
        line-height: 32px;
        padding-left: 18px;
      }
    }
  }
}
</style>
