var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            defaults: _vm.defaults,
            page: _vm.page,
            data: _vm.tableData,
            "table-loading": _vm.tableLoading,
            option: _vm.tableOption,
          },
          on: {
            "update:defaults": function ($event) {
              _vm.defaults = $event
            },
            "update:page": function ($event) {
              _vm.page = $event
            },
            "on-load": _vm.fetchData,
            "search-change": _vm.handleSubmit,
            "row-update": _vm.updateRecord,
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (ref) {
                var row = ref.row
                var index = ref.index
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.crud.rowEdit(row, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inquiryRecord.editCaseHist")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.handleChatHist(row)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inquiryRecord.graphicRecord")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        },
        [
          _c(
            "template",
            { slot: "menuForm" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.crud.rowUpdate()
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.showChatHistory
        ? _c("chatHistory", {
            ref: "chatHistory",
            attrs: { "current-data": _vm.currentData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }