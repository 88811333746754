var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("consultRoom.createRecipe") + " - " + _vm.recipeType,
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "600px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c("div", { staticClass: "temp-title" }, [
        _vm._v("【" + _vm._s(_vm.$t("consultRoom.tempDiagnose")) + "】"),
      ]),
      _c("div", { staticClass: "diagnose-content" }, [
        _vm._v(
          _vm._s(
            _vm.detailData.diagnose_info
              ? _vm.detailData.diagnose_info
              : _vm.detailData.custom_diagnose
          )
        ),
      ]),
      _c("div", { staticClass: "temp-title" }, [
        _vm._v("【" + _vm._s(_vm.$t("consultRoom.tempDrugs")) + "】"),
      ]),
      _vm.recipeType === "西药处方"
        ? _c(
            "div",
            { staticClass: "use-drug-detail" },
            _vm._l(_vm.detailData.drugList, function (item, index) {
              return _c("div", { key: index, staticClass: "drug-list" }, [
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        item.drug_name +
                          " " +
                          item.buy_count +
                          item.packing_unit +
                          " （" +
                          item.specification +
                          "）"
                      )
                  ),
                ]),
                _c("div", [
                  _vm._v(
                    "Sig：" +
                      _vm._s(
                        item.usage +
                          " 每" +
                          item.dosage +
                          item.dosage_unit +
                          " " +
                          item.frequency
                      )
                  ),
                ]),
              ])
            }),
            0
          )
        : [
            _c(
              "div",
              {
                staticClass: "use-drug-detail",
                staticStyle: { "margin-left": "10px" },
              },
              _vm._l(_vm.detailData.drugList, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "chinese-drug-list" },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          index +
                            1 +
                            "、" +
                            item.drug_name +
                            " " +
                            item.dosage +
                            item.dosage_unit
                        )
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "temp-title" }, [
              _vm._v("【" + _vm._s(_vm.$t("consultRoom.takeRequire")) + "】"),
            ]),
            _c("div", { staticClass: "diagnose-content" }, [
              _vm._v(_vm._s(_vm.detailData.remark)),
            ]),
          ],
      _c(
        "div",
        {
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.isCreatRecipe,
                type: "primary",
                size: "small",
              },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("consultRoom.recipeSubmit")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }