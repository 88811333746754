var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "patient-information",
      style: { height: _vm.showMore ? "auto" : "50px" },
    },
    [
      _c(
        "div",
        { staticClass: "patient-content" },
        _vm._l(_vm.patientInfoList, function (item, index) {
          return _c(
            "el-col",
            { key: index, staticClass: "info-item", attrs: { span: 12 } },
            [
              _c("label", [_vm._v(_vm._s(item.label) + "：")]),
              _vm.currentUserId
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        content: _vm.patientInfo[item.key],
                        effect: "dark",
                        placement: "bottom",
                      },
                    },
                    [
                      _c("el-input", {
                        class:
                          _vm.patientInfo[item.key] != "无" ? "input-red" : "",
                        attrs: {
                          placeholder: _vm.$t("common.pleaseEdit") + item.label,
                          size: "mini",
                        },
                        on: {
                          focus: _vm.fetchCurrentInfo,
                          blur: function ($event) {
                            return _vm.changeInfo(
                              $event,
                              item.sendKey,
                              item.key
                            )
                          },
                        },
                        model: {
                          value: _vm.patientInfo[item.key],
                          callback: function ($$v) {
                            _vm.$set(_vm.patientInfo, item.key, $$v)
                          },
                          expression: "patientInfo[item.key]",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }