<template>
  <div class="main-container">
    <div class="line-chart">
      <lineEcharts />
    </div>
    <div class="crud">
      <avue-crud ref="crud"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :option="tableOption"
                 v-model="formData"
                 @on-load="fetchData"
                 @search-change="handleSubmit">
        <template slot-scope="{size,row}"
                  slot="menu">
          <el-button type="text"
                     :size="size"
                     @click="viewDetail(row)">{{$t('dutyStatistic.viewDetail')}}</el-button>
        </template>
      </avue-crud>
    </div>
    <detail v-if="showDetail"
            ref="detail"
            :currentData="currentData"
            :params="paramsSearch" />
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/dutyStatistic'
import { dutyStatisticApi } from '@/api/functionApi'
import { dateFormat } from '@/util/date'
import lineEcharts from './lineEcharts'
import detail from './detail'

export default {
  name: 'dutyStatistic',
  components: {
    lineEcharts,
    detail
  },
  data() {
    return {
      formData: {},
      tableData: [],
      paramsSearch: {
        startTime: dateFormat(
          +new Date() - 30 * 24 * 60 * 60 * 1000,
          'YYYY-MM-DD'
        ),
        endTime: dateFormat(+new Date(), 'YYYY-MM-DD')
      },
      tableLoading: false,
      tableOption,
      showDetail: false
    }
  },
  methods: {
    async fetchData() {
      try {
        this.tableLoading = true
        const res = await dutyStatisticApi.fetchData({
          ...this.paramsSearch
        })
        this.tableData = res.data.data.doctorAttendance
          ? [res.data.data.doctorAttendance]
          : []
        this.tableLoading = false
      } catch (err) {
        console.error(err)
        this.tableLoading = false
      }
    },
    viewDetail(row) {
      this.currentData = row
      this.showDetail = true
      this.$nextTick(() => {
        this.$refs.detail.isVisible = true
      })
    },
    handleSubmit(params, done) {
      ;[params.startTime, params.endTime] = params.date ? params.date : []
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
.main-container {
  padding: 4px;
  background-color: #f9f9f9;
  .crud {
    margin-top: 30px;
    background-color: #fff;
    height: 300px;
    border-radius: 14px;
    padding: 20px;
  }
}
</style>
