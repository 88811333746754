var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("recipeRecord.recipeDetail"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "610px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
        },
        [
          _c("iframe", {
            ref: "Iframe",
            staticStyle: { height: "660px", width: "100%" },
            attrs: { src: _vm.url, frameborder: "0" },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }