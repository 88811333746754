import { translate as $t } from '@/lang'
import moment from 'moment'

export const tableOption = {
  size: 'medium',
  dialogDrag: true,
  align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  searchMenuSpan: 2,
  highlightCurrentRow: true,
  columnBtn: false,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  menuWidth: 80,
  column: [
    {
      label: $t('recipeRecord.username'),
      overHidden: true,
      prop: 'patient_name'
    },
    {
      label: $t('common.sex'),
      overHidden: true,
      prop: 'sex'
    },
    {
      label: $t('recipeRecord.linkPhone'),
      overHidden: true,
      prop: 'patient_phone'
    },
    {
      label: $t('recipeRecord.diagnosis'),
      overHidden: true,
      prop: 'diagnose_info'
    },
    {
      label: $t('recipeRecord.userOrigin'),
      overHidden: true,
      prop: 'dispensary_name'
    },
    {
      label: $t('recipeRecord.channelType'),
      overHidden: true,
      prop: 'doctor_data_source',
      formatter: (obj) => {
        return obj.doctor_data_source == 1
          ? $t('recipeRecord.smallProgramDrugstoreEnd')
          : obj.doctor_data_source == 2
            ? $t('recipeRecord.PCDrugstoreEnd')
            : obj.doctor_data_source == 3
              ? $t('recipeRecord.H5DrugstoreEnd')
              : obj.doctor_data_source == 4
                ? $t('recipeRecord.userEnd')
                : obj.doctor_data_source == 5
                  ? $t('recipeRecord.shopSmallProgram')
                  : ''
      }
    },
    {
      width: 200,
      label: $t('recipeRecord.recipeDate'),
      overHidden: true,
      prop: 'recipe_date',
      type: 'daterange',
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      searchSpan: 6,
      searchOrder: 2,
      clearable: false,
      formatter: (val) => {
        return val.recipe_date
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          return time.getTime() > +new Date() - 24 * 60 * 60 * 1000
          // if (tableOption.timeData) {
          //   var date = new Date(tableOption.timeData) // 只可以选择当月
          //   // 以下最多可选一个月
          //   // const one = 30 * 24 * 3600 * 1000
          //   // const minTime = tableOption.timeData - one
          //   // const maxTime = tableOption.timeData + one
          //   // 这里就是限制的关键，大于或者小于本月的日期被禁用
          //   return (
          //     date.getMonth() > new Date(time).getMonth() ||
          //     date.getMonth() < new Date(time).getMonth() // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
          //     // time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
          //   )
          // } else {
          //   return false
          // }
        }
        // onPick: ({ maxDate, minDate }) => {
        //   tableOption.timeData = minDate.getTime()
        //   if (maxDate) {
        //     // 解除限制
        //     tableOption.timeData = ''
        //   }
        // }
      },
      searchValue: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    },
    {
      searchOrder: 2,
      searchSpan: 2,
      search: true,
      hide: true,
      prop: 'isNow',
      searchLabelWidth: 20
    },
    {
      label: $t('recipeRecord.recipeType'),
      prop: 'recipeType',
      search: true,
      overHidden: true,
      searchSpan: 5,
      searchOrder: 1,
      placeholder: $t('common.pleaseSelect') + $t('recipeRecord.recipeType'),
      type: 'select',
      dicData: [
        {
          label: $t('recipeRecord.westMedicineRecipe'),
          value: 1
        },
        {
          label: $t('recipeRecord.chineseMedicineRecipe'),
          value: 2
        }
      ],
      formatter: (val) => {
        return val.recipe_type
      }
    },
    {
      label: $t('recipeRecord.auditStatus'),
      overHidden: true,
      prop: 'status'
    },
    {
      label: $t('recipeRecord.username'),
      prop: 'keyword',
      search: true,
      hide: true,
      placeholder: $t('common.pleaseInput') + $t('recipeRecord.username'),
      searchSpan: 5,
      searchOrder: 3
    }
  ]
}
