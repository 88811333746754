var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("consultRoom.caseAnalysis"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "800px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "case-analysis" },
        [
          _c("el-image", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.demoImg, "preview-src-list": [_vm.demoImg] },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }