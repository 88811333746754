var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "room-right",
      style: { width: _vm.showUseAssist ? "32%" : "42%" },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.doctorInfo.work_power == 1 || _vm.doctorInfo.work_power == 3
            ? _c(
                "el-tab-pane",
                {
                  staticClass: "pane-visit",
                  attrs: {
                    name: _vm.$t("consultRoom.visitPrescrib"),
                    label: _vm.$t("consultRoom.visitPrescrib"),
                    disabled: !!_vm.currentUserData.clinicType,
                  },
                },
                [
                  _c("visitPrescrib"),
                  _vm.currentUserData.recipeType == 2
                    ? _c(
                        "div",
                        { staticClass: "chinese-tip" },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("consultRoom.drugUse")) +
                                "：" +
                                _vm._s(
                                  _vm.formatter(_vm.currentUserData.drugUseData)
                                )
                            ),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { size: "mini", type: "primary" },
                              on: { click: _vm.editUse },
                            },
                            [_vm._v(_vm._s(_vm.$t("consultRoom.editDrugUse")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.drugShow
                    ? _c("drugTable", {
                        attrs: {
                          showtip: _vm.showtip,
                          "table-list": _vm.currentUserData.drugList,
                          "recipe-type": _vm.currentUserData.recipeType || 1,
                          "active-name": _vm.activeName,
                        },
                        on: { saveStorage: _vm.saveStorage },
                      })
                    : _vm._e(),
                  _vm.currentUserId
                    ? _c(
                        "div",
                        { staticClass: "btn-end" },
                        [
                          _vm.currentUserData.deleteDrugList.length
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "recover",
                                  attrs: { size: "small", type: "success" },
                                  on: { click: _vm.recoverDrug },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("consultRoom.recoverDrug"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.isCreatRecipe || _vm.isDeleteRecipe,
                                size: "small",
                                plain: "",
                                type: "danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("stopInquiry")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("consultRoom.endSession")))]
                          ),
                          _vm.currentUserData.isRecipe
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "danger",
                                  attrs: {
                                    loading: _vm.isDeleteRecipe,
                                    size: "small",
                                    type: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("deleteRecipe")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("consultRoom.deleteRecipe"))
                                  ),
                                ]
                              )
                            : [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      loading: _vm.isCreatRecipe,
                                      disabled:
                                        !!_vm.currentUserData.doctorAdvice &&
                                        !_vm.provisionalRelease,
                                      type:
                                        !!_vm.currentUserData.doctorAdvice &&
                                        !_vm.provisionalRelease
                                          ? "info"
                                          : "primary",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("openRecipe")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("consultRoom.confirmPrescrib")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.doctorInfo.work_power == 2 || _vm.doctorInfo.work_power == 3
            ? _c(
                "el-tab-pane",
                {
                  staticClass: "medical-record",
                  attrs: {
                    name: _vm.$t("consultRoom.medicalRecord"),
                    label: _vm.$t("consultRoom.medicalRecord"),
                    disabled: !_vm.currentUserData.clinicType,
                  },
                },
                [
                  _c("visitPrescrib", {
                    attrs: { "active-type": _vm.activeType },
                  }),
                  _c(
                    "el-tabs",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeType,
                        callback: function ($$v) {
                          _vm.activeType = $$v
                        },
                        expression: "activeType",
                      },
                    },
                    [
                      _vm.doctorInfo.recipe_power == "1" ||
                      _vm.doctorInfo.recipe_power == "0"
                        ? _c("el-tab-pane", {
                            attrs: {
                              name: _vm.$t("consultRoom.westMedicineRecipe"),
                              label: _vm.$t("consultRoom.westMedicineRecipe"),
                            },
                          })
                        : _vm._e(),
                      !_vm.currentUserData.visitType &&
                      (_vm.doctorInfo.recipe_power == "2" ||
                        _vm.doctorInfo.recipe_power == "0")
                        ? _c("el-tab-pane", {
                            attrs: {
                              name: _vm.$t("consultRoom.chineseMedicineRecipe"),
                              label: _vm.$t(
                                "consultRoom.chineseMedicineRecipe"
                              ),
                            },
                          })
                        : _vm._e(),
                      !_vm.currentUserData.visitType &&
                      (_vm.doctorInfo.recipe_power == "1" ||
                        _vm.doctorInfo.recipe_power == "0")
                        ? _c("el-tab-pane", {
                            staticClass: "temp-item",
                            attrs: {
                              name: _vm.$t(
                                "consultRoom.westMedicineRecipeTemp"
                              ),
                              label: _vm.$t(
                                "consultRoom.westMedicineRecipeTemp"
                              ),
                            },
                          })
                        : _vm._e(),
                      !_vm.currentUserData.visitType &&
                      (_vm.doctorInfo.recipe_power == "2" ||
                        _vm.doctorInfo.recipe_power == "0")
                        ? _c("el-tab-pane", {
                            staticClass: "temp-item",
                            attrs: {
                              name: _vm.$t(
                                "consultRoom.chineseMedicineRecipeTemp"
                              ),
                              label: _vm.$t(
                                "consultRoom.chineseMedicineRecipeTemp"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.activeType == _vm.$t("consultRoom.westMedicineRecipe")
                    ? _c("cwDrugTable", {
                        staticStyle: { "margin-top": "0px" },
                        attrs: {
                          "table-list": _vm.currentUserData.westernDrugList,
                          "recipe-type": 1,
                          "active-name": _vm.activeName,
                        },
                        on: { saveStorage: _vm.saveStorage },
                      })
                    : _vm._e(),
                  _vm.activeType == _vm.$t("consultRoom.chineseMedicineRecipe")
                    ? [
                        _c(
                          "div",
                          { staticClass: "chinese-tip" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(_vm.$t("consultRoom.drugUse")) + "："
                              ),
                            ]),
                            _c("el-input", {
                              staticStyle: { width: "60%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "consultRoom.chineseDrugTip"
                                ),
                                size: "small",
                                readonly: "",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.editUse($event)
                                },
                              },
                              model: {
                                value: _vm.currentUserData.drugUseMsg,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.currentUserData,
                                    "drugUseMsg",
                                    $$v
                                  )
                                },
                                expression: "currentUserData.drugUseMsg",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("cwDrugTable", {
                          staticStyle: { "margin-top": "0px" },
                          attrs: {
                            "table-list": _vm.currentUserData.chineseDrugList,
                            "recipe-type": 2,
                            "active-name": _vm.activeName,
                          },
                          on: { saveStorage: _vm.saveStorage },
                        }),
                      ]
                    : _vm._e(),
                  _vm.activeType == _vm.$t("consultRoom.westMedicineRecipeTemp")
                    ? _c("avue-crud", {
                        ref: "westernCrud",
                        staticClass: "crud",
                        staticStyle: { "margin-top": "10px", flex: "1" },
                        attrs: {
                          page: _vm.westernPage,
                          search: _vm.westernSearch,
                          data: _vm.westernTableData,
                          "table-loading": _vm.westernTableLoading,
                          option: _vm.tempTableOption,
                          "header-cell-class-name": function () {
                            return "crud-header"
                          },
                          "row-class-name": function () {
                            return "crud-row"
                          },
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.westernPage = $event
                          },
                          "update:search": function ($event) {
                            _vm.westernSearch = $event
                          },
                          "on-load": function ($event) {
                            return _vm.fetchTempData(1)
                          },
                          "search-change": _vm.handleSubmit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "menu",
                              fn: function (ref) {
                                var size = ref.size
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: size, type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.edit")))]
                                  ),
                                  !_vm.currentUserData.isRecipe
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { size: size, type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUse(row)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("common.use")))]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "keywordSearch",
                              fn: function (ref) {
                                var size = ref.size
                                var row = ref.row
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "input-with-select",
                                      attrs: {
                                        size: size,
                                        placeholder: _vm.$t(
                                          "consultRoom.keywordSearch"
                                        ),
                                      },
                                      model: {
                                        value: row.keyword,
                                        callback: function ($$v) {
                                          _vm.$set(row, "keyword", $$v)
                                        },
                                        expression: "row.keyword",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "130px" },
                                          attrs: { slot: "append" },
                                          slot: "append",
                                          model: {
                                            value: row.scope,
                                            callback: function ($$v) {
                                              _vm.$set(row, "scope", $$v)
                                            },
                                            expression: "row.scope",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "consultRoom.ownTemp"
                                              ),
                                              value: 2,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "consultRoom.commonTemp"
                                              ),
                                              value: 1,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3503315647
                        ),
                      })
                    : _vm._e(),
                  _vm.activeType ==
                  _vm.$t("consultRoom.chineseMedicineRecipeTemp")
                    ? _c("avue-crud", {
                        ref: "chineseCrud",
                        staticClass: "crud",
                        staticStyle: { "margin-top": "10px", flex: "1" },
                        attrs: {
                          page: _vm.chinesePage,
                          search: _vm.chineseSearch,
                          data: _vm.chineseTableData,
                          "table-loading": _vm.chineseTableLoading,
                          option: _vm.tempTableOption,
                          "header-cell-class-name": function () {
                            return "crud-header"
                          },
                          "row-class-name": function () {
                            return "crud-row"
                          },
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.chinesePage = $event
                          },
                          "update:search": function ($event) {
                            _vm.chineseSearch = $event
                          },
                          "on-load": function ($event) {
                            return _vm.fetchTempData(2)
                          },
                          "search-change": _vm.handleSubmit,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "menu",
                              fn: function (ref) {
                                var size = ref.size
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: size, type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.edit")))]
                                  ),
                                  !_vm.currentUserData.isRecipe
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { size: size, type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUse(row)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("common.use")))]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "keywordSearch",
                              fn: function (ref) {
                                var size = ref.size
                                var row = ref.row
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "input-with-select",
                                      attrs: {
                                        size: size,
                                        placeholder: _vm.$t(
                                          "consultRoom.keywordSearch"
                                        ),
                                      },
                                      model: {
                                        value: row.keyword,
                                        callback: function ($$v) {
                                          _vm.$set(row, "keyword", $$v)
                                        },
                                        expression: "row.keyword",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "130px" },
                                          attrs: { slot: "append" },
                                          slot: "append",
                                          model: {
                                            value: row.scope,
                                            callback: function ($$v) {
                                              _vm.$set(row, "scope", $$v)
                                            },
                                            expression: "row.scope",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "consultRoom.ownTemp"
                                              ),
                                              value: 2,
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: _vm.$t(
                                                "consultRoom.commonTemp"
                                              ),
                                              value: 1,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3503315647
                        ),
                      })
                    : _vm._e(),
                  _vm.currentUserId
                    ? _c(
                        "div",
                        { staticClass: "btn-end" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  _vm.isDeleteRecipe || _vm.isCreatRecipe,
                                size: "small",
                                plain: "",
                                type: "danger",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("stopInquiry")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("consultRoom.endSession")))]
                          ),
                          _vm.currentUserData.isRecipe
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "danger",
                                  attrs: {
                                    loading: _vm.isDeleteRecipe,
                                    size: "small",
                                    type: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("deleteRecipe")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("consultRoom.deleteRecipe"))
                                  ),
                                ]
                              )
                            : _vm._e(),
                          !_vm.currentUserData.isRecipe
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: _vm.isCreatRecipe,
                                    disabled:
                                      !!_vm.currentUserData.doctorAdvice &&
                                      !_vm.provisionalRelease,
                                    type:
                                      !!_vm.currentUserData.doctorAdvice &&
                                      !_vm.provisionalRelease
                                        ? "info"
                                        : "primary",
                                    size: "small",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("openRecipe")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("consultRoom.confirmPrescrib")
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.doctorInfo.work_power == 2 || _vm.doctorInfo.work_power == 3
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    name: _vm.$t("consultRoom.historyRecord"),
                    disabled: !_vm.currentUserData.clinicType,
                    label: _vm.$t("consultRoom.historyRecord"),
                  },
                },
                [
                  _c("avue-form", {
                    ref: "historyForm",
                    staticStyle: { "margin-top": "10px" },
                    attrs: { option: _vm.historyOption },
                    on: { submit: _vm.fetchHistoryData },
                    model: {
                      value: _vm.historyData,
                      callback: function ($$v) {
                        _vm.historyData = $$v
                      },
                      expression: "historyData",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "case-information" },
                    _vm._l(_vm.caseList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "case-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "case-left" },
                            [
                              _c(
                                "el-row",
                                { staticClass: "max-weights" },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "overflow-1" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("consultRoom.name")) +
                                              "："
                                          ),
                                        ]),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              content: item.patient_name,
                                              effect: "dark",
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                style: {
                                                  color:
                                                    item.patient_name.length >
                                                      3 ||
                                                    item.patient_name.length ==
                                                      1
                                                      ? "#f00"
                                                      : "#333",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.patient_name)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("common.sex")) + "："
                                        ),
                                      ]),
                                      _c("span", [_vm._v(_vm._s(item.sex))]),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", [
                                      _c("label", [
                                        _vm._v(
                                          _vm._s(_vm.$t("common.age")) + "："
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          style: {
                                            color:
                                              Number(item.patient_age) < 18 ||
                                              Number(item.patient_age) > 60
                                                ? "#f00"
                                                : "#333",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.patient_age
                                                ? item.patient_age
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.caseHistoryList,
                                function (cItem, cIndex) {
                                  return _c(
                                    "div",
                                    { key: cIndex, staticClass: "info-item" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(cItem.label) + "："),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item[cItem.key]
                                              ? item[cItem.key]
                                              : "无"
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "case-right" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.useCase(item)
                                    },
                                  },
                                },
                                [_vm._v("按此病例开方")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showEdit
        ? _c("editChineseDrug", {
            ref: "editChineseDrug",
            attrs: { "current-user-data": _vm.currentUserData },
            on: { editChineseInfo: _vm.editChineseInfo },
          })
        : _vm._e(),
      _vm.showEditTemplate
        ? _c("editTemplate", {
            ref: "editTemplate",
            attrs: {
              "recipe-type": _vm.recipeType,
              "current-data": _vm.currentData,
            },
            on: { onRefresh: _vm.onRefresh },
          })
        : _vm._e(),
      _vm.showCreateRecipe
        ? _c("createRecipe", {
            ref: "createRecipe",
            attrs: {
              "recipe-type": _vm.recipeType,
              "current-data": _vm.currentData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }