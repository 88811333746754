var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aide-home" },
    [
      _c("div", { staticClass: "aide-home-result" }, [
        _vm.checkDone == 0
          ? _c("div", { staticClass: "aide-home-checking" }, [_vm._m(0)])
          : _vm._e(),
        _vm.currentUserData.recipeType == 2
          ? _c("div", { staticClass: "aide-home-done" }, [
              _c("div", { staticClass: "result-top" }, [
                _c("div", { staticClass: "result-check" }, [
                  _c("div", { staticClass: "result-logo" }, [
                    _c("img", {
                      attrs: { src: _vm.unpassSvg, width: "36", height: "36" },
                    }),
                  ]),
                  _c("div", { staticClass: "unpass result-text" }, [
                    _vm._v("中药不支持用药检测"),
                  ]),
                ]),
                _c("div", { staticClass: "button-box" }, [
                  !!_vm.currentUserData.doctorAdvice
                    ? _c(
                        "div",
                        { staticClass: "cxjc", on: { click: _vm.release } },
                        [_vm._v("临时放行")]
                      )
                    : _vm._e(),
                ]),
              ]),
              !!_vm.currentUserData.doctorAdvice
                ? _c("div", { staticClass: "result-done check-title" }, [
                    _vm._v("\n        处方存在以下风险：\n      "),
                  ])
                : _vm._e(),
              !!_vm.currentUserData.doctorAdvice
                ? _c("div", { staticClass: "check-box" }, [
                    _vm._m(1),
                    _vm.checkDone == 1 && _vm.showYellow.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "result-list",
                            staticStyle: { "padding-top": "5px" },
                          },
                          _vm._l(_vm.showYellow, function (item, index) {
                            return _c("div", {
                              key: index,
                              staticClass: "result-warnig",
                              domProps: {
                                innerHTML: _vm._s(index + 1 + "、" + item),
                              },
                            })
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.checkDone == 2 && _vm.currentUserData.recipeType != 2
          ? _c("div", { staticClass: "aide-home-done" }, [
              _c("div", { staticClass: "result-top" }, [
                _c(
                  "div",
                  {
                    staticClass: "result-check",
                    staticStyle: { display: "block" },
                  },
                  [
                    _c("div", { staticClass: "result-logo" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.unpassSvg,
                          width: "36",
                          height: "36",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "unpass result-text" }, [
                      _vm._v("本次检测无法提供结果，请您自行根据病情判断"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "button-box" }, [
                  !!_vm.currentUserData.doctorAdvice
                    ? _c(
                        "div",
                        { staticClass: "cxjc", on: { click: _vm.release } },
                        [_vm._v("临时放行")]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.checkDone == 1 || _vm.currentUserData.clinicType
          ? _c("div", { staticClass: "aide-home-done" }, [
              _c(
                "div",
                {
                  staticClass: "result-top",
                  style: {
                    backgroundColor:
                      _vm.forbidLevel == 1
                        ? "#E7FFEA"
                        : _vm.forbidLevel == 0 || _vm.forbidLevel == 2
                        ? "#FFE5E5"
                        : _vm.forbidLevel == 3
                        ? "#FFEDD3"
                        : "",
                  },
                },
                [
                  _c("div", { staticClass: "result-check" }, [
                    _c("div", { staticClass: "result-logo" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.forbidLevel == 1
                              ? _vm.passSvg
                              : _vm.forbidLevel == 0 || _vm.forbidLevel == 2
                              ? _vm.unpassSvg
                              : _vm.forbidLevel == 3
                              ? _vm.payAttentionSvg
                              : "",
                          width: "36",
                          height: "36",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "result-text",
                        class:
                          _vm.forbidLevel == 1
                            ? "pass"
                            : _vm.forbidLevel == 0 || _vm.forbidLevel == 2
                            ? "unpass"
                            : _vm.forbidLevel == 3
                            ? "c-warning"
                            : "",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.forbidLevel == 1
                                ? "检测通过"
                                : _vm.forbidLevel == 0
                                ? "禁止开方"
                                : _vm.forbidLevel == 2
                                ? "存在风险"
                                : _vm.forbidLevel == 3
                                ? "重点关注"
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "button-box" }, [
                    _vm.showUpdate
                      ? _c(
                          "div",
                          {
                            staticClass: "update-use",
                            on: { click: _vm.updateUse },
                          },
                          [_vm._v("更新用法用量")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "cxjc", on: { click: _vm.checkAgain } },
                      [_vm._v("重新检测")]
                    ),
                    !!_vm.currentUserData.doctorAdvice
                      ? _c(
                          "div",
                          { staticClass: "cxjc", on: { click: _vm.release } },
                          [_vm._v("临时放行")]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm.showError.length > 0 || _vm.showYellow.length > 0
                ? _c("div", { staticClass: "result-done check-title" }, [
                    _vm._v("\n        处方存在以下风险：\n      "),
                  ])
                : _vm._e(),
              _vm.showError.length > 0 || _vm.showYellow.length > 0
                ? _c("div", { staticClass: "check-box" }, [
                    _vm.showError.length > 0
                      ? _c(
                          "div",
                          { staticClass: "result-list" },
                          _vm._l(_vm.showError, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "result-reason" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(index + 1) +
                                    "、" +
                                    _vm._s(item) +
                                    "\n          "
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.showError.length > 0 &&
                    _vm.checkDone == 1 &&
                    _vm.showYellow.length > 0
                      ? _c("div", { staticClass: "line-row" })
                      : _vm._e(),
                    _vm.checkDone == 1 && _vm.showYellow.length > 0
                      ? _c(
                          "div",
                          { staticClass: "result-list" },
                          _vm._l(_vm.showYellow, function (item, index) {
                            return _c("div", {
                              key: index,
                              staticClass: "result-warnig",
                              domProps: {
                                innerHTML: _vm._s(index + 1 + "、" + item),
                              },
                            })
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      (_vm.druguseTip && _vm.druguseTip.length > 0) ||
      (_vm.changeAdvises && _vm.changeAdvises.length > 0) ||
      (_vm.useforbidList && _vm.useforbidList.length > 0) ||
      (_vm.excessList && _vm.excessList.length > 0) ||
      (_vm.outFreList && _vm.outFreList.length > 0) ||
      (_vm.outDoseList && _vm.outDoseList.length > 0) ||
      (_vm.noUseMethodList && _vm.noUseMethodList.length > 0)
        ? _c("div", { staticClass: "aide-home-check" }, [
            _c("div", { staticClass: "aide-title check-title" }, [
              _vm._v("需确认内容"),
            ]),
            _c(
              "div",
              {
                staticClass: "aide-check-body check-box",
                staticStyle: { "padding-top": "0" },
              },
              [
                _vm.changeAdvises && _vm.changeAdvises.length > 0
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass: "aide-check-item no-border",
                          staticStyle: { "padding-bottom": "0" },
                        },
                        _vm._l(_vm.changeAdvises, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "advise-result-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "advise-result-tip clearfix" },
                                [
                                  _vm._v("\n              请问【"),
                                  _c("span", { staticClass: "drug-name" }, [
                                    _vm._v(_vm._s(item.drugNames)),
                                  ]),
                                  _vm._v("】\n              是用于治疗【"),
                                  _c("span", { staticClass: "drug-name" }, [
                                    _vm._v(_vm._s(item.diagnoseNames)),
                                  ]),
                                  _vm._v(
                                    "】吗？如果是请回复【是】\n              "
                                  ),
                                  _c("div", { staticClass: "send-box" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "check-click",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editSend(item)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "check-click",
                                        on: {
                                          click: function ($event) {
                                            return _vm.sendMsg(item.tip)
                                          },
                                        },
                                      },
                                      [_vm._v("发送")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.druguseTip && _vm.druguseTip.length > 0
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "aide-check-item no-border" },
                        _vm._l(_vm.druguseTip, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "advise-result-list" },
                            [
                              _c(
                                "div",
                                { staticClass: "check-item-title" },
                                [
                                  _vm._v("\n              【"),
                                  _c(
                                    "span",
                                    { staticClass: "title-left drug-name" },
                                    [_vm._v(_vm._s(item.commDrug))]
                                  ),
                                  _vm._v("】\n              "),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "4px" },
                                      attrs: {
                                        type: "warning",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.updateUseOne(item)
                                        },
                                      },
                                    },
                                    [_vm._v("更新用法用量")]
                                  ),
                                ],
                                1
                              ),
                              [
                                _c("div", { staticClass: "drug-use-method" }, [
                                  _vm._v(
                                    "\n                适用范围：" +
                                      _vm._s(item.syAge) +
                                      "\n              "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "drug-use-method" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          content:
                                            item.usageMethod +
                                            "，" +
                                            (item.doseValue + item.doseUnit) +
                                            "，" +
                                            item.freqUnit,
                                          effect: "dark",
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "overflow-1" },
                                          [
                                            _vm._v(
                                              "用法用量：" +
                                                _vm._s(item.usageMethod) +
                                                "，" +
                                                _vm._s(
                                                  item.doseValue + item.doseUnit
                                                ) +
                                                "，" +
                                                _vm._s(item.freqUnit)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.useforbidList && _vm.useforbidList.length > 0
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(4),
                      _c(
                        "div",
                        { staticClass: "aide-check-item no-border" },
                        [
                          _vm._l(_vm.useforbidList, function (fItem, fIndex) {
                            return _c("span", { key: fIndex }, [
                              _vm._v("【"),
                              _c("span", { staticClass: "drug-name" }, [
                                _vm._v(_vm._s(fItem.drugName)),
                              ]),
                              _vm._v(
                                "】" +
                                  _vm._s(
                                    fIndex !== _vm.useforbidList.length - 1
                                      ? "、"
                                      : ""
                                  )
                              ),
                            ])
                          }),
                          _vm._v(
                            "\n          用法用量不完善，无法开方，请根据实际情况完善后点击\n          "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "drug-name",
                              staticStyle: { "margin-left": "6px" },
                            },
                            [_vm._v("临时放行")]
                          ),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.excessList && _vm.excessList.length > 0
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(5),
                      _c(
                        "div",
                        { staticClass: "aide-check-item no-border" },
                        _vm._l(_vm.excessList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "advise-result-list" },
                            [
                              _c("div", { staticClass: "excess-limit" }, [
                                _vm._v("\n              【"),
                                _c("span", { staticClass: "drug-name" }, [
                                  _vm._v(_vm._s(item.comName)),
                                ]),
                                _vm._v(
                                  "】超过规定使用量，当前限购数量为" +
                                    _vm._s(item.maxBuyCount) +
                                    "件" +
                                    _vm._s(
                                      item.maxUseDay
                                        ? "（用药天数为" +
                                            item.maxUseDay +
                                            "天）"
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "excess-tip clearfix" },
                                [
                                  _c("span", [
                                    _vm._v("您好，按照目前互联网诊疗规范，【"),
                                    _c("span", { staticClass: "drug-name" }, [
                                      _vm._v(_vm._s(item.comName)),
                                    ]),
                                    _vm._v(
                                      "】药品超量了，单方先开" +
                                        _vm._s(item.maxBuyCount) +
                                        "件。"
                                    ),
                                  ]),
                                  _c("div", { staticClass: "send-box" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "check-click",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editSend(item)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "check-click",
                                        on: {
                                          click: function ($event) {
                                            return _vm.sendMsg(
                                              "您好，按照目前互联网诊疗规范，【" +
                                                item.comName +
                                                "】药品超量了，单方先开" +
                                                item.maxBuyCount +
                                                "件。"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("发送")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                (_vm.outFreList && _vm.outFreList.length > 0) ||
                (_vm.outDoseList && _vm.outDoseList.length > 0)
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(6),
                      _c(
                        "div",
                        { staticClass: "aide-check-item no-border" },
                        [
                          _vm._l(_vm.outDoseList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "advise-result-list" },
                              [
                                _c("div", { staticClass: "out-tip" }, [
                                  _vm._v("\n              【"),
                                  _c("span", { staticClass: "drug-name" }, [
                                    _vm._v(_vm._s(item.comName)),
                                  ]),
                                  _vm._v(
                                    "\n              】单次用量超出推荐范围（" +
                                      _vm._s(
                                        item.minLimit && item.maxLimit
                                          ? item.minLimit +
                                              item.unit +
                                              "~" +
                                              (item.maxLimit + item.unit)
                                          : item.minLimit
                                          ? "单次最小用量为" +
                                            (item.minLimit + item.unit)
                                          : item.maxLimit
                                          ? "单次最大用量为" +
                                            (item.maxLimit + item.unit)
                                          : ""
                                      ) +
                                      "），请谨慎开方\n            "
                                  ),
                                ]),
                              ]
                            )
                          }),
                          _vm._l(_vm.outFreList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "advise-result-list" },
                              [
                                _c("div", { staticClass: "out-tip" }, [
                                  _vm._v("\n              【"),
                                  _c("span", { staticClass: "drug-name" }, [
                                    _vm._v(_vm._s(item.comName)),
                                  ]),
                                  _vm._v(
                                    "\n              】用药频次推荐范围（" +
                                      _vm._s(
                                        item.minLimit && item.maxLimit
                                          ? item.unit +
                                              item.minLimit +
                                              "~" +
                                              item.maxLimit +
                                              "次"
                                          : item.minLimit
                                          ? "最小频次为" +
                                            (item.unit + item.minLimit) +
                                            "次"
                                          : item.maxLimit
                                          ? "最大频次为" +
                                            (item.unit + item.maxLimit) +
                                            "次"
                                          : ""
                                      ) +
                                      "），请谨慎开方\n            "
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
                _vm.noUseMethodList && _vm.noUseMethodList.length > 0
                  ? _c("div", { staticClass: "check-item" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "aide-check-item no-border" }, [
                        _c(
                          "div",
                          { staticClass: "advise-result-list" },
                          _vm._l(_vm.noUseMethodList, function (item, index) {
                            return _c("span", { key: index }, [
                              _vm._v("\n              【"),
                              _c("span", { staticClass: "drug-name" }, [
                                _vm._v(_vm._s(item.comName)),
                              ]),
                              _vm._v("】\n            "),
                            ])
                          }),
                          0
                        ),
                        _c("div", { staticClass: "advise-result-list" }, [
                          _vm._v(
                            "该药品的用法用量暂时无法检测，请仔细查阅药品说明书，并根据实际情况进行适当调整"
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm.checkDone == 1 && _vm.showAdvises.length > 0
        ? _c("div", { staticClass: "aide-home-check" }, [
            _c("div", { staticClass: "aide-title check-title" }, [
              _vm._v("建议回复话术"),
            ]),
            _c(
              "div",
              { staticClass: "aide-check-body check-box" },
              _vm._l(_vm.showAdvises, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "advise-result-list",
                    staticStyle: { display: "flex" },
                  },
                  [
                    _vm._v("\n        " + _vm._s(index + 1) + "、\n        "),
                    _c("div", { staticStyle: { flex: "1" } }, [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(item.text) },
                      }),
                      item.recommond
                        ? _c("div", [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(item.recommond) },
                            }),
                            _c("div", { staticClass: "send-box" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "check-click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSend(item)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "check-click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendMsg(item.tip)
                                    },
                                  },
                                },
                                [_vm._v("发送")]
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "send-box" }, [
                            _c(
                              "span",
                              {
                                staticClass: "check-click",
                                on: {
                                  click: function ($event) {
                                    return _vm.editSend(item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "check-click",
                                on: {
                                  click: function ($event) {
                                    return _vm.sendMsg(item.tip)
                                  },
                                },
                              },
                              [_vm._v("发送")]
                            ),
                          ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "aide-home-check" }, [
        _c("div", { staticClass: "aide-title check-title" }, [
          _vm._v("用药人信息"),
        ]),
        _c(
          "div",
          { staticClass: "aide-check-body check-box" },
          [
            _vm.checkDone != 1 || !_vm.currentUserId
              ? _c("el-empty", { attrs: { description: "暂无用药人信息" } })
              : _c(
                  "div",
                  { staticClass: "check-user" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { staticClass: "check-col", attrs: { span: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  content: _vm.patientInfo.user,
                                  effect: "dark",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "check-left overflow-1" },
                                  [
                                    _vm._v(
                                      "姓名：" + _vm._s(_vm.patientInfo.user)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "check-right",
                                class:
                                  _vm.testResults.isUserName == 0
                                    ? "pass"
                                    : "unpass",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.testResults.isUserName == 0
                                        ? "通过"
                                        : "不通过"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { staticClass: "check-col", attrs: { span: 12 } },
                          [
                            _c("div", { staticClass: "check-left" }, [
                              _vm._v("\n              肝功能："),
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.patientInfo.gang == 0 ? "unpass" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.patientInfo.gang == 1
                                        ? "正常"
                                        : _vm.patientInfo.gang == 0
                                        ? "异常"
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm.patientInfo.gang == 0
                              ? _c("div", {
                                  staticClass: "check-right",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.chtml[_vm.testResults.isLiverDamage]
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.patientInfo.gang == 1
                              ? _c("div", {
                                  staticClass: "check-right",
                                  domProps: { innerHTML: _vm._s(_vm.chtml[0]) },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "check-col mgtb6",
                            attrs: { span: 12 },
                          },
                          [
                            _c("div", { staticClass: "check-left" }, [
                              _vm._v("年龄：" + _vm._s(_vm.patientInfo.age)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "check-right",
                                class:
                                  _vm.testResults.isAge == 1
                                    ? "pass"
                                    : "unpass",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.testResults.isAge == 1
                                        ? "通过"
                                        : "不通过"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          {
                            staticClass: "check-col mgtb6",
                            attrs: { span: 12 },
                          },
                          [
                            _c("div", { staticClass: "check-left" }, [
                              _vm._v("\n              肾功能："),
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.patientInfo.shen == 0 ? "unpass" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.patientInfo.shen == 1
                                        ? "正常"
                                        : _vm.patientInfo.shen == 0
                                        ? "异常"
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm.patientInfo.shen == 0
                              ? _c("div", {
                                  staticClass: "check-right",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.chtml[_vm.testResults.isRenalDamage]
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.patientInfo.shen == 1
                              ? _c("div", {
                                  staticClass: "check-right",
                                  domProps: { innerHTML: _vm._s(_vm.chtml[0]) },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "check-col", attrs: { span: 12 } },
                          [
                            _c("div", { staticClass: "check-left" }, [
                              _vm._v("性别：" + _vm._s(_vm.patientInfo.gender)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "check-right",
                                class:
                                  _vm.testResults.isSex == 1
                                    ? "pass"
                                    : "unpass",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.testResults.isSex == 1
                                        ? "通过"
                                        : "不通过"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { staticClass: "check-col", attrs: { span: 12 } },
                          [
                            _c("div", { staticClass: "check-left" }, [
                              _vm._v("\n              孕哺情况："),
                              _c(
                                "span",
                                {
                                  class:
                                    _vm.patientInfo.pregnancy == 1
                                      ? "unpass"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.patientInfo.pregnancy == 1
                                        ? "是"
                                        : _vm.patientInfo.pregnancy == 0
                                        ? "否认"
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "check-right",
                                class:
                                  _vm.patientInfo.pregnancy == 1 &&
                                  (_vm.testResults.isDisableWoman == 2 ||
                                    _vm.testResults.isDisableGestational == 2)
                                    ? "unpass"
                                    : "pass",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.patientInfo.pregnancy == 1 &&
                                        (_vm.testResults.isDisableWoman == 2 ||
                                          _vm.testResults
                                            .isDisableGestational == 2)
                                        ? "不通过"
                                        : "通过"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "aide-home-check" }, [
        _c("div", { staticClass: "aide-title check-title" }, [
          _vm._v("疾病信息"),
        ]),
        _c("div", { staticClass: "aide-check-body check-box" }, [
          _c(
            "div",
            { staticClass: "check-dignose" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "check-col", attrs: { span: 24 } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            content: _vm.dignoseList.join("、"),
                            effect: "dark",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("div", { staticClass: "check-left overflow-1" }, [
                            _vm._v(
                              "诊断：" + _vm._s(_vm.dignoseList.join("、"))
                            ),
                          ]),
                        ]
                      ),
                      _vm.dignoseList.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "check-right",
                              class: _vm.checkDignose == 1 ? "pass" : "unpass",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.checkDignose == 1 ? "通过" : "不通过"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("el-backtop", { attrs: { target: ".aide-home" } }, [
        _c(
          "div",
          { staticClass: "back-top" },
          [
            _c("el-image", { attrs: { src: "/svg/back_to_top.svg" } }),
            _c("div", [_vm._v("返回顶部")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n        风险检测中"),
      _c("span", [_c("i", { staticClass: "el-icon-loading" })]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "result-list" }, [
      _c("div", { staticClass: "result-reason" }, [
        _vm._v(
          "\n            存在医嘱备注，请仔细核对且修改后，点击临时放行开方\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [_vm._v("修改诊断话术")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [
        _vm._v("与推荐的药品用法用量不一致"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [
        _vm._v("药品用法用量不完善"),
      ]),
      _c("div", { staticClass: "new-title-right" }, [
        _c(
          "span",
          { staticClass: "unpass", staticStyle: { "font-weight": "bold" } },
          [_vm._v("风险：禁开")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [_vm._v("药品超量问题")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [
        _vm._v("药品用法用量超出推荐范围"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "new-title" }, [
      _c("span", { staticClass: "weight-bold" }, [
        _vm._v("该药品的用法用量暂时无法检测"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }