<template>
  <div :style="{height:showMore?'auto':'50px'}"
       class="patient-information">
    <div class="patient-content">
      <el-col v-for="(item, index) in patientInfoList"
              :span="12"
              :key="index"
              class="info-item">
        <label>{{ item.label }}：</label>
        <el-tooltip v-if="currentUserId"
                    :content="patientInfo[item.key]"
                    class="item"
                    effect="dark"
                    placement="bottom">
          <el-input v-model="patientInfo[item.key]"
                    :placeholder="$t('common.pleaseEdit') + item.label"
                    :class="patientInfo[item.key]!= '无'?'input-red':''"
                    size="mini"
                    @focus="fetchCurrentInfo"
                    @blur="changeInfo($event, item.sendKey, item.key)" />
        </el-tooltip>
      </el-col>
    </div>
    <!-- <div class="right-icon pointer"
         @click="()=>{showMore=!showMore}">
      <span>{{ showMore?'收起':'展开' }}</span>
      <i :class="showMore?'el-icon-arrow-up':'el-icon-arrow-down'" />
    </div> -->
  </div>
</template>

<script>
import { patientInfoList } from '@/const/crud/consultRoom'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import checkDrug from '@/socketMsg/checkDrug.js'

export default {
  data () {
    return {
      patientInfoList,
      isLoading: false,
      showRed: false,
      previousMsg: '',
      showMore: true,
      patientInfo: {}
    }
  },
  computed: {
    // patientInfo () {
    //   return socketPublic.state.currentUserData.patientInfo
    // },
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    currentUserId () {
      return socketPublic.state.currentUserId
    }
  },
  watch: {
    currentUserData: {
      handler (val) {
        this.patientInfo = this.deepClone(val.patientInfo)
      },
      deep: true
    }
  },
  methods: {
    fetchCurrentInfo (e) {
      this.previousMsg = e.target.value
    },
    // 修改信息
    changeInfo (e, sendKey, key) {
      const currentMsg = e.target.value
      if (this.previousMsg === currentMsg) return
      const modifyData = { key: sendKey, value: currentMsg }
      const currentUserData = this.deepClone(this.currentUserData)
      currentUserData.patientInfo[key] = currentMsg
      inquiry.commonSetCache({
        name: 'currentUserData_' + this.currentUserId,
        content: currentUserData
      })
      inquiry.doctorModify(modifyData, this.currentUserId)
      checkDrug.newCheckDrugs()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1550px) {
  label,
  .tag,
  span,
  .el-input {
    font-size: 12px !important;
  }
}

label {
  font-size: 14px;
  font-weight: 400;
}
.patient-information {
  padding: 4px 10px;
  display: flex;
  overflow: hidden;
  .patient-content {
    flex: 1;
    .info-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      margin-bottom: 12px;
      .el-input {
        flex: 1;
        font-size: 14px;
        /deep/ .el-input__inner {
          border: none;
          padding: 0;
          height: 22px;
          line-height: 22px;
          text-overflow: ellipsis;
        }
      }
      /deep/ .input-red {
        input {
          color: #f00;
        }
      }
    }
  }
  .right-icon {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #3b94ff;
    width: 42px;
    height: 20px;
    text-align: right;
    i {
      margin-left: 4px;
    }
  }
}
</style>
