<template>
  <el-dialog :title="$t('inquiryRecord.graphicChatRecord')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="630px"
             append-to-body
             @close="isVisible = false">
    <div v-loading="isLoading"
         class="chat-history">
      <div class="chat-item">
        <div><label>[{{ $t('inquiryRecord.patient') }}]：</label><span>{{ `${infoData.user}/${infoData.gende}/${infoData.age}` }}</span></div>
        <div><label>[{{ $t('inquiryRecord.illness') }}]：</label><span>{{ infoData.icd }}</span></div>
        <div><label>[{{ $t('inquiryRecord.liverFunction') }}]：</label><span>{{ infoData.gang }}</span></div>
        <div><label>[{{ $t('inquiryRecord.renalFunction') }}]：</label><span>{{ infoData.shen }}</span></div>
        <div><label>[{{ $t('inquiryRecord.pregnancy') }}]：</label><span>{{ infoData.pregnancy }}</span></div>
        <div><label>[{{ $t('inquiryRecord.allergy') }}]：</label><span>{{ infoData.allergyContent }}</span></div>
        <div><label>[{{ $t('inquiryRecord.presentCaseHist') }}]：</label><span>{{ infoData.illContent }}</span></div>
        <div><label>[{{ $t('inquiryRecord.previousHistory') }}]：</label><span>{{ infoData.pastContent }}</span></div>
      </div>
      <div v-for="(item,index) in historyList"
           :class="item.clientType===1?'patient':item.clientType===2?'doctor':item.clientType===3?'without':''"
           :key="index"
           class="history-item">
        <div v-if="item.clientType==3"
             class="top-content"><span class="withdraw-notice">{{ $t('consultRoom.withdrawMsgTip1') }}</span></div>
        <template v-else>
          <div class="img">
            <el-image :src="item.clientType===1?'/img/doctor_default.png':item.clientType===2?'/img/doctor_white.png':''"
                      fit="cover" />
          </div>
          <div v-if="item.medieType==1"
               class="msg">{{ item.msg }}</div>
          <div v-if="item.medieType==2"
               class="msg">
            <el-image :src="item.msg"
                      :preview-src-list="[item.msg]"
                      style="width: 100px;" />
          </div>
        </template>

      </div>
    </div>
  </el-dialog>
</template>

<script>
import { inquiryRecordApi } from '@/api/functionApi'

export default {
  name: 'ChatHistory',
  props: {
    currentData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      isVisible: false,
      isLoading: false,
      infoData: {},
      historyList: []
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showChatHistory) this.$parent.showChatHistory = false
        if (this.$parent.$parent.showChatHistory) { this.$parent.$parent.showChatHistory = false }
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        this.isLoading = true
        const res = await inquiryRecordApi.fetchChatHist({
          applyId: this.currentData.inquiry_id
        })
        const list = res.data.data
        const index = list.findIndex((item) => item.medieType === 3)
        if (index > -1) {
          this.infoData = list[index].msg
          list.splice(index, 1)
        }
        this.historyList = list && list.length > 0 ? list.filter(item => item.medieType != 4) : []
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #f2f2f2;
}
.top-content {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.chat-history {
  padding: 20px 0;
  height: calc(100vh - 12vh - 54px);
  overflow-y: auto;
  .chat-item {
    line-height: 26px;
    padding: 10px 20px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 30px;
  }
  .history-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .img {
      .el-image {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .msg {
      width: fit-content;
      padding: 10px;
      border-radius: 5px;
      background-color: #ffffff;
      margin: 0 20px;
    }
    &.without {
      justify-content: center;
    }
    &.patient {
      flex-direction: row-reverse;
      .msg {
        background-color: #cde6fe;
      }
    }
  }
}
</style>
