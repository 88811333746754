var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("consultRoom.injectReason"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "600px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c("div", { staticStyle: { color: "#f00", "margin-bottom": "10px" } }, [
        _vm._v("*选择的拒方原因仅用于内部记录，不会发送给用户"),
      ]),
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.injectReason,
            callback: function ($$v) {
              _vm.injectReason = $$v
            },
            expression: "injectReason",
          },
        },
        [
          _vm._l(_vm.reasonList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "radio-item" },
              [
                _c("el-radio", { attrs: { label: item.label } }, [
                  _vm._v(_vm._s(item.label)),
                ]),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "radio-item" },
            [_c("el-radio", { attrs: { label: "其他" } }, [_vm._v("其他")])],
            1
          ),
        ],
        2
      ),
      _vm.injectReason == "其他"
        ? _c("el-input", {
            attrs: {
              rows: 2,
              type: "textarea",
              maxlength: "50",
              "show-limit": "",
              placeholder: "请输入拒方原因（限50字以内）",
            },
            model: {
              value: _vm.injectReason2,
              callback: function ($$v) {
                _vm.injectReason2 = $$v
              },
              expression: "injectReason2",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: { "text-align": "center" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  _vm.isVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("common.affirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }