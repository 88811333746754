import { translate as $t } from '@/lang'
import { dateFormat } from '@/util/date'

export const tableOption = {
  dialogDrag: true,
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  // stripe: true,
  align: 'center',
  addBtn: false,
  searchShowBtn: false,
  refreshBtn: false,
  columnBtn: false,
  menu: false,
  searchMenuSpan: 2,
  highlightCurrentRow: true,
  // viewBtn: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  column: [
    {
      label: $t('common.date'),
      prop: 'date',
      type: 'daterange',
      searchSpan: 6,
      searchLabelWidth: 46,
      hide: true,
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              // time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选当前的前一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      },
      searchValue: [
        dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
        dateFormat(+new Date(), 'YYYY-MM-DD')
      ]
    },
    {
      label: $t('preStatistic.doctorName'),
      prop: 'doctorName'
    },
    {
      label: $t('preStatistic.totalPre'),
      prop: 'recipeNum'
    },
    {
      label: $t('preStatistic.imgTextPre'),
      prop: 'textRecipeNum'
    },
    {
      label: $t('preStatistic.videoPre'),
      prop: 'videoRecipeNum'
    },
    {
      label: $t('preStatistic.effictPre'),
      prop: 'recipeNumValid'
    },
    {
      label: $t('preStatistic.cancelPre'),
      prop: 'recipeNumDelete'
    }
  ]
}

// 查询表单
export function formOption (fetchData) {
  return {
    // size: 'mini',
    emptyBtn: false,
    // submitText: $t('common.inquire'),
    submitBtn: false,
    gutter: 20,
    column: [
      {
        prop: 'tjDate',
        type: 'daterange',
        span: 6,
        format: 'yyyy-MM-dd',
        valueFormat: 'yyyy-MM-dd',
        startPlaceholder: $t('common.startTime'),
        endPlaceholder: $t('common.endTime'),
        change: ({ column, value }) => {
          setTimeout(() => {
            fetchData(false)
          }, 100)
        }
      },
      {
        prop: 'tjType',
        type: 'radio',
        span: 4,
        dicData: [
          {
            label: $t('preStatistic.nearSevenDay'),
            value: 2
          },
          {
            label: $t('preStatistic.nearThirtyDay'),
            value: 3
          },
          {
            label: $t('preStatistic.allYear'),
            value: 4
          }
        ],
        change: ({ column, value }) => {
          setTimeout(() => {
            fetchData(false)
          }, 100)
        }
      }
    ]
  }
}
