import { translate as $t } from '@/lang'
import { dateFormat } from '@/util/date'

export const tableOption = {
  size: 'medium',
  dialogDrag: true,
  align: 'center',
  searchBtnText: $t('common.inquire'),
  searchBtnIcon: ' ',
  addBtn: false,
  searchShowBtn: false,
  columnBtn: false,
  refreshBtn: false,
  searchMenuSpan: 2,
  highlightCurrentRow: true,
  emptyBtn: false,
  delBtn: false,
  editBtn: false,
  menu: false,
  column: [
    {
      label: $t('serviceEvalua.username'),
      overHidden: true,
      prop: 'user_name'
    },
    {
      label: $t('serviceEvalua.linkPhone'),
      overHidden: true,
      prop: 'user_phone'
    },
    {
      label: $t('serviceEvalua.evaluaScore'),
      overHidden: true,
      prop: 'score_name'
    },
    {
      label: $t('serviceEvalua.evaluaTag'),
      overHidden: true,
      prop: 'tags'
    },
    {
      label: $t('serviceEvalua.evaluaContent'),
      overHidden: true,
      prop: 'content'
    },
    {
      width: 200,
      label: $t('serviceEvalua.evaluaDate'),
      overHidden: true,
      prop: 'date',
      type: 'daterange',
      search: true,
      searchRange: true,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      startPlaceholder: $t('common.startTime'),
      endPlaceholder: $t('common.endTime'),
      searchSpan: 7,
      searchOrder: 2,
      formatter: (val) => {
        return val.create_time
      },
      pickerOptions: {
        disabledDate: (time) => {
          // 是否限制的判断条件
          if (tableOption.timeData) {
            // var date = new Date(this.timeData) // 只可以选择当月
            // 以下最多可选一个月
            const one = 30 * 24 * 3600 * 1000
            const minTime = tableOption.timeData - one
            const maxTime = tableOption.timeData + one
            // 这里就是限制的关键，大于或者小于本月的日期被禁用
            return (
              // date.getMonth() > new Date(time).getMonth() ||
              // date.getMonth() < new Date(time).getMonth()  // 只可以选择当月，比如只能选8月，9月，10月，反正就是不能跨月选择范围
              time.getTime() < minTime || time.getTime() > maxTime // 最多可选一个月
            )
          } else {
            return false
          }
        },
        onPick: ({ maxDate, minDate }) => {
          tableOption.timeData = minDate.getTime()
          if (maxDate) {
            // 解除限制
            tableOption.timeData = ''
          }
        }
      },
      searchValue: [
        dateFormat(+new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
        dateFormat(+new Date(), 'YYYY-MM-DD')
      ]
    },
    {
      label: $t('serviceEvalua.showStatus'),
      prop: 'status',
      overHidden: true,
      search: true,
      clearable: false,
      type: 'select',
      clearable: true,
      placeholder: $t('common.pleaseSelect'),
      searchSpan: 4,
      searchOrder: 1,
      dicData: [
        {
          label: $t('serviceEvalua.show'),
          value: 0
        },
        {
          label: $t('serviceEvalua.noShow'),
          value: 1
        }
      ],
      formatter: (val) => {
        return val.status_desc
      }
    },
    {
      label: $t('serviceEvalua.keyword'),
      prop: 'keyword',
      hide: true,
      placeholder: $t('common.pleaseInput'),
      search: true,
      searchLabelWidth: 60,
      searchSpan: 4,
      searchOrder: 3
    }
  ]
}
