<template>
  <el-dialog :title="$t('consultRoom.onlineDoctor')"
             :visible.sync="isVisible"
             :close-on-click-modal="false"
             top="6vh"
             width="630px"
             append-to-body
             @close="isVisible = false">
    <div class="online-doctor">
      <template v-if="doctorList.length > 0">
        <div v-for="(item, index) in doctorList"
             :key="index"
             class="doctor-item">
          <div class="item-header">
            <el-image :src="item.doctorImg || '/img/doctor_default.png'"
                      class="doctor-image"
                      fit="cover" />
            <el-image :src="item.status ? '/img/exit.png' : '/img/online.png'"
                      class="online" />
            <el-image v-if="item.highRisk"
                      title="高风险药品转诊"
                      src="/img/high_risk.png"
                      class="high-risk" />
            <div class="doctor-info">
              <div class="doctor-content">
                <div class="doctor-name">{{ item.doctorName }}</div>
                <div :class="{profession:item.profession == '主任医师' ||item.profession == '副主任医师' ||item.profession == '主治医师'}"
                     class="doctor-position">
                  {{ item.profession }}
                </div>
                <div class="doctor-type">{{ item.deptName }}</div>
                <div v-if="item.onlyRefer"
                     class="only-tip">只接转诊</div>
              </div>
              <div class="doctor-belong">{{ item.hospitalNname }}</div>
            </div>
          </div>
          <div class="item-btn">
            <el-button v-if="item.text"
                       round
                       plain
                       size="mini"
                       @click="changeDoctor(item, 1)">{{ $t('consultRoom.changeText') }}</el-button>
            <el-button v-if="item.video && !item.onlyRefer"
                       round
                       plain
                       size="mini"
                       @click="changeDoctor(item, 2)">{{ $t('consultRoom.changeVideo') }}</el-button>
          </div>
          <div class="line-up line1">
            {{ $t('consultRoom.lineUpNum') }}：<span>{{ item.queueNum }}</span>{{ $t('consultRoom.people') }}
          </div>
          <!-- <div class="line-up line2">{{ $t('consultRoom.videoLineUpNum') }}：<span>{{ item.videoQueueNum }}</span>{{ $t('consultRoom.people') }}</div> -->
          <!-- <div class="line-up line3">{{ $t('consultRoom.textLineUpNum') }}：<span>{{ item.textQueueNum }}</span>{{ $t('consultRoom.people') }}</div> -->
        </div>
      </template>
      <el-empty v-else
                :description="$t('consultRoom.noDocTip')"
                style="height: 100%" />
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { consultRoomApi } from '@/api/consultRoomApi'
import inquiry from '@/socketMsg/inquiry.js'
import socketPublic from '@/socketMsg/socketPublic.js'

export default {
  name: 'ChangeDoctor',
  data () {
    return {
      isVisible: false,
      isLoading: false,
      doctorList: [],
      currentUserId: 0
    }
  },
  computed: {
    ...mapGetters(['doctorInfo']),
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    autoAccept () {
      return socketPublic.state.autoAccept
    },
    inquiryList () {
      return socketPublic.state.inquiryList
    }
  },
  watch: {
    isVisible (val) {
      if (!val) {
        if (this.$parent.showChangeDoctor) this.$parent.showChangeDoctor = false
        if (this.$parent.$parent.showChangeDoctor) {
          this.$parent.$parent.showChangeDoctor = false
        }
      } else {
        this.currentUserId = socketPublic.state.currentUserId
        this.fetchDoctor()
      }
    }
  },
  methods: {
    async fetchDoctor () {
      try {
        const res = await consultRoomApi.fetchDoctorList({
          work_power: this.currentUserData.clinicType ? 2 : 1,
          company_code: this.currentUserData.companyCode
        })
        this.doctorList = res.data.data
      } catch (err) {
        console.error(err)
      }
    },
    async changeDoctor (item, inquiryMode) {
      try {
        await this.$confirm(
          this.$t('consultRoom.changeTip'),
          this.$t('common.tip'),
          {
            confirmButtonText: this.$t('common.confirm'),
            cancelButtonText: this.$t('common.cancel'),
            type: 'warning'
          }
        )
        const currentUserId = socketPublic.state.currentUserId
        if (this.currentUserId === currentUserId) {
          this.changeSessionEnd(item, currentUserId, inquiryMode)
          // this.$message.success(this.$t('consultRoom.changeTip1'))
        } else {
          this.$message.error('当前问诊已经结束，请重新操作')
        }
        this.isVisible = false
      } catch (err) {
        console.error(err)
      }
    },
    // 会话置底
    changeSessionEnd (obj, currentUserId, inquiryMode) {
      const index = this.inquiryList.findIndex(
        (item) => item.userId == this.currentUserId
      )
      if (index > -1) {
        const item = this.inquiryList.splice(index, 1)[0]
        item.isPush = true
        this.inquiryList.push(item)
        this.currentUserData.isPush = true
        inquiry.commonSetCache({
          name: 'currentUserData_' + this.currentUserId,
          content: this.currentUserData
        })

        inquiry.commonSetCache({
          name: 'inquiryList',
          content: this.inquiryList
        })

        if (this.autoAccept) {
          const obj = this.inquiryList.find((item) => item.inquiryMode == 2)
          if (obj && this.inquiryList.length > 1) {
            setTimeout(() => {
              inquiry.startVisit(obj)
            }, 500)
          }
        }
        setTimeout(() => {
          inquiry.referralVisit(obj.doctorId, currentUserId, inquiryMode)
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-bottom: 10px;
}

.online-doctor {
  height: calc(100vh - 210px);
  overflow-y: auto;
  .doctor-item {
    position: relative;
    height: 140px;
    background-color: #f3f8ff;
    border-radius: 8px;
    margin-bottom: 10px;

    .item-header {
      padding-left: 10px;
      padding-top: 25px;
      position: relative;
      display: flex;
      .doctor-image {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-right: 20px;
      }
      .online {
        position: absolute;
        left: 60px;
        top: 60px;
      }
      .high-risk {
        position: absolute;
        top: 0;
        left: 10px;
      }

      .doctor-info {
        flex: 1;
        height: 70px;
        border-bottom: 1px solid #eae8e8;
        .doctor-content {
          display: flex;
          align-items: center;
          padding: 5px 0;
          .doctor-name {
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
          }
          .doctor-position,
          .doctor-type {
            font-size: 12px;
            color: #999999;
            margin-right: 10px;
          }
          .profession {
            padding: 2px 6px;
            border: 1px solid rgba(253, 156, 18, 1);
            border-radius: 4px;
            color: rgba(253, 156, 18, 1);
          }
          .only-tip {
            font-size: 12px;
            padding: 2px 6px;
            border: 1px solid #11c529;
            border-radius: 4px;
            color: #11c529;
            margin-right: 10px;
            &.red {
              border-color: #f00;
              color: #f00;
            }
          }
        }
        .doctor-belong {
          font-size: 14px;
        }
      }
    }
    .item-btn {
      text-align: right;
      padding: 10px;
      .el-button {
        color: #3b94ff;
        border: 1px solid #3b94ff;
        &:hover {
          color: #fff;
          background-color: #3b94ff;
        }
      }
    }
    .line-up {
      position: absolute;
      right: 0;
      font-size: 10px;
      border-radius: 10px 0 0 10px;
      height: 20px;
      line-height: 20px;
      background-color: #fff;
      padding: 0 10px;
      span {
        color: #f00;
      }
      &.line1 {
        top: 10px;
      }
      &.line2 {
        top: 35px;
      }
      &.line3 {
        top: 60px;
      }
    }
  }
}
</style>
