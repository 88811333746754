var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drug-use-assistant animate__animated animate__fadeOutRight",
      style: {
        width: _vm.showUseAssist ? "29%" : "40px",
        minWidth: _vm.showUseAssist ? "350px" : "auto",
      },
    },
    [
      _c(
        "div",
        { staticClass: "assistant-title" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                content: _vm.showUseAssist ? "收起助手(Esc)" : "展开助手(F4)",
                manual: _vm.showTip,
                effect: "dark",
                placement: "left",
              },
              model: {
                value: _vm.showTip,
                callback: function ($$v) {
                  _vm.showTip = $$v
                },
                expression: "showTip",
              },
            },
            [
              _c("el-image", {
                attrs: {
                  src: _vm.showUseAssist
                    ? "/svg/packUp.svg"
                    : "/svg/unfold.svg",
                  fit: "cover",
                },
                on: {
                  click: function ($event) {
                    return _vm.changeUseAssist(!_vm.showUseAssist)
                  },
                  mouseout: function () {
                    _vm.showTip = false
                  },
                },
              }),
            ],
            1
          ),
          _vm.showUseAssist
            ? [
                _c("el-image", {
                  staticStyle: { margin: "0 10px" },
                  attrs: { src: "/img/yy_logo.png", fit: "cover" },
                }),
                _c("span", [_vm._v("银叶合理用药助手")]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showUseAssist,
              expression: "showUseAssist",
            },
          ],
          staticClass: "assistant-content ",
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "用药检测", name: "first" } },
                [
                  _c("drugTest", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkSuccess,
                        expression: "checkSuccess",
                      },
                    ],
                    ref: "drugTestRef",
                  }),
                  _c("testFail", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.checkSuccess,
                        expression: "!checkSuccess",
                      },
                    ],
                    ref: "testFailRef",
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticStyle: { height: "100%" },
                  attrs: { label: "处方用药", name: "second" },
                },
                [_c("drugInfo", { ref: "drugInfo" })],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "辅助功能", name: "fourth" } },
                [_c("auxiliaryFunc", { ref: "auxiliaryFuncRef" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }