var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("div", { staticClass: "line-chart" }, [_c("lineEcharts")], 1),
      _c(
        "div",
        { staticClass: "crud" },
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              data: _vm.tableData,
              "table-loading": _vm.tableLoading,
              option: _vm.tableOption,
            },
            on: { "on-load": _vm.fetchData, "search-change": _vm.handleSubmit },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function (ref) {
                  var size = ref.size
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: size },
                        on: {
                          click: function ($event) {
                            return _vm.viewDetail(row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("dutyStatistic.viewDetail")))]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          }),
        ],
        1
      ),
      _vm.showDetail
        ? _c("detail", {
            ref: "detail",
            attrs: { currentData: _vm.currentData, params: _vm.paramsSearch },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }