var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-container" }, [
    _c("div", { staticClass: "line-chart" }, [_c("lineEcharts")], 1),
    _c(
      "div",
      { staticClass: "crud" },
      [
        _c("avue-crud", {
          ref: "crud",
          attrs: {
            data: _vm.tableData,
            "table-loading": _vm.tableLoading,
            option: _vm.tableOption,
          },
          on: { "on-load": _vm.fetchData, "search-change": _vm.handleSubmit },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }