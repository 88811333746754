var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("consultRoom.onlineDoctor"),
        visible: _vm.isVisible,
        "close-on-click-modal": false,
        top: "6vh",
        width: "630px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isVisible = $event
        },
        close: function ($event) {
          _vm.isVisible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "online-doctor" },
        [
          _vm.doctorList.length > 0
            ? _vm._l(_vm.doctorList, function (item, index) {
                return _c("div", { key: index, staticClass: "doctor-item" }, [
                  _c(
                    "div",
                    { staticClass: "item-header" },
                    [
                      _c("el-image", {
                        staticClass: "doctor-image",
                        attrs: {
                          src: item.doctorImg || "/img/doctor_default.png",
                          fit: "cover",
                        },
                      }),
                      _c("el-image", {
                        staticClass: "online",
                        attrs: {
                          src: item.status
                            ? "/img/exit.png"
                            : "/img/online.png",
                        },
                      }),
                      item.highRisk
                        ? _c("el-image", {
                            staticClass: "high-risk",
                            attrs: {
                              title: "高风险药品转诊",
                              src: "/img/high_risk.png",
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "doctor-info" }, [
                        _c("div", { staticClass: "doctor-content" }, [
                          _c("div", { staticClass: "doctor-name" }, [
                            _vm._v(_vm._s(item.doctorName)),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "doctor-position",
                              class: {
                                profession:
                                  item.profession == "主任医师" ||
                                  item.profession == "副主任医师" ||
                                  item.profession == "主治医师",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.profession) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "doctor-type" }, [
                            _vm._v(_vm._s(item.deptName)),
                          ]),
                          item.onlyRefer
                            ? _c("div", { staticClass: "only-tip" }, [
                                _vm._v("只接转诊"),
                              ])
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "doctor-belong" }, [
                          _vm._v(_vm._s(item.hospitalNname)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item-btn" },
                    [
                      item.text
                        ? _c(
                            "el-button",
                            {
                              attrs: { round: "", plain: "", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeDoctor(item, 1)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("consultRoom.changeText")))]
                          )
                        : _vm._e(),
                      item.video && !item.onlyRefer
                        ? _c(
                            "el-button",
                            {
                              attrs: { round: "", plain: "", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeDoctor(item, 2)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("consultRoom.changeVideo")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "line-up line1" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("consultRoom.lineUpNum")) +
                        "："
                    ),
                    _c("span", [_vm._v(_vm._s(item.queueNum))]),
                    _vm._v(_vm._s(_vm.$t("consultRoom.people")) + "\n        "),
                  ]),
                ])
              })
            : _c("el-empty", {
                staticStyle: { height: "100%" },
                attrs: { description: _vm.$t("consultRoom.noDocTip") },
              }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }