var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "line-echarts" }, [
    _c(
      "div",
      { staticClass: "line-echarts-search" },
      [
        _c("div", { staticClass: "line-echarts-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("dutyStatistic.dutyStatistic")) +
              "\n    "
          ),
        ]),
        _c("avue-form", {
          ref: "aVueForm",
          attrs: { option: _vm.formOption },
          on: { submit: _vm.handleSubmit },
          model: {
            value: _vm.formData,
            callback: function ($$v) {
              _vm.formData = $$v
            },
            expression: "formData",
          },
        }),
      ],
      1
    ),
    _c("div", { ref: "lineEcharts", staticStyle: { height: "320px" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }