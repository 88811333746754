var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aide-set", style: { height: _vm.screenHeight + "px" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "first-set" }, [
        _c("div", [_vm._v("自动接诊：")]),
        _c(
          "div",
          [
            _c("el-switch", {
              attrs: { "active-value": "1", "inactive-value": "0" },
              on: { change: _vm.setAutoReceive },
              model: {
                value: _vm.autoReceive,
                callback: function ($$v) {
                  _vm.autoReceive = $$v
                },
                expression: "autoReceive",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "first-set" }, [
        _c("div", [_vm._v("一键开方：")]),
        _c(
          "div",
          [
            _c("el-switch", {
              attrs: { "active-value": "1", "inactive-value": "0" },
              on: { change: _vm.setSquareRoot },
              model: {
                value: _vm.squareRoot,
                callback: function ($$v) {
                  _vm.squareRoot = $$v
                },
                expression: "squareRoot",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "setNotice" }, [
        _vm._v("注意：请确保您有足够的熟练度后再开启一键开方"),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "short-key" }, [
      _c("span", { staticClass: "key-title" }, [_vm._v("快捷键提示：")]),
      _c("div", [_vm._v("Esc：打开/收起用药助手")]),
      _c("div", [
        _vm._v("ALT：开方,确认层未出现时，打开确认层；确认层出现时，确认开方"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }