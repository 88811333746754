<template>
  <div class="aide-home">
    <div class="aide-home-result">
      <div v-if="checkDone == 0"
           class="aide-home-checking">
        <div>
          风险检测中<span><i class="el-icon-loading" /></span>
        </div>
      </div>
      <div v-if="currentUserData.recipeType==2"
           class="aide-home-done">
        <div class="result-top">
          <div class="result-check">
            <div class="result-logo">
              <img :src="unpassSvg"
                   width="36"
                   height="36">
            </div>
            <div class="unpass result-text">中药不支持用药检测</div>
          </div>
          <div class="button-box">
            <div v-if="!!currentUserData.doctorAdvice"
                 class="cxjc"
                 @click="release">临时放行</div>
          </div>
        </div>
        <div v-if="!!currentUserData.doctorAdvice"
             class="result-done check-title">
          处方存在以下风险：
        </div>
        <div v-if="!!currentUserData.doctorAdvice"
             class="check-box">
          <div class="result-list">
            <div class="result-reason">
              存在医嘱备注，请仔细核对且修改后，点击临时放行开方
            </div>
          </div>
          <div v-if="checkDone == 1 && showYellow.length > 0"
               class="result-list"
               style="padding-top: 5px;">
            <div v-for="(item, index) in showYellow"
                 :key="index"
                 class="result-warnig"
                 v-html="index + 1 + '、' + item" />
          </div>
        </div>
      </div>
      <div v-if="checkDone == 2&&currentUserData.recipeType!=2"
           class="aide-home-done">
        <div class="result-top">
          <div class="result-check"
               style="display: block;">
            <div class="result-logo">
              <img :src="unpassSvg"
                   width="36"
                   height="36">
            </div>
            <div class="unpass result-text">本次检测无法提供结果，请您自行根据病情判断</div>
          </div>
          <div class="button-box">
            <div v-if="!!currentUserData.doctorAdvice"
                 class="cxjc"
                 @click="release">临时放行</div>
          </div>
        </div>
      </div>
      <div v-if="checkDone == 1||currentUserData.clinicType"
           class="aide-home-done">
        <div :style="{backgroundColor: forbidLevel == 1?'#E7FFEA':forbidLevel == 0 || forbidLevel == 2?'#FFE5E5':forbidLevel == 3?'#FFEDD3':''}"
             class="result-top">
          <div class="result-check">
            <div class="result-logo">
              <img :src="forbidLevel == 1?passSvg:forbidLevel == 0 || forbidLevel == 2?unpassSvg:forbidLevel == 3?payAttentionSvg:''"
                   width="36"
                   height="36">
            </div>
            <div :class="forbidLevel == 1?'pass':forbidLevel == 0 || forbidLevel == 2?'unpass':forbidLevel == 3?'c-warning':''"
                 class="result-text">
              {{ forbidLevel == 1?'检测通过':forbidLevel == 0 ?'禁止开方':forbidLevel == 2 ?'存在风险':forbidLevel == 3?'重点关注':'' }}
            </div>
          </div>
          <div class="button-box">
            <div v-if="showUpdate"
                 class="update-use"
                 @click="updateUse">更新用法用量</div>
            <div class="cxjc"
                 @click="checkAgain">重新检测</div>
            <div v-if="!!currentUserData.doctorAdvice"
                 class="cxjc"
                 @click="release">临时放行</div>
          </div>
        </div>
        <div v-if="showError.length > 0||showYellow.length > 0"
             class="result-done check-title">
          处方存在以下风险：
        </div>
        <div v-if="showError.length > 0||showYellow.length > 0"
             class="check-box">
          <div v-if="showError.length > 0"
               class="result-list">
            <div v-for="(item, index) in showError"
                 :key="index"
                 class="result-reason">
              {{ index + 1 }}、{{ item }}
            </div>
          </div>
          <div v-if="showError.length > 0&&checkDone == 1 && showYellow.length > 0"
               class="line-row" />
          <!-- <div class="result-done"
             v-if="checkDone == 1 && showYellow.length > 0">
          注意事项：
        </div> -->
          <div v-if="checkDone == 1 && showYellow.length > 0"
               class="result-list">
            <div v-for="(item, index) in showYellow"
                 :key="index"
                 class="result-warnig"
                 v-html="index + 1 + '、' + item" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="druguseTip&&druguseTip.length>0||changeAdvises&&changeAdvises.length > 0||useforbidList&&useforbidList.length>0||excessList&&excessList.length>0||outFreList&&outFreList.length>0 ||outDoseList&&outDoseList.length>0||noUseMethodList&&noUseMethodList.length>0"
         class="aide-home-check">
      <div class="aide-title check-title">需确认内容</div>
      <div class="aide-check-body check-box"
           style="padding-top: 0;">
        <div v-if="changeAdvises&&changeAdvises.length > 0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">修改诊断话术</span>
          </div>
          <div class="aide-check-item no-border"
               style="padding-bottom: 0;">
            <div v-for="(item, index) in changeAdvises"
                 :key="index"
                 class="advise-result-list">
              <div class="advise-result-tip clearfix">
                请问【<span class="drug-name">{{ item.drugNames }}</span>】
                是用于治疗【<span class="drug-name">{{ item.diagnoseNames }}</span>】吗？如果是请回复【是】
                <div class="send-box">
                  <span class="check-click"
                        @click="editSend(item)">编辑</span>
                  <span class="check-click"
                        @click="sendMsg(item.tip)">发送</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="druguseTip&&druguseTip.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">与推荐的药品用法用量不一致</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in druguseTip"
                 :key="index"
                 class="advise-result-list">
              <div class="check-item-title">
                【<span class="title-left drug-name">{{ item.commDrug }}</span>】
                <el-button type="warning"
                           size="mini"
                           plain
                           style="padding: 4px;"
                           @click="updateUseOne(item)">更新用法用量</el-button>
              </div>
              <template>
                <div class="drug-use-method">
                  适用范围：{{ item.syAge }}
                </div>
                <div class="drug-use-method">
                  <el-tooltip :content="`${item.usageMethod}，${item.doseValue + item.doseUnit}，${ item.freqUnit }`"
                              class="item"
                              effect="dark"
                              placement="bottom">
                    <div class="overflow-1">用法用量：{{ item.usageMethod }}，{{ item.doseValue + item.doseUnit }}，{{ item.freqUnit }}</div>
                  </el-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div v-if="useforbidList&&useforbidList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品用法用量不完善</span>
            <div class="new-title-right">
              <span class="unpass"
                    style="font-weight: bold;">风险：禁开</span>
            </div>
          </div>
          <div class="aide-check-item no-border">
            <span v-for="(fItem,fIndex) in useforbidList"
                  :key="fIndex">【<span class="drug-name">{{ fItem.drugName }}</span>】{{ fIndex!==useforbidList.length-1?'、':'' }}</span>
            用法用量不完善，无法开方，请根据实际情况完善后点击
            <span class="drug-name"
                  style="margin-left: 6px;">临时放行</span>
          </div>
        </div>
        <div v-if="excessList&&excessList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品超量问题</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in excessList"
                 :key="index"
                 class="advise-result-list">
              <div class="excess-limit">
                【<span class="drug-name">{{ item.comName }}</span>】超过规定使用量，当前限购数量为{{ item.maxBuyCount }}件{{ item.maxUseDay?`（用药天数为${item.maxUseDay}天）`:'' }}
              </div>
              <div class="excess-tip clearfix">
                <span>您好，按照目前互联网诊疗规范，【<span class="drug-name">{{ item.comName }}</span>】药品超量了，单方先开{{ item.maxBuyCount }}件。</span>
                <div class="send-box">
                  <span class="check-click"
                        @click="editSend(item)">编辑</span>
                  <span class="check-click"
                        @click="sendMsg(`您好，按照目前互联网诊疗规范，【${ item.comName }】药品超量了，单方先开${ item.maxBuyCount }件。`)">发送</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="outFreList&&outFreList.length>0 ||outDoseList&&outDoseList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">药品用法用量超出推荐范围</span>
          </div>
          <div class="aide-check-item no-border">
            <div v-for="(item, index) in outDoseList"
                 :key="index"
                 class="advise-result-list">
              <div class="out-tip">
                【<span class="drug-name">{{ item.comName }}</span>
                】单次用量超出推荐范围（{{ item.minLimit&&item.maxLimit?`${item.minLimit+item.unit}~${item.maxLimit+item.unit}`:item.minLimit?`单次最小用量为${item.minLimit+item.unit}`:item.maxLimit?`单次最大用量为${item.maxLimit+item.unit}`:'' }}），请谨慎开方
              </div>
            </div>
            <div v-for="(item, index) in outFreList"
                 :key="index"
                 class="advise-result-list">
              <div class="out-tip">
                【<span class="drug-name">{{ item.comName }}</span>
                】用药频次推荐范围（{{ item.minLimit&&item.maxLimit?`${item.unit+item.minLimit}~${item.maxLimit}次`:item.minLimit?`最小频次为${item.unit+item.minLimit}次`:item.maxLimit?`最大频次为${item.unit+item.maxLimit}次`:'' }}），请谨慎开方
              </div>
            </div>
          </div>
        </div>
        <div v-if="noUseMethodList&&noUseMethodList.length>0"
             class="check-item">
          <div class="new-title">
            <span class="weight-bold">该药品的用法用量暂时无法检测</span>
          </div>
          <div class="aide-check-item no-border">
            <div class="advise-result-list">
              <span v-for="(item, index) in noUseMethodList"
                    :key="index">
                【<span class="drug-name">{{ item.comName }}</span>】
              </span>
            </div>
            <div class="advise-result-list">该药品的用法用量暂时无法检测，请仔细查阅药品说明书，并根据实际情况进行适当调整</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checkDone == 1 && showAdvises.length > 0"
         class="aide-home-check">
      <div class="aide-title check-title">建议回复话术</div>
      <div class="aide-check-body check-box">
        <div v-for="(item, index) in showAdvises"
             :key="index"
             class="advise-result-list"
             style="display:flex">
          {{ index+1 }}、
          <div style="flex:1;">
            <span v-html="item.text" />
            <div v-if="item.recommond">
              <span v-html="item.recommond" />
              <div class="send-box">
                <span class="check-click"
                      @click="editSend(item)">编辑</span>
                <span class="check-click"
                      @click="sendMsg(item.tip)">发送</span>
              </div>
            </div>
            <div v-else
                 class="send-box">
              <span class="check-click"
                    @click="editSend(item)">编辑</span>
              <span class="check-click"
                    @click="sendMsg(item.tip)">发送</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aide-home-check">
      <div class="aide-title check-title">用药人信息</div>
      <div class="aide-check-body check-box">
        <el-empty v-if="checkDone != 1||!currentUserId"
                  description="暂无用药人信息" />
        <div v-else
             class="check-user">
          <el-row>
            <el-col :span="12"
                    class="check-col">
              <el-tooltip :content="patientInfo.user"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <div class="check-left overflow-1">姓名：{{ patientInfo.user }}</div>
              </el-tooltip>
              <div :class="testResults.isUserName == 0 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isUserName == 0 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">
                肝功能：<span :class="patientInfo.gang == 0 ? 'unpass' : ''">{{ patientInfo.gang==1?'正常':patientInfo.gang==0?'异常':'' }}</span>
              </div>
              <div v-if="patientInfo.gang == 0"
                   class="check-right"
                   v-html="chtml[testResults.isLiverDamage]" />
              <div v-if="patientInfo.gang == 1"
                   class="check-right"
                   v-html="chtml[0]" />
            </el-col>
            <el-col :span="12"
                    class="check-col mgtb6">
              <div class="check-left">年龄：{{ patientInfo.age }}</div>
              <div :class="testResults.isAge == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isAge == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col mgtb6">
              <div class="check-left">
                肾功能：<span :class="patientInfo.shen == 0 ? 'unpass' : ''">{{ patientInfo.shen == 1?'正常':patientInfo.shen==0?'异常':'' }}</span>
              </div>
              <div v-if="patientInfo.shen == 0"
                   class="check-right"
                   v-html="chtml[testResults.isRenalDamage]" />
              <div v-if="patientInfo.shen == 1"
                   class="check-right"
                   v-html="chtml[0]" />
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">性别：{{ patientInfo.gender }}</div>
              <div :class="testResults.isSex == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ testResults.isSex == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
            <el-col :span="12"
                    class="check-col">
              <div class="check-left">
                孕哺情况：<span :class="patientInfo.pregnancy == 1 ? 'unpass' : ''">{{ patientInfo.pregnancy==1?'是':patientInfo.pregnancy==0?'否认':'' }}</span>
              </div>
              <div :class="patientInfo.pregnancy == 1 &&(testResults.isDisableWoman == 2 ||testResults.isDisableGestational == 2)? 'unpass': 'pass'"
                   class="check-right">
                {{ patientInfo.pregnancy == 1 &&(testResults.isDisableWoman == 2 ||testResults.isDisableGestational == 2)? '不通过': '通过' }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="aide-home-check">
      <div class="aide-title check-title">疾病信息</div>
      <div class="aide-check-body check-box">
        <div class="check-dignose">
          <el-row>
            <el-col :span="24"
                    class="check-col">
              <el-tooltip :content="dignoseList.join('、')"
                          class="item"
                          effect="dark"
                          placement="bottom">
                <div class="check-left overflow-1">诊断：{{ dignoseList.join('、') }}</div>
              </el-tooltip>
              <div v-if="dignoseList.length > 0"
                   :class="checkDignose == 1 ? 'pass' : 'unpass'"
                   class="check-right">
                {{ checkDignose == 1 ? '通过' : '不通过' }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-backtop target=".aide-home">
      <div class="back-top">
        <el-image src="/svg/back_to_top.svg" />
        <div>返回顶部</div>
      </div>
    </el-backtop>
  </div>
</template>
<script>
import {
  getDrugUsageForDis
} from './utils/index'
import socketPublic from '@/socketMsg/socketPublic.js'
import inquiry from '@/socketMsg/inquiry.js'
import checkDrug from '@/socketMsg/checkDrug.js'

export default {
  name: 'DrugTest',
  data () {
    return {
      getDrugUsageForDis,
      passSvg: '/svg/pass.svg',
      unpassSvg: '/svg/unpass.svg',
      payAttentionSvg: '/svg/pay_attention.svg',
      arrowUpPng: '/img/arrow_up.png',
      arrowDownPng: '/img/arrow_down.png',
      showUser: false,
      drugActiveObj: '1',
      drugActiveList: [],
      drugDetailList: [],
      druguseTip: [],
      useError: [],
      drugDetailObj: {
        drugBase: {}
      },
      shtml: [
        '可用',
        '<b style="color:#F07503">慎用</b>',
        '<b style="color:#e20000">禁用</b>',
        '<b style="color:#F07503">慎用</b>'
      ],
      chtml: [
        '<span style="color:#66cc66">通过</span>',
        '<span style="color:#F07503">需注意</span>',
        '<span style="color:#e20000">不通过</span>',
        '<span style="color:#F07503">需注意</span>'
      ],
      testResults: {
        isUserName: 0, // 姓名检测 0 正常 1异常
        isSex: 1, // 性别检测 0.不合适 1.合适 2.不确定
        isAge: 1, // 年龄检测  0.不合适 1.合适 2.不确定
        isDiagnoses: [], // 诊断
        isLiverDamage: 0, // 肝功能异常0.可用 1.慎用 2.禁用 3.不确定
        isRenalDamage: 0, // 肾功能异常0.可用 1.慎用 2.禁用 3.不确定
        isDisableChildren: 0, // 儿童用药0.可用 1.慎用 2.禁用
        isDisableOld: 0, // 老年用药0.可用 1.慎用 2.禁用
        isDisableWoman: 0, // 妊娠孕妇用药0.可用 1.慎用 2.禁用
        isDisableGestational: 0, // 哺乳期用药0.可用 1.慎用 2.禁用
        needPrescription: 0 // 处方凭证  0.不需要 1.需要
      },
      checkDone: 2,
      pass: 1,
      checkDignose: 1,
      showError: [],
      showYellow: [],
      showAdvises: [],
      kfhMust: [],
      callPhone: false,
      isyidaosu: false,
      showUpdate: false,
      isjinzhi: [], // 司美格鲁肽注射液禁忌疾病
      showMore: false,
      drinkFlags: [],
      drinkFlags2: [],
      drinkFlags3: [],
      drugUseMethodList: [], // 药品检测后说明书中的药品用法用量信息列表
      zdforbidState: false, // 诊断禁止
      useforbidList: [], // 用法用量缺失药品列表
      useforbidTexts: [], // 用法用量缺失的处理内容
      forbidLevel: 1, // 整体检测结果等级 0 禁止 1 通过 2 有风险，3 需关注
      // qzfangxing: false, // 强制放行
      pauseType: false, // 未在待开方状态
      userInfoPass: false, // 用药人检测通过
      isUserCheck: false,
      isDignoseCheck: false,
      checkTimer: null,
      checkNum: 0,
      outDoseList: [], // 药品用量超出推荐范围
      outFreList: [], // 药品频次超出推荐范围
      excessList: [], // 药品超量
      noUseMethodList: [], // 药品用法用量缺失
      isChild: 0, // 儿童
      isOldPeople: 0 // 老人
    }
  },
  computed: {
    checkDrugData () {
      return socketPublic.state.checkDrugData
    },
    currentUserData () {
      return socketPublic.state.currentUserData
    },
    currentUserId () {
      return socketPublic.state.currentUserId
    },
    patientInfo () {
      return socketPublic.state.currentUserData.patientInfo
    },
    dignoseList () {
      return socketPublic.state.currentUserData.customDiagnose.map(item => item.value)
    },
    changeAdvises () {
      return socketPublic.state.changeAdvises
    },
    activeType () {
      return socketPublic.state.activeTabName2
    }
  },
  watch: {
    currentUserId (val) {
      if (!val) {
        this.clearDetect()
      }
      socketPublic.commit('setProvisionalRelease', false)
      socketPublic.commit('setChangeAdvises', [])
      socketPublic.commit('setIsCheck', false)
      if (socketPublic.state.checkTimer) {
        clearInterval(socketPublic.state.checkTimer)
        socketPublic.commit('setCheckTimer', null)
      }
      socketPublic.commit('setCheckSuccess', true)
    },
    'currentUserData.drugList': {
      handler (newVal, oldVal) {
        this.checkDrugUseMethod(newVal)
      },
      deep: true,
      immediate: true
    },
    checkDrugData: {
      handler (newVal, oldVal) {
        if (!newVal || !newVal.data || newVal.data.length <= 0) {
          this.clearDetect()
          return
        }
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return
        this.clearDetect()
        this.getDrugResult()
        this.getError()
        if (this.currentUserData.clinicType) {
          if (this.activeType === this.$t('consultRoom.westMedicineRecipe')) {
            this.checkDrugUseMethod(this.currentUserData.westernDrugList)
          } else {
            this.checkDrugUseMethod(this.currentUserData.chineseDrugList)
          }
        } else {
          this.checkDrugUseMethod(this.currentUserData.drugList)
        }
      },
      deep: true
    },
    drugActiveList: {
      handler (val) {
        if (val.length > 0) {
          this.drugDetailObj = this.drugActiveList[this.drugActiveObj]
        }
      },
      deep: true // 深度监听
    }
  },
  methods: {
    release () {
      socketPublic.commit('setProvisionalRelease', true)
    },
    checkAgain () {
      checkDrug.newCheckDrugs(true)
    },
    editSend (item) {
      socketPublic.commit('setSendMsg', item.tip)
    },
    // 发送信息
    sendMsg (tip) {
      if (!this.currentUserId) return
      inquiry.inquirySendMsg(tip, this.currentUserId)
    },
    // 判断药品用法用量是否完整
    checkDrugUseMethod (drugList) {
      if (this.currentUserData.recipeType == 2) {
        this.useforbidList = []
        return
      }
      this.useforbidList = []
      // let useforbidTexts = []
      drugList.forEach((item) => {
        if (!item.usage || !item.dosage || !item.dosageUnit || !item.frequencyName) {
          this.useforbidList.push(item)
        }
      })
    },
    updateUseOne (item) {
      getDrugUsageForDis(item)
      setTimeout(() => {
        checkDrug.newCheckDrugs()
      }, 500)
    },
    updateUse () {
      if (this.drugUseMethodList && this.drugUseMethodList.length > 0) {
        this.drugUseMethodList.forEach((item) => {
          getDrugUsageForDis(item)
        })
      } else {
        this.$message.error('药品信息有误，请联系管理员！')
      }
      setTimeout(() => {
        checkDrug.newCheckDrugs()
      }, 500)
    },
    switchDrug (val) {
      const swtichKey = val.index
      this.drugDetailObj = this.drugActiveList[swtichKey]
    },
    // 未接诊情况下，清理页面内容
    clearDetect () {
      this.testResults = {
        isUserName: 0, // 姓名检测 0 正常 1异常
        isSex: 1, // 性别检测 0.不合适 1.合适 2.不确定
        isAge: 1, // 年龄检测  0.不合适 1.合适 2.不确定
        isDiagnoses: [], // 诊断
        isLiverDamage: 0, // 肝功能异常0.可用 1.慎用 2.禁用 3.不确定
        isRenalDamage: 0, // 肾功能异常0.可用 1.慎用 2.禁用 3.不确定
        isDisableChildren: 0, // 儿童用药0.可用 1.慎用 2.禁用
        isDisableOld: 0, // 老年用药0.可用 1.慎用 2.禁用
        isDisableWoman: 0, // 妊娠孕妇用药0.可用 1.慎用 2.禁用
        isDisableGestational: 0, // 哺乳期用药0.可用 1.慎用 2.禁用
        needPrescription: 0 // 处方凭证  0.不需要 1.需要
      }
      this.showUser = false
      this.checkDone = 2
      this.drugDetailObj = {
        drugBase: {}
      }
      this.showYellow = []
      this.showError = []
      this.showUpdate = false
      this.drinkFlags = []
      this.drinkFlags2 = []
      this.drinkFlags3 = []
      this.drugUseMethodList = []
      this.drugActiveList = []
      this.druguseTip = []
      this.userInfoPass = true
      this.excessList = []
      this.outDoseList = []
      this.outFreList = []
      this.noUseMethodList = []
      this.focuses = []
      this.isChild = 0
      this.isOldPeople = 0
    },
    getDrugResult (res) {
      try {
        const checkList = this.checkDrugData.data
        const patientInfo = this.currentUserData.patientInfo
        if (!checkList || !checkList.length) {
          this.$message.warning('没有检测数据')
          this.checkDone = 2
          return // 未检查
        }
        this.drugActiveList = []
        const drugUseMethodList = []
        let drugFrom = {}
        const useError = []
        const druguseTip = []
        let showUpdate = false
        checkList.forEach((item, index) => {
          const { isAge, isSex, isDiagnoses, isUserName, needPrescription, age, sex,
            drugBase, isDisableChildren, isDisableOld, isDisableWoman, isDisableGestational,
            isLiverDamage, isRenalDamage, isDisableAllergy, drinkFlag, drugUseMethod, from,
            maxUseData, doseOverLimitData, dayFreOverLimitData
          } = item
          if (maxUseData) this.excessList.push({ ...maxUseData, ...{ comName: item.comName } })
          if (doseOverLimitData) this.outDoseList.push({ ...doseOverLimitData, ...{ comName: item.comName } })
          if (dayFreOverLimitData) this.outFreList.push({ ...dayFreOverLimitData, ...{ comName: item.comName } })
          drugFrom = from
          if (drugUseMethod) {
            if (!drugUseMethod.usageMethod || !drugUseMethod.doseUnit || !drugUseMethod.doseValue || !drugUseMethod.freqUnit) {
              this.noUseMethodList.push({ comName: drugFrom['drugUseMethodFroms'][index].comName })
            }
            drugUseMethodList.push(drugUseMethod)
            if (drugUseMethod) {
              if (drugUseMethod.usageMethod == '嚼服') {
                drugUseMethod.usageMethod = '咀嚼口服'
              }
            }

            // 判断用法用量是否一致doseValue
            if (drugUseMethod && drugFrom['drugUseMethodFroms'][index] &&
              (drugUseMethod.usageMethod != drugFrom['drugUseMethodFroms'][index].usage || drugUseMethod.doseValue != drugFrom['drugUseMethodFroms'][index].doseValue ||
                drugUseMethod.doseUnit != drugFrom['drugUseMethodFroms'][index].doseUnit || drugUseMethod.freqUnit != drugFrom['drugUseMethodFroms'][index].frequencyUnit)) {
              if (drugUseMethod.doseValue && drugUseMethod.freqUnit) {
                showUpdate = true
                // 建议提醒
                let syAge = ''
                const ageSUnit = drugUseMethod.minAgeUnit == 1 ? '岁' : drugUseMethod.minAgeUnit == 2 ? '月' : ''
                const ageEUnit = drugUseMethod.maxAgeUnit == 1 ? '岁' : drugUseMethod.maxAgeUnit == 2 ? '月' : ''
                if (drugUseMethod.ageS && drugUseMethod.ageE) {
                  syAge = `${drugUseMethod.ageS + ageSUnit} ~ ${drugUseMethod.ageE + ageEUnit}`
                } else if (!drugUseMethod.ageS && !drugUseMethod.ageE) {
                  syAge = '全年龄段'
                } else if (drugUseMethod.ageS && !drugUseMethod.ageE) {
                  syAge = drugUseMethod.ageS + ageSUnit + '以上'
                } else if (!drugUseMethod.ageS && drugUseMethod.ageE) {
                  syAge = drugUseMethod.ageE + ageEUnit + '以下'
                }
                drugUseMethod.syAge = syAge
                // let html = '【<span style="color:#F07503">' + drugUseMethod.commDrug + '</span>】：适用年龄：' + syAge
                // if (drugUseMethod.diagType) {
                //   html += '，' + drugUseMethod.diagType
                // }
                // html += '<br>使用频次：' + drugUseMethod.freqValue + '/' + drugUseMethod.freqUnit + '，使用剂量：' + drugUseMethod.doseValue + drugUseMethod.doseUnit
                // if (drugUseMethod.doseLimitHigh && drugUseMethod.doseLimitHigh > drugUseMethod.doseValue) {
                //   html += '，最大用量：' + drugUseMethod.doseLimitHigh + drugBase ? drugBase.smallestUnit : ''
                // }
                // if (drugUseMethod.usageMethod != drugFrom['drugUseMethodFroms'][index].usage) {
                //   html += '<br>建议用法：【' + drugUseMethod.usageMethod + '】'
                // }
                druguseTip.push(drugUseMethod)
              }
            }
          } else {
            this.noUseMethodList.push({ comName: drugFrom['drugUseMethodFroms'][index].comName })
          }

          // const cls = 'drug-btna'
          if (index == 0) {
            this.testResults.isUserName = isUserName
            this.testResults.isDiagnoses = isDiagnoses
            // cls = 'drug-btna cur';
          }
          if (drinkFlag == 1 && drugBase) {
            this.drinkFlags.push(drugBase.commDrug)
          } else if (drinkFlag == 2 && drugBase) {
            this.drinkFlags2.push(drugBase.commDrug)
          }
          const drugKeys = index + 1
          if (drugBase) {
            this.drugActiveList.push({
              labels: '药品' + drugKeys,
              drugBase: drugBase || {},
              sex: sex,
              age: age,
              isDisableChildren: isDisableChildren,
              isDisableOld: isDisableOld,
              isDisableWoman: isDisableWoman,
              isDisableGestational: isDisableGestational,
              isLiverDamage: isLiverDamage,
              isRenalDamage: isRenalDamage,
              isDisableAllergy: isDisableAllergy
            })
          }

          if (sex.indexOf(patientInfo.gender) === -1 || isSex == 0) {
            this.testResults.isSex = 0
          }
          if (isAge != 1 && this.testResults.isAge != 0) {
            this.testResults.isAge = isAge
          }
          if (needPrescription == 1) {
            this.testResults.needPrescription = needPrescription
          }
          const lis = [
            'isLiverDamage',
            'isRenalDamage',
            'isDisableChildren',
            'isDisableOld',
            'isDisableWoman',
            'isDisableGestational'
          ]
          lis.forEach((key) => {
            if (item[key] != 0 && this.testResults[key] != 2) {
              this.testResults[key] = item[key]
            }
          })
        })
        this.showUpdate = showUpdate
        this.useError = useError
        // if (druguseTip.length > 0) {
        //   const html1 = '处理建议：推荐用法用量与已维护药品不一致，可鼠标悬停药品，核对好用法用量频次，根据实际情况及需要进行调整！'
        //   druguseTip.push(html1)
        // }
        this.druguseTip = druguseTip
        if (drugUseMethodList && drugUseMethodList.length > 0) {
          // 检测完有准确数据，自动更新用法用量
          const drugUseMethodFroms = drugFrom.drugUseMethodFroms && drugFrom.drugUseMethodFroms.length > 0 ? drugFrom.drugUseMethodFroms[0] : {}
          // 判断来源数据，包括剂量，剂量单位，频次，频次数据都为空的时候，才去自动更新
          if ((!drugUseMethodFroms.doseValue || drugUseMethodFroms.doseValue == 0) && (!drugUseMethodFroms.frequencyValve || drugUseMethodFroms.frequencyValve == 0) &&
            !drugUseMethodFroms.doseUnit && !drugUseMethodFroms.frequencyUnit && !drugUseMethodFroms.usage) {
            drugUseMethodList.forEach(function (item) {
              getDrugUsageForDis(item)
            })
            // setTimeout(() => {
            //   getdrugDetect() //用药助手
            // }, 200)
          }
          this.drugUseMethodList = drugUseMethodList
          if (this.useforbidList && this.useforbidList.length > 0) {
            this.useforbidList.forEach(item => {
              const obj = drugUseMethodList.find(fItem => fItem.commDrug == item.drugName)
              if (obj) {
                item.drugCheckMethod = obj[0]
              }
            })
          }
        }

        const messageReminder = checkList && checkList.length > 0 ? checkList[0].messageReminder : []
        if (messageReminder.length > 0) {
          this.pass = 2
        }

        if (patientInfo.gang == 0 && this.testResults.isLiverDamage != 0) {
          if (this.testResults.isLiverDamage == 2) {
            this.pass = 2 // 肝检测不通过
          }
        }
        // const RenalTypehtml = ''
        if (patientInfo.gang == 0 && this.testResults.isRenalDamage != 0) {
          if (this.testResults.isRenalDamage == 2) {
            this.pass = 2 // 检测不通过
          }
        }
        if (patientInfo.pregnancy && patientInfo.pregnancy == 1) {
          if (this.testResults.isDisableWoman == 2) {
            this.pass = 2 // 检测不通过
          }
          if (this.testResults.isDisableGestational == 2) {
            this.pass = 2 // 检测不通过
          }
        }
        if (this.testResults.isSex == 0) {
          this.pass = 2 // 检测不通过
        }
        if (this.testResults.isAge == 0) {
          this.pass = 2 // 检测不通过
        }
        let age = patientInfo.age
        if (age.indexOf('岁') != -1) {
          age = age.split('岁')[0]
        }
        if (age.indexOf('月') != -1) {
          age = 0
        } else {
          if (age.indexOf('天') != -1) {
            age = 0
          }
        }
        this.checkDignose = 1
      } catch (err) {
        this.checkDone = 2
        console.error(err)
      }
    },
    getError () {
      try {
        let drugnames = ''
        const error = []
        const warnings = []
        const advises = []
        const patientInfo = this.currentUserData.patientInfo
        const drugList = this.currentUserData.drugList
        const customDiagnose = this.currentUserData.customDiagnose
        const imageList = this.currentUserData.imageList
        const checkList = this.checkDrugData.data
        let age = patientInfo.age
        // 年龄的判断
        if (age.indexOf('岁') != -1) {
          age = age.split('岁')[0]
        }
        if (age.indexOf('月') != -1) {
          age = 0
        } else {
          if (age.indexOf('天') != -1) {
            age = 0
          }
        }

        if (age <= 14 && age >= 6) {
          // xgptUserInfo('age')
          this.isChild = 1
          warnings.push('<span class="c-warning">儿童，注意用量</span>')
        }

        if (age >= 65) {
          this.isOldPeople = 1
          warnings.push('<span class="c-warning">老人，注意用量</span>')
        }

        if (patientInfo.pregnancy && patientInfo.pregnancy == 1) {
          warnings.push('<span class="c-warning">备孕/怀孕/哺乳期，注意用药</span>')
        }
        // if (patientInfo.allergyContent && patientInfo.allergyContent != '无') {
        //   warnings.push('<span class="c-warning">用户填写信息中有过敏史，请谨慎开方</span>')
        // }

        // 判断药品后-》检查用户年龄信息
        if (imageList.length > 0) {
          warnings.push('<span class="c-warning">用户上传强制凭证,请认真审核。</span>')
        }
        if (this.testResults.isUserName == 1) {
          error.push('名字异常，请重新确认')
        }

        let isNurseTypeTips = false
        let ismanxing = false
        let manxingName = ''
        const isgnqTips = []
        let isjwzz = false
        let jwzzName = ''
        customDiagnose.forEach((item) => {
          if (item.value.indexOf('1型糖尿病') != -1 || item.value.indexOf('胰腺炎') != -1) {
            this.isjinzhi.push(item.value)
          }

          if ((item.value.indexOf('慢性') != -1 && item.value.indexOf('急性发作') == -1) ||
            item.value.indexOf('术后') != -1 || item.value.indexOf('术后伤口感染') != -1 ||
            item.value.indexOf('细菌性感染') != -1 || item.value.indexOf('皮肤感染') != -1) {
            ismanxing = true
            manxingName = item.value
          }

          if (
            item.value.indexOf('消化道出血') != -1 ||
            item.value.indexOf('胃出血') != -1 ||
            item.value.indexOf('肠梗阻') != -1 ||
            item.value.indexOf('肺出血') != -1 ||
            item.value.indexOf('脑出血') != -1 ||
            item.value.indexOf('心肌梗死') != -1 ||
            item.value.indexOf('脑梗塞') != -1
          ) {
            isjwzz = true
            jwzzName = item.value
          }

          if (
            (item.value.indexOf('早产') != -1 ||
              item.value.indexOf('产科') != -1 ||
              item.value.indexOf('先兆流产') != -1 ||
              item.value.indexOf('孕期') != -1 ||
              item.value.indexOf('胎动') != -1 ||
              item.value.indexOf('安胎') != -1 ||
              item.value.indexOf('孕妇') != -1 ||
              (item.value.indexOf('孕') != -1 && item.value.indexOf('周') != -1)) && patientInfo.pregnancy != 1
          ) {
            isNurseTypeTips = true
          }

          if (
            item.value.indexOf('更年期') != -1 && age < 45
          ) {
            isgnqTips.push(item.value)
          }
        })

        if (isNurseTypeTips) {
          const htmlText = '请核实怀孕状态，如确认怀孕请将个人信息是否备孕/怀孕/哺乳期修改为”是”。'
          const htmlText2 = '您现在是怀孕状态吗？'
          advises.push({
            text: htmlText,
            tip: htmlText2,
            recommond: `推荐话术：${htmlText2}`
          })
          error.push(htmlText)

          // 将孕哺情况的文字标红
          // xgptUserInfo('ypqk')
        }

        if (isgnqTips && isgnqTips.length > 0) {
          const isgnqTipstr = isgnqTips.join(',')
          const htmlText = `诊断【${isgnqTipstr}】不符合年龄，请核对！`
          advises.push({
            recommond: `推荐话术：${htmlText}`,
            tip: htmlText
          })
          error.push(htmlText)

          // 将孕哺情况的文字标红
          // xgptUserInfo('age')
        }
        // let disListxt = disList.join(',')
        const limitTip = []
        let iskangshengsu = false
        this.kfhMust = []
        drugList.forEach((item, index) => {
          if (patientInfo.allergyContent && patientInfo.allergyContent != '无' && patientInfo.allergyContent.includes(item.drugName)) {
            error.push(`${item.drugName}-药品有过敏史请谨慎使用`)
          }
          if ((item.usage.indexOf('阴道') != -1 || item.usage.indexOf('外阴') != -1) && patientInfo.gender == '男') {
            if (drugnames) {
              drugnames += ',' + item.drugName
            } else {
              drugnames = item.drugName
            }
          }
          if (item.buyCount) {
            if (checkList[index] && checkList[index].maxUseNumber) {
              let maxUseNumber = checkList[index].maxUseNumber
              maxUseNumber = parseInt(maxUseNumber)
              // 购买数量大于最大限制数量
              if (parseInt(item.buyCount) > maxUseNumber) {
                // 提示超量数量
                limitTip[index] = '当前限购数量为<span style="color:red">' + maxUseNumber + '件</span>,'
              }
            }
          }
          if (
            item.drugName.indexOf('头孢') != -1 ||
            item.drugName.indexOf('阿莫西林') != -1 ||
            item.drugName.indexOf('甲硝唑') != -1 ||
            item.drugName.indexOf('左氧氟沙星') != -1 ||
            item.drugName.indexOf('沙星') != -1
          ) {
            iskangshengsu = true
          }
        })
        if (drugnames) {
          const htmlText = `${drugnames}用药方式与性别不符，请修改;`
          const htmlText1 = `<span style="color: #F07503;">${drugnames}</span>用药方式与性别不符，请修改;`
          advises.push({
            text: htmlText1,
            tip: htmlText
          })
          error.push(htmlText)
        }
        // 判断有抗生素药品，并且诊断有慢性内容，需要拦截
        if (ismanxing == true && iskangshengsu == true) {
          error.push(`【${manxingName}】无感染指征，不建议用抗生素，请核对`)
        }

        // 判断有危重病症，需要拦截
        if (isjwzz == true) {
          error.push(`【${jwzzName}】属于急危重症，如需开具，请尝试修改轻症的诊断名。`)
        }
        if (checkList && checkList.length > 0) {
          checkList.forEach(item => {
            // if (item.drugDiagnoses.length == 0) error.push(`【${item.comName}】缺少诊断`)
            const repeatDrugs = item.repeatDrugs
            if (repeatDrugs && repeatDrugs.length > 0) {
              repeatDrugs.forEach(fItem => {
                const repeatStr = `${item.comName}和${fItem}属于重复用药，不推荐合用`
                const repeatTip = `${item.comName}和${fItem}属于重复用药，这边帮您先开其中一个药品`
                error.push(repeatStr)
                advises.push({
                  text: repeatTip,
                  tip: repeatTip
                })
              })
            }
            const messageReminder = item.messageReminder
            if (messageReminder && messageReminder.length) {
              let buchongshuomingtxt = ''
              messageReminder.forEach((items) => {
                if (!error.some(item => item.includes(items))) error.push(items)
                if (items && items.indexOf('联合用药可能会增加不良反应') != -1 && items.indexOf('请根据具体病情分析判断') != -1) {
                  // 暂时屏蔽这项提示
                } else if (items && items.indexOf('如有呕吐、恶心等不适感严重者请及时到医院就诊') != -1) {
                  // 开方后必须发送的话术
                  const htmlText = '本品严格遵医嘱使用，如有呕吐、恶心等不适感严重者请及时到医院就诊。'
                  advises.push({
                    text: htmlText,
                    tip: htmlText
                  })
                } else {
                  if (items.indexOf('禁售药品') != -1) {
                    const tishis2 = '根据国家法律法规，' + items
                    advises.push({
                      text: items,
                      recommond: `拒方话术：${tishis2}`,
                      tip: tishis2
                    })
                  } else {
                    // allergyDrugshtm = '<p>' + idx + '、' + items + ';</p>'
                    // advises.push(allergyDrugshtm)
                    // idx++
                  }
                }
                if (items.indexOf('一个药品存在多个诊断') != -1) {
                  //
                }
                if (items.indexOf('属于高风险药品') != -1) {
                  const drugname2 = items.split('属高风险药品')[0]
                  const drinkFlags3 = this.drinkFlags3
                  const drinkFlags2txt = drinkFlags3.join(' ')
                  if (
                    drinkFlags3.indexOf(items) == -1 &&
                    drinkFlags2txt.indexOf(drugname2) == -1
                  ) {
                    this.drinkFlags3.push(items)
                  }
                }
                if (items.indexOf('请处方补充说明：') != -1) {
                  let shuoming = items.split('请处方补充说明：')[1]
                  if (shuoming.indexOf('为避免不良反应，请分开使用') != -1) {
                    shuoming =
                      '药品功效相近或主要成份相同,为避免不良反应,建议按线下医嘱用药，避免一起使用！'
                  }
                  buchongshuomingtxt += shuoming
                }
              })
              if (buchongshuomingtxt) {
                // 处方补充说明
                // this.currentUserData.suppleInstruct = buchongshuomingtxt
                // inquiry.commonSetCache({
                //   name: 'currentUserData_' + this.currentUserId,
                //   content: this.currentUserData
                // })
              }
            }
            const focuses = item.focuses
            if (focuses && focuses.length) {
              this.focuses = focuses
              focuses.forEach((items) => {
                if (!warnings.some(item => item.includes(items))) warnings.push(`<span class="c-warning">${items}</span>`)
              })
            }
            if (item.sex.indexOf(patientInfo.gender) === -1) {
              const htmlText = `您的性别不适合使用${item.comName}，无法为您开具处方，建议您线下医院就诊`
              const textTip = `${item.comName}与用药人性别不符`
              advises.push({
                text: textTip,
                recommond: `推荐话术：${htmlText}`,
                tip: htmlText
              })
              error.push(textTip)
            }
            if (item.isAge == 0) {
              const htmlText = `您的年龄不适合使用${item.comName}，无法为您开具用药。请上传复诊凭证或以真实用药人信息重新下单！`
              const textTip = `${item.comName}与用药人年龄不符`
              advises.push({
                text: textTip,
                recommond: `推荐话术：${htmlText}`,
                tip: htmlText
              })
              error.push(textTip)
            }
          })
        }
        if (patientInfo.pregnancy && patientInfo.pregnancy == 1) {
          let hasnurse = []
          checkList.forEach((litem) => {
            if ((litem.isDisableGestational == 1 || litem.isDisableWoman == 1) && litem.drugBase) {
              hasnurse.push(litem.drugBase.commDrug)
            }
            if (patientInfo.pregnancy == 1 && litem.isDisableWoman == 2) {
              error.push(`${litem.comName}为妊娠孕妇禁用药品`)
              // xgptUserInfo('ypqk')
            }
            if (patientInfo.pregnancy == 1 && litem.isDisableGestational == 2) {
              error.push(`${litem.comName}为哺乳期禁用药品`)
              // xgptUserInfo('ypqk')
            }
          })
          if (hasnurse.length) {
            hasnurse = hasnurse.join('、')
            const hasnursetxt = hasnurse + '哺乳期慎用，用药请暂停哺乳！'
            const hasnurseHtml = `<span style="color: #F07503;">${hasnurse}</span>哺乳期慎用，用药请暂停哺乳！`
            advises.push({
              text: `<span style="color: #F07503;">${hasnurse}</span>哺乳期慎用药品，用户在哺乳期`,
              recommond: `推荐话术：${hasnurseHtml}`,
              tip: hasnursetxt
            })
            // 处方补充说明
            // this.currentUserData.suppleInstruct = hasnursetxt
            // inquiry.commonSetCache({
            //   name: 'currentUserData_' + this.currentUserId,
            //   content: this.currentUserData
            // })
          }
        }

        // 诊断与药品适应症/用户性别不匹配
        const diseaseList = []
        const sexList = []
        for (let i = 0; i < this.testResults.isDiagnoses.length; i++) {
          const item = this.testResults.isDiagnoses[i]
          if (item.isDisease != 1) diseaseList.push(item.diseaseName)
          if (item.isSexUnusual != 1) sexList.push(item.diseaseName)
        }
        if (diseaseList.length > 0) {
          const diseaseStr = diseaseList.join('/')
          const advisesTip = diseaseStr + '诊断与药品适应症不匹配'
          advises.push({ text: advisesTip, recommond: ``, tip: advisesTip })
          warnings.push(`<span class="c-warning">${advisesTip}</span>`)
          this.checkDignose = 0
        }
        if (sexList.length > 0) {
          const diseaseStr = diseaseList.join('/')
          const advisesTip = diseaseStr + '诊断与用户性别不匹配'
          advises.push({ text: advisesTip, recommond: ``, tip: advisesTip })
          error.push(advisesTip)
          this.checkDignose = 0
        }

        if (this.currentUserData.doctorAdvice) error.push('存在医嘱备注，请仔细核对且修改后，点击临时放行开方')

        this.showError = error
        this.showYellow = warnings
        this.showAdvises = advises
        // 判断风险等级，存于store
        // const zdforbidState = this.zdforbidState
        // const qzfangxing = this.qzfangxing
        // const isAllergy = this.currentUserData.patientInfo.allergyContent && this.currentUserData.patientInfo.allergyContent != '无'
        // const pauseType = this.pauseType
        if (this.useforbidList.length > 0 || error.length > 0) {
          // 此处处理为禁止开方
          this.forbidLevel = 0
        } else {
          if (this.isOldPeople || this.isChild || this.focuses.length > 0 || this.excessList.length > 0 || this.noUseMethodList.length > 0 || this.outDoseList.length > 0 || this.outFreList.length > 0 || this.druguseTip && this.druguseTip.length > 0) {
            // 重点关注
            this.forbidLevel = 3
          } else {
            this.forbidLevel = 1
          }
        }
        if (this.testResults.isUserName == 0 && this.patientInfo.gang == 1 && this.testResults.isAge == 1 && this.patientInfo.shen == 1 &&
          this.testResults.isSex == 1 && !(patientInfo.pregnancy == 1 && (this.testResults.isDisableWoman == 2 || this.testResults.isDisableGestational == 2))) {
          this.userInfoPass = true
        } else {
          this.userInfoPass = false
        }
        if ((this.changeAdvises && this.changeAdvises.length > 0) || this.checkDignose == 0) {
          this.isDignoseCheck = true
        } else {
          this.isDignoseCheck = false
        }
        if (this.drinkFlags && this.drinkFlags.length > 0) {
          const tishitxt =
            '用药前须知：请确保用药人使用【' +
            this.drinkFlags.join('、') +
            '】前后一周没有饮酒或饮用含酒精饮料！否则请不要使用该药品。'
          const idx = this.changeAdvises.length + 1
          const thtml = `<p>${idx}、${tishitxt}<a href="javascript:;" class="blue-a" data-id="${tishitxt}">立即发送</a><a href="javascript:;" class="blue-d" data-id="${tishitxt}">编辑后发送</a></p>`
          this.changeAdvises.push(thtml)
          // sendMsgAli(tishitxt)
        }
        if (this.drinkFlags2 && this.drinkFlags2.length > 0) {
          const yaoping = this.drinkFlags2.join('、')
          const tishitxt2 = `用药前须知：【${yaoping}】属于青霉素类药物，易发生药物不良反应，请确保使用该药品做皮试不过敏，或近三天内有使用过该用药品无过敏等情况。
        青霉素过敏及青霉素皮肤试验阳性者禁用！`
          if (this.drinkFlags && this.drinkFlags.length > 0) {
            const idx = this.changeAdvises.length + 1
            const thtml = `<p>${idx}、${tishitxt2}<a href="javascript:;" class="blue-a" data-id="${tishitxt2}">立即发送</a><a href="javascript:;" class="blue-d" data-id="${tishitxt2}">编辑后发送</a></p>`
            this.changeAdvises.push(thtml)
          }
        }
        if (this.drinkFlags3 && this.drinkFlags3.length > 0) {
          const yaoping2 = this.drinkFlags3.join(' ')
          this.changeAdvises.push(yaoping2)
        }
        this.checkDone = 1
      } catch (err) {
        this.checkDone = 2
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.drug-name {
  color: #f07503;
  font-weight: bold;
}

.line-row {
  height: 1px;
  background-color: rgba(224, 224, 224, 1);
  margin: 6px 0;
}

/deep/ .el-backtop {
  width: 48px;
  height: 70px;
  font-size: 12px;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}
.back-top {
  text-align: center;
  .el-image {
    background-color: #fff;
  }
}
.aide-check-item[data-subtitle]:before {
  content: attr(data-subtitle);
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  position: relative;
  z-index: 1;
  line-height: 24px;
  margin: 8px 0;
}
.aide-check-item[data-subtitle] {
  position: relative;
}

.no-margin-top[data-subtitle]:before {
  margin-top: 0;
}

.aide-check-item {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  &.no-border {
    border: none;
  }
  .title-left {
    text-align: left;
  }
  .title-right {
    text-align: right;
    float: right;
    padding-right: 6px;
    //background: #e02020;
    //color: #000;
  }
}

.aide-home {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  .drug-use-method {
    display: flex;
    & > div {
      flex: 1;
    }
  }
  .check-title {
    margin: 10px 0;
  }
  .send-box {
    text-align: right;
    float: right;
    .check-click {
      margin-left: 20px;
      color: #f07503;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .check-box {
    border: 1px solid rgba(216, 216, 216, 1);
    border-radius: 8px;
    padding: 12px;
  }
  .unpass {
    color: #e02020;
  }
  .pass {
    color: #66cc66;
  }
  .c-error {
    color: #e20000;
  }
  .c-warning {
    color: #f07503;
  }
  .mgtb12 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .line-left {
    text-align: right;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    padding-right: 16px;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
  }
  .line-right {
    height: 32px;
    line-height: 18px;
    text-align: left;
    padding: 12px 12px 12px 18px;
    border-top: 1px solid #d8d8d8;
  }
  .drug-usage-dosage {
    overflow: hidden;
    .check-use-method-list {
      &:last-of-type {
        margin-bottom: 0px;
      }
      margin-bottom: 18px;
      .check-use-method {
        .check-drug-name {
          font-weight: bold;
        }
      }
    }
    .check-operate {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3b94ff;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      height: 40px;
      cursor: pointer;
      i {
        margin-right: 12px;
      }
    }
  }
  .check-item {
    .new-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      color: #e20000;
      .new-title-right {
        display: flex;
        align-items: center;
        .el-image {
          margin-top: 2px;
          margin-left: 10px;
        }
      }
    }
  }

  .aide-home-result {
    text-align: left;
    .aide-home-checking {
      font-weight: 600;
      .el-icon-loading {
        margin-left: 10px;
      }
    }
    .aide-home-done {
      text-align: left;
      .result-done {
        font-weight: 600;
      }
      .result-top {
        text-align: center;
        background-color: #ffe5e5;
        height: 132px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 8px;
        .result-check {
          display: flex;
          justify-content: center;
          align-items: center;
          .result-logo {
            text-align: center;
          }
          .result-text {
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
          }
        }
        .button-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .update-use {
            cursor: pointer;
            width: 132px;
            height: 36px;
            background: #f4faff;
            border: 1px solid rgba(59, 148, 255, 1);
            border-radius: 5px;
            font-size: 16px;
            color: #2d85ee;
            line-height: 36px;
          }
          .cxjc {
            cursor: pointer;
            width: 100px;
            height: 36px;
            line-height: 36px;
            background: #f4faff;
            border: 1px solid rgba(59, 148, 255, 1);
            border-radius: 5px;
            margin-left: 10px;
            font-size: 16px;
            color: #2d85ee;
          }
        }
      }
      .result-list {
        text-align: left;
        .result-reason {
          color: #e02020;
        }
        .result-warnig {
          color: #f07503;
          // margin-top: 5px;
        }
      }
    }
  }
  .aide-home-check {
    text-align: left;
    .aide-title {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .aide-check-body {
      .check-user,
      .check-dignose {
        .check-col {
          display: flex;
          justify-content: space-between;
          padding-right: 12px;
          height: 24px;
          line-height: 24px;
        }
        .check-left {
          text-align: left;
          flex: 1;
        }
        .check-right {
          text-align: right;
        }
      }
      .advise-result-list {
        line-height: 24px;
        .usewenti {
          line-height: 24px;
        }
        .jcFx {
          text-align: right;
          color: #3b94ff;
          padding-right: 6px;
          cursor: pointer;
        }
        .wtFx {
          text-align: right;
          color: #3b94ff;
          padding-right: 6px;
          cursor: pointer;
        }
      }
    }
  }
  .aide-home-drug {
    margin-bottom: 20px;
    text-align: left;
    .aide-title {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .aide-check-body {
      padding: 0;
      .drug-active {
        padding-top: 12px;
        padding-left: 32px;
      }
      /deep/ .el-tabs__header {
        margin-top: 2px;
      }
      /deep/ .el-tabs__item {
        color: #333;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }
      /deep/ .el-tabs__item.is-active {
        color: #3b94ff;
      }
      /deep/ .el-tabs__active-bar {
        background-color: #3b94ff;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background: #fff;
      }
      .drug-list {
        .drug-detail {
          font-size: 12px;
          .drug-img {
            text-align: right;
            padding: 16px;
          }
          .drug-desc {
            text-align: left;
            font-size: 12px;
            padding-left: 18px;
            .desc-title {
              font-weight: 600;
              margin-bottom: 10px;
            }
            .desc-text {
              margin-bottom: 5px;
            }
          }
          .text-left {
            text-align: right;
            height: 32px;
            line-height: 32px;
            font-weight: 600;
            padding-right: 16px;
          }
          .text-right {
            text-align: left;
            height: 32px;
            line-height: 32px;
            padding-left: 18px;
          }
        }
      }
    }
  }
}
</style>
