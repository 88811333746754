<template>
  <div class="aide-set">
    <div class="short-key">
      <span class="key-title">快捷键提示：</span>
      <div>收起用药助手：Esc</div>
      <div>展开用药助手：F4</div>
      <div>发送：Enter</div>
      <div>确认开方：F1</div>
      <div>常用话术：F6</div>
      <div>图文自动接诊：F9</div>
      <div>结束会话：F10</div>
      <div>删除处方：Delete</div>
      <div>安排转诊：~</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.aide-set {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  overflow-y: scroll;
  .setNotice {
    display: flex;
    font-weight: 600;
  }
  .short-key {
    margin-bottom: 20px;
    text-align: left;

    color: #333;
    .key-title {
      font-weight: 600;
      color: #333333;
    }
  }
  .short-key div {
    margin-top: 10px;
  }
}
</style>
