<template>
  <div class="main-container">
    <avue-crud ref="crud"
               :defaults.sync="defaults"
               :page.sync="page"
               :data="tableData"
               :table-loading="tableLoading"
               :option="tableOption"
               v-model="formData"
               @on-load="fetchData"
               @search-change="handleSubmit"
               @row-update="updateRecord">
      <template slot-scope="{row,index}"
                slot="menu">
        <el-button type="primary"
                   size="mini"
                   @click="$refs.crud.rowEdit(row,index)">{{ $t('inquiryRecord.editCaseHist') }}</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="handleChatHist(row)">{{ $t('inquiryRecord.graphicRecord') }}</el-button>
      </template>
      <template slot="menuForm">
        <el-button type="primary"
                   size="small"
                   @click="$refs.crud.rowUpdate()">提交</el-button>
      </template>
    </avue-crud>
    <chatHistory v-if="showChatHistory"
                 ref="chatHistory"
                 :current-data="currentData" />
  </div>
</template>

<script>
import { tableOption } from '@/const/crud/inquiryRecord'
import { inquiryRecordApi } from '@/api/functionApi'
import { defaultDate, dateFormat } from '@/util/date'
import { mapGetters } from 'vuex'
import chatHistory from './chatHistory'

export default {
  name: 'InquiryRecord',
  components: {
    chatHistory
  },
  data () {
    return {
      formData: {},
      tableData: [],
      page: {
        total: 1, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        ascs: [], // 升序字段
        descs: [] // 降序字段
      },
      paramsSearch: {
        startTime: defaultDate().startData,
        endTime: defaultDate().endData
      },
      tableLoading: false,
      tableOption,
      isExport: false,
      selectList: [],
      currentData: {},
      showChatHistory: false,
      defaults: null
    }
  },
  computed: {
    ...mapGetters(['doctorInfo'])
  },
  created () {
    if (this.doctorInfo.career_type == '1') {
      this.$nextTick(() => {
        this.defaults.dispensary_name.hide = true
      })
    }
  },
  methods: {
    async fetchData () {
      try {
        if (dateFormat(+new Date(this.paramsSearch.startTime), 'YYYY-MM') !== dateFormat(+new Date(this.paramsSearch.endTime), 'YYYY-MM')) {
          this.$message.warning('不支持跨月查询，请重新选择时间段')
          return
        }
        this.tableLoading = true
        const res = await inquiryRecordApi.fetchData({
          page: this.page.currentPage,
          limit: this.page.pageSize,
          ...this.paramsSearch
        })
        res.data.data.forEach((item) => {
          item.diagnoseInfo = item.diagnose_info
            ? item.diagnose_info + ';' + item.custom_diagnose
            : item.custom_diagnose
        })
        this.tableData = res.data.data
        this.page.total = res.data.count
        this.tableLoading = false
      } catch (e) {
        this.tableLoading = false
      }
    },
    // 编辑病历fetchChatHist
    async updateRecord (row, index, done, loading) {
      try {
        await inquiryRecordApi.updateCase({
          inquiryId: row.inquiry_id,
          yp_allergic: row.yp_allergic,
          main_diagnose: row.main_diagnose,
          ill_history: row.ill_history,
          past_history: row.past_history
        })
        this.$message.success(
          this.$t('inquiryRecord.editCaseHist') + this.$t('common.success')
        )
        this.fetchData()
        done()
      } catch (err) {
        loading()
        console.error(err)
      }
    },
    // 图文记录
    handleChatHist (row) {
      this.currentData = row
      this.showChatHistory = true
      this.$nextTick(() => {
        this.$refs.chatHistory.isVisible = true
      })
    },
    // 查询
    handleSubmit (params, done) {
      [params.startTime, params.endTime] = params.date ? params.date : []
      delete params.date
      params = this.filterForm(params)
      this.paramsSearch = params
      this.page.currentPage = 1
      this.fetchData()
      done()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
/deep/ .inquiry-record-dialog {
  width: 20%;
}
</style>
